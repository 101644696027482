export const linkedin_color = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 5.1258V18.8742C23 20.0121 22.5979 20.9831 21.7894 21.7894C20.9831 22.5979 20.0121 23 18.8742 23H5.1258C3.99008 23 3.01692 22.5979 2.21058 21.7894C1.40424 20.9831 1 20.0121 1 18.8742V5.1258C1 3.98794 1.40424 3.01692 2.21058 2.21058C3.01692 1.4021 3.99008 1 5.1258 1H18.8742C20.0121 1 20.9831 1.4021 21.7894 2.21058C22.5979 3.01692 23 3.98794 23 5.1258V5.1258Z"
      fill="#0077B5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2969 19.4175H19.6057V13.7175C19.6057 12.2482 19.2571 11.136 18.5598 10.3831C17.8625 9.62808 16.9407 9.24951 15.7964 9.24951C14.4982 9.24951 13.4993 9.80775 12.8042 10.9264H12.8299V9.48051H9.52111C9.55105 10.1093 9.55105 13.4245 9.52111 19.4175H12.8299V13.863C12.8299 13.4994 12.8641 13.232 12.9304 13.0609C13.0758 12.7251 13.2897 12.4428 13.5763 12.2075C13.8629 11.9744 14.2158 11.8567 14.635 11.8567C15.743 11.8567 16.2969 12.6075 16.2969 14.1047V19.4175Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.39433 19.4175H7.7031V9.48047H4.39433V19.4175Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.91699 6.4134C7.91057 5.91933 7.73518 5.50654 7.40153 5.18144C7.06787 4.85847 6.62513 4.69592 6.07118 4.69592C5.51508 4.69592 5.06379 4.85847 4.7173 5.18144C4.36867 5.50654 4.19328 5.91933 4.19328 6.4134C4.19328 6.90106 4.36225 7.30957 4.70446 7.63895C5.0424 7.96833 5.48514 8.13302 6.0284 8.13302H6.04123C6.60588 8.13302 7.05931 7.96833 7.40153 7.63895C7.74588 7.30957 7.91699 6.90106 7.91699 6.4134Z"
      fill="white"
    />
  </>
);
