import { useState, UIEvent } from 'react';
import { Backdrop, Container, Head, Nav } from './index.styles';
import { Button } from '../Button';
import { Box } from '../../atoms/Box';
import { Heading } from '../../atoms/Heading';
import { Skeleton } from '../../atoms/Skeleton';
import { IconNames } from '../../../theme';
import { MOBILE_MAX_SIZE } from '../../utils/constants';
import { useWindowSize } from '../../utils/useWindowSize';

export interface SecondarySidebarProps extends Component {
  /**
   * Close event emitter. Optional.
   */
  close?: () => void;
  /**
   * Header button cta event emitter. Optional.
   */
  cta?: () => void;
  /**
   * Header button label. Optional.
   */
  ctaButtonLabel?: string;
  /**
   * Header button icon. Optional.
   */
  ctaButtonIcon?: IconNames;
  /**
   * Control if the sidebar is visible or not. Optional.
   */
  isVisible?: boolean;
  /**
   * Sidebar title. Optional.
   */
  title?: string;
  /**
   * Control if it should show the skeleton or not. Optional
   */
  isLoading?: boolean;
  /**
   * Aria Label. Optional
   */
  ariaLabel?: string;
  /**
   * Aria add new Label. Optional
   */
  ariaAddNewLabel?: string;
}

/**
 * SecondarySidebar
 */
export const SecondarySidebar = (props: SecondarySidebarProps) => {
  const {
    close,
    cta,
    children,
    className = '',
    customClass = '',
    ctaButtonLabel,
    ctaButtonIcon,
    isVisible,
    testId = 'secondarySidebar',
    title,
    isLoading,
    ariaLabel,
    ariaAddNewLabel = '',
  } = props;

  const { width } = useWindowSize();
  const [scrolled, setScrolled] = useState(false);

  const onScroll = (e: UIEvent) => {
    const target = e.target as HTMLUListElement;

    setScrolled(target.scrollTop > 0);
  };

  const skeleton = (
    <>
      <Head showLine={false}>
        <Box padding={['s16', 's0', 's0', 's0']}>
          <Skeleton
            width="6rem"
            height="0.75rem"
            data-testid="media-library-sidebar-skeleton"
          />
        </Box>
      </Head>
      <Nav>
        <Box
          verticalGap="s28"
          padding={['s24', 's24', 's24', 's24']}
        >
          {new Array(5).fill('').map((_, index) => (
            <Skeleton
              key={index}
              width="9rem"
              height="0.75rem"
            />
          ))}
        </Box>
      </Nav>
    </>
  );

  return (
    <>
      <Container
        className={`secondary-sidebar ${customClass} ${className}`}
        isVisible={isVisible}
        data-testid={testId}
      >
        {isLoading ? (
          skeleton
        ) : (
          <>
            {title && (
              <Head showLine={scrolled}>
                {width > MOBILE_MAX_SIZE ? (
                  <>
                    <Heading
                      align="left"
                      color="brandDark"
                      semantic="h6"
                      variation="list-title_12"
                    >
                      {title}
                    </Heading>
                    {ctaButtonLabel && (
                      <Button
                        appearance="default"
                        onClick={() => cta && cta()}
                        data-testid="secondary-sidebar-header-cta"
                        icon={ctaButtonIcon}
                        iconPosition="left"
                        label={ctaButtonLabel}
                        size={14}
                        variation="outline"
                        aria-label={ariaAddNewLabel}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {ctaButtonLabel && (
                      <>
                        <Button
                          appearance="default"
                          onClick={() => close && close()}
                          icon="close"
                          size={16}
                          variation="default"
                          data-testid="close-secondary-sidebar"
                        />
                        <Button
                          appearance="primary"
                          onClick={() => cta && cta()}
                          icon={ctaButtonIcon}
                          iconPosition="right"
                          label={ctaButtonLabel}
                          size={14}
                          data-testid="secondary-sidebar-header-cta"
                          variation="default"
                        />
                      </>
                    )}
                  </>
                )}
              </Head>
            )}
            <Nav
              onScroll={onScroll}
              aria-label={ariaLabel}
            >
              {width <= MOBILE_MAX_SIZE && (
                <Heading
                  align="left"
                  color="brandDark"
                  semantic="h6"
                  variation="component-title_20"
                >
                  {title}
                </Heading>
              )}
              {children}
            </Nav>
          </>
        )}
      </Container>
      <Backdrop
        isVisible={isVisible}
        onClick={() => close && close()}
        data-testid="backdrop"
      />
    </>
  );
};
