import { Box, Button, Heading, Text } from '@sendible/design-system/src';
import { useTranslation } from 'react-i18next';
import { Message } from './index.styles';
import { useCampaignsContext } from '../../context';

export const BlankState = () => {
  const { t } = useTranslation('campaigns');
  const { setIsSidebarVisible, setIsEditionModalOpen } = useCampaignsContext();
  const noCampaignsSetup = t('no_campaigns_setup');
  const clickToCreate = t('click_to_create');
  const ctaButtonLabel = t('sibebar_new_campaigns_button');

  return (
    <Box
      fullWidth
      contentJustify="space-around"
      contentAlignment="center"
    >
      <Message orientation="column">
        <Heading
          align="left"
          color="brandDark"
          semantic="h2"
          variation="layout-title_28"
        >
          {noCampaignsSetup}
        </Heading>
        <Text
          align="left"
          color="brandDark"
          variation="body_14"
        >
          {clickToCreate}
        </Text>
        <Button
          variation="outline"
          appearance="primary"
          size={14}
          onClick={() => {
            setIsEditionModalOpen(true);
            setIsSidebarVisible(false);
          }}
          label={ctaButtonLabel}
        />
      </Message>
    </Box>
  );
};
