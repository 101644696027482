import { forwardRef, useRef, useState, useEffect, MouseEvent, ChangeEvent, MutableRefObject, ReactNode } from 'react';
import { useFloating, useFocus, useInteractions, offset, autoPlacement, autoUpdate } from '@floating-ui/react';
import { Container, Content, Feedback, Label, WidthMaster, PopupBox } from './index.styles';
import { Button } from '../Button';
import { Icon } from '../../atoms/Icon';
import { IconNames } from '../../../theme';

export const autoCompletes = ['on', 'off'] as const;
export const sizes = ['sm', 'md', 'lg', 'xl'] as const;
export const types = ['text', 'email', 'password', 'date'] as const;

export interface InputFeedbackType {
  type?: 'success' | 'danger' | 'warning' | 'info';
  message?: string;
}

interface ButtonType {
  label?: string;
  icon?: IconNames;
}

export interface InputProps extends Component {
  /**
   * Width of input automatically adjusts to text input. Optional.
   */
  adaptWidthToText?: boolean;
  /**
   * Aria Label. Optional.
   */
  ariaLabel?: string;
  /**
   * Has autocomplete? Optional.
   */
  autoComplete?: (typeof autoCompletes)[number];
  /**
   * Has autofocus? Optional.
   */
  autoFocus?: boolean;
  /**
   * Blur event emitter. Optional.
   */
  blur?: (e: string) => void;
  /**
   * Button. E.g.: {}: Optional.
   */
  button?: ButtonType;
  /**
   * Change event emitter. Optional.
   */
  change?: (e: string) => void;
  /**
   * Button click event emitter. Optional.
   */
  click?: (e: MouseEvent) => void;
  /**
   * Set dark mode colors or not. Optional.
   */
  darkmode?: boolean;
  /**
   * Visual feedback message and type ('success', 'warning', 'info' or 'danger'). (E.g: {type: 'danger', message: 'Password is wrong'}). Optional.
   */
  feedback?: InputFeedbackType;
  /**
   * Sets a time in milliseconds to display feedbacks. Optional.
   */
  feedbackDuration?: number;
  /**
   * Hides input border until hovered and keeps text height
   */
  inline?: boolean;
  /**
   * ID attribute. Optional.
   */
  id?: string;
  /**
   * Icon name. If it has icon, icon will be applied. Optional. (You can see the full list at branding/icons)
   */
  icon?: IconNames;
  /**
   * Initial value. Optional.
   */
  initialValue?: string;
  /**
   * Disable state attribute. Optional.
   */
  isDisabled?: boolean;
  /**
   * Loading state. Optional.
   */
  isLoading?: boolean;
  /**
   * Readonly state attribute. Optional.
   */
  isReadonly?: boolean;
  /**
   * Required state attribute. Optional.
   */
  isRequired?: boolean;
  /**
   * On keyboard press event emitter. Optional.
   */
  keyPress?: (key: string, text: string) => void;
  /**
   * Input label text and type ('internal' or 'external'). Required. (E.g: {type: 'internal', text: 'Placeholder'})
   */
  label: string;
  /**
   * Delay change event emiter (good for preventing unecessary request calls). Optional.
   */
  lazy?: boolean;
  /**
   * Delay time in milliseconds to emmit change event. Optional.
   */
  lazyDelayTime?: number;
  /**
   * Max character lenght. Optional.
   */
  maxLength?: number;
  /**
   * Max width. Optional.
   */
  maxWidth?: string;
  /**
   * Min width. Optional.
   */
  minWidth?: string;
  /**
   * Emmits event to the parent when the feedback reaches it's duration time. Optional.
   */
  onFeedbackDurationEnd?: () => void;
  /**
   * Sizing variations. Optional.
   */
  popupContent?: ReactNode;
  /**
   * popup information content. HTML content type. Optional.
   */
  size?: (typeof sizes)[number];
  /**
   * Hover title. Optional.
   */
  title?: string;
  /**
   * Input type attribute.
   */
  type?: (typeof types)[number];
  /**
   * value. Optional.
   */
  value?: string;
}

/**
 * Input
 */
export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    adaptWidthToText = false,
    ariaLabel = '',
    autoComplete = 'on',
    autoFocus,
    blur,
    button,
    change,
    className = '',
    click,
    customClass = '',
    darkmode,
    feedback,
    feedbackDuration,
    inline,
    icon,
    id,
    initialValue = '',
    isDisabled,
    isLoading,
    isReadonly,
    isRequired,
    keyPress,
    label,
    lazy,
    lazyDelayTime = 2000,
    maxLength,
    maxWidth,
    minWidth,
    onFeedbackDurationEnd,
    popupContent,
    size = 'md',
    testId = 'input',
    title,
    type = 'text',
    value = '',
  } = props;

  const [hasFocus, setHasFocus] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { refs, strategy, x, y, context } = useFloating({
    open: isPopupOpen,
    onOpenChange: setIsPopupOpen,
    middleware: [offset(9), autoPlacement({ alignment: 'start' })],
    whileElementsMounted: autoUpdate,
  });
  const [isLabelFloating, setIsLabelFloating] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [lazyDelay, setLazyDelay] = useState<ReturnType<typeof setTimeout>>(setTimeout(() => null, 0));
  const [feedbackTimer, setFeedbackTimer] = useState<ReturnType<typeof setTimeout>>(setTimeout(() => null, 0));
  const inputRef = (ref as MutableRefObject<HTMLInputElement>) || useRef<HTMLInputElement>();
  const widthMasterRef = useRef<HTMLDivElement>(null);
  const setInputWidth = () => {
    setTimeout(() => {
      if (adaptWidthToText && widthMasterRef?.current && inputRef?.current) {
        const adjustOffset = 10;
        const widthMasterStyles = window.getComputedStyle(widthMasterRef.current);
        const widthAdjusted = parseInt(widthMasterStyles.width, 10) + adjustOffset;

        if (widthAdjusted) {
          inputRef.current.style.width = `${widthAdjusted}px`;
        }
      }
    }, 0);
  };
  const inputfocus = useFocus(context, {
    enabled: !!popupContent,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([inputfocus]);

  useEffect(() => {
    const valueToSet = value || initialValue;

    setInputValue(valueToSet);
    if (valueToSet && inputRef?.current) {
      inputRef.current.value = valueToSet;
    } else {
      setIsLabelFloating(false);
    }

    if (adaptWidthToText && widthMasterRef?.current) {
      if (initialValue) {
        widthMasterRef.current.innerHTML = initialValue;
      }
      widthMasterRef.current.style.width = 'auto';
      setInputWidth();
    }
  }, [initialValue, value]);

  useEffect(() => {
    if (feedbackDuration && onFeedbackDurationEnd) {
      clearTimeout(feedbackTimer);

      setFeedbackTimer(setTimeout(() => onFeedbackDurationEnd(), feedbackDuration));
    }
  }, [keyPress, change]);

  const getIconSize = () => {
    if (size === 'sm') return 12;
    if (size === 'md') return 14;
    if (size === 'lg') return 16;

    return 18;
  };

  const labelText = isRequired ? label : `${label}`;

  const getRightIcon = () => {
    if (type === 'password') {
      return (
        <Button
          data-testid="button"
          appearance="default"
          onClick={() => setShowPassword(!showPassword)}
          icon={showPassword ? 'eye' : 'eye_off'}
          size={getIconSize()}
          variation="default"
        />
      );
    }
    if (feedback?.type) {
      const feedbackIcon = () => {
        if (feedback.type === 'danger') return 'danger';
        if (feedback.type === 'warning') return 'warning';
        if (feedback.type === 'success') return 'check';

        return 'info';
      };

      return (
        <Icon
          name={feedbackIcon()}
          customClass="right-icon"
        />
      );
    }
    if (button) {
      return (
        <Button
          data-testid="button"
          appearance="default"
          onClick={(e) => click && click(e)}
          icon={button.icon}
          iconPosition="right"
          label={button.label}
          size={getIconSize()}
          variation="default"
        />
      );
    }

    return null;
  };

  const handleBlur = (e: string) => {
    const valueToSet = initialValue;

    if (!valueToSet && !inputRef.current.value) setIsLabelFloating(false);
    e.length > 0 ? setIsLabelFloating(true) : setIsLabelFloating(false);
    setHasFocus(false);
    blur && blur(e);
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;

    text.length > 0 ? setIsLabelFloating(true) : setIsLabelFloating(false);
    if (hasFocus) setIsLabelFloating(true);

    if (text.trim() === '' && widthMasterRef?.current) {
      setInputValue('');
      widthMasterRef.current.innerHTML = '';
      setInputWidth();
    } else {
      setInputValue(text);
    }

    if (lazy) {
      clearTimeout(lazyDelay);

      const debounce = setTimeout(() => {
        change && change(text);
      }, lazyDelayTime);

      setLazyDelay(debounce);
    } else {
      change && change(text);
    }
  };

  const handleKeyPress = (key: string) => {
    clearTimeout(lazyDelay);

    setInputWidth();

    keyPress && keyPress(key, inputValue);
  };

  const handleFocus = () => {
    if (popupContent) {
      setIsPopupOpen(true);
    }
    setIsLabelFloating(true);
    setHasFocus(true);
  };

  return (
    <Container
      className={`input input-${size} ${customClass} ${className}`}
      isDisabled={isDisabled}
      isLoading={isLoading}
      ref={refs.setReference}
      {...getReferenceProps()}
    >
      <Content
        float={isLabelFloating}
        htmlFor={id}
        darkmode={darkmode}
        feedback={feedback}
        inline={inline}
        icon={icon}
        isDisabled={isDisabled}
        isReadonly={isReadonly}
        maxWidth={maxWidth}
        minWidth={minWidth}
        size={size}
        data-testid={testId}
      >
        {icon && (
          <Icon
            name={icon}
            customClass="left-icon"
          />
        )}

        <input
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          data-testid={`${testId}-input`}
          disabled={isDisabled}
          id={id}
          name={id}
          maxLength={maxLength}
          value={inputValue}
          onFocus={() => handleFocus()}
          onChange={(e) => handleChange(e)}
          onBlur={(e) => handleBlur(e.target.value)}
          onKeyDown={(e) => handleKeyPress(e.key)}
          placeholder={labelText}
          readOnly={isReadonly}
          ref={inputRef}
          required={isRequired}
          title={title}
          type={showPassword ? 'text' : type}
          aria-label={ariaLabel}
        />
        {adaptWidthToText && widthMasterRef && (
          <WidthMaster
            ref={widthMasterRef}
            data-testid={`${testId}-width-master`}
          >
            {inputValue}
          </WidthMaster>
        )}

        {size !== 'sm' && size !== 'md' && (
          <Label
            className="input-label"
            darkmode={darkmode}
            float={isLabelFloating}
            icon={icon}
          >
            {labelText}
          </Label>
        )}
        {getRightIcon()}
      </Content>
      {isPopupOpen && (
        <PopupBox
          ref={refs.setFloating}
          style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
          {...getFloatingProps()}
        >
          {popupContent}
        </PopupBox>
      )}
      {feedback && (
        <Feedback
          className="input-feedback"
          darkmode={darkmode}
          feedback={feedback}
        >
          {feedback.message}
        </Feedback>
      )}
    </Container>
  );
});
