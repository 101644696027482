import styled, { css } from 'styled-components';
import { FeedbackItem } from '.';

export const Container = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  list-style: none;
  padding: 0;
  margin: 0.25rem 0 0.75rem 0;
`;

export const Message = styled.li<Pick<FeedbackItem, 'done'>>`
  ${({ theme: { colors }, done }) => css`
    align-items: flex-start;
    color: ${colors.brandDark};
    display: flex;
    gap: 0.5em;

    svg {
      height: 1em;
      width: 1em;

      path {
        stroke: ${done ? colors.semanticSuccessShade10 : colors.brandDarkAlpha20};
      }
    }

    ${done &&
    css`
      text-decoration: line-through;
      text-decoration-color: ${colors.brandDarkAlpha60};

      * {
        color: ${colors.brandDarkAlpha60};
      }
    `}
  `}
`;
