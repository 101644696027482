import styled, { css } from 'styled-components';
import { SpinnerProps } from '.';

export const Container = styled.div<SpinnerProps>`
  ${({ theme: { colors }, size = 'xs' }) => css`
    align-items: center;
    animation: spin 6s infinite linear;
    display: flex;
    justify-content: center;

    svg {
      height: 100%;
      object-fit: contain;
      width: 100%;

      * {
        stroke: inherit !important;
      }
    }

    ${size === 'xs' &&
    css`
      height: 1rem;
      width: 1rem;
    `}

    ${size === 'sm' &&
    css`
      height: 2rem;
      width: 2rem;
    `}

    ${size === 'md' &&
    css`
      height: 3rem;
      width: 3rem;
    `}

    ${size === 'lg' &&
    css`
      height: 4rem;
      width: 4rem;
    `}

    ${size === 'xl' &&
    css`
      height: 5rem;
      width: 5rem;
    `}

    @keyframes spin {
      0% {
        stroke: ${colors.brandPrimary};
        transform: rotate(0deg);
      }
      12% {
        transform: rotate(135deg);
      }
      16% {
        stroke: ${colors.brandPrimary};
      }
      25% {
        transform: rotate(270deg);
      }
      33% {
        stroke: ${colors.brandAccent};
      }
      37% {
        transform: rotate(405deg);
      }
      50% {
        stroke: ${colors.brandAccent};
        transform: rotate(540deg);
      }
      62% {
        transform: rotate(675deg);
      }
      66% {
        stroke: ${colors.brandSecondary};
      }
      75% {
        transform: rotate(810deg);
      }
      84% {
        stroke: ${colors.brandSecondary};
      }
      87% {
        transform: rotate(945deg);
      }
      100% {
        stroke: ${colors.brandPrimary};
        transform: rotate(1080deg);
      }
    }
  `}
`;
