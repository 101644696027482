import styled, { css } from 'styled-components';
import { inputMaxLength } from '../TextArea';

const warningStart = inputMaxLength * 0.15;

export const Container = styled.div<{ $count: number }>`
  ${({ theme: { colors }, $count }) => css`
    height: 2rem;
    position: relative;
    width: 2rem;

    span {
      color: ${$count < 0 ? colors.semanticDanger : colors.brandDarkAlpha70};
      display: ${$count <= warningStart ? 'block' : 'none'};
      font-size: 0.75rem;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    svg {
      height: 2rem;
      transform: rotate(-90deg);
      width: 2rem;

      ${$count === inputMaxLength &&
      css`
        display: none;
      `}

      ${$count < 0 &&
      css`
        display: none;
      `}

      .counter-bg {
        stroke: ${colors.brandLight};
      }

      .counter-meter {
        stroke: ${colors.brandPrimaryTint70};
        stroke-miterlimit: round;
        transition: all 850ms ease-in-out;
        will-change: auto;

        ${$count <= warningStart &&
        css`
          stroke: ${colors.semanticWarning};
        `}

        ${$count <= 0 &&
        css`
          display: none;
        `}
      }
    }
  `}
`;
