/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { useInView } from 'react-intersection-observer';
import { AnimatePresence, MotionConfig, motion } from 'framer-motion';
import { useFloating, offset, flip, shift, autoUpdate, Placement } from '@floating-ui/react';
import { useWindowSize, MOBILE_MAX_SIZE } from '@sendible/design-system';

export interface PortalProps extends Component {
  /**
   * The inner content shown in the Portal.
   */
  content: ReactNode;
  /**
   * The unique ID for the content.
   */
  id: string;
  /**
   * Portal opening position. Optional.
   */
  placement?: Placement;
  /**
   * Makes a gap between the invoker element and the portal. Optional.
   */
  margin?: number;
  /**
   * Allows the parent to control if portal content is visible, disables internal visibility handling
   */
  isVisibleOverride?: boolean;
}

export const Portal = ({ children, content, id, margin = 0, placement = 'bottom', isVisibleOverride = undefined }: PortalProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const { width: w } = useWindowSize();
  const { ref: inViewRef, inView } = useInView({
    threshold: 1,
    rootMargin: `${margin}px`,
  });

  const { x, y, strategy, refs } = useFloating({
    open: isVisible,
    onOpenChange: setIsVisible,
    placement,
    middleware: [offset(margin), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const parentElementDoesNotHaveId = (element: HTMLUnknownElement) => {
    if (element.id.includes(id)) return true;

    let parent = element.parentElement;

    while (parent) {
      if (parent.id.includes(id)) return false;
      parent = parent.parentElement;
    }

    return true;
  };

  useEffect(() => {
    if (isVisibleOverride !== undefined) {
      setIsVisible(isVisibleOverride);
    }
  }, [isVisibleOverride]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      if (parentElementDoesNotHaveId(target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener('mouseup', handleClickOutside);

    return () => document.removeEventListener('mouseup', handleClickOutside);
  }, [inViewRef]);

  useEffect(() => {
    if (isVisible && !inView) {
      setIsVisible(false);
    }
  }, [inView]);

  return (
    <>
      <div
        onClick={() => {
          if (isVisibleOverride === undefined) {
            setIsVisible((prev) => !prev);
          }
        }}
        ref={refs.setReference}
        data-testid="portal-wrapper"
      >
        {children}
      </div>
      {isVisible &&
        createPortal(
          <>
            <AnimatePresence>
              <MotionConfig transition={{ duration: 0.33, ease: 'easeInOut' }}>
                <motion.div
                  id={id}
                  ref={refs.setFloating}
                  style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
                  initial={w <= MOBILE_MAX_SIZE ? { y: '100vh', opacity: 0 } : {}}
                  animate={w <= MOBILE_MAX_SIZE ? { y: 0, opacity: 1 } : {}}
                  exit={w <= MOBILE_MAX_SIZE ? { y: '100vh', opacity: 0 } : {}}
                >
                  <motion.div
                    ref={inViewRef}
                    id={`${id}-content`}
                  >
                    {content}
                  </motion.div>
                </motion.div>
              </MotionConfig>
            </AnimatePresence>
          </>,
          document.getElementById('dropdown-root') as HTMLElement
        )}
    </>
  );
};
