export const tiktok_color = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.025 19.372a3.063 3.063 0 003.062-2.932V1.919h2.649A5.187 5.187 0 0115.629 1h-3.614v14.544a3.06 3.06 0 01-4.447 2.58 3.062 3.062 0 002.457 1.248zm-.16-9.707v-.857A6.668 6.668 0 005.15 20.903 6.66 6.66 0 019.863 9.665zm10.808-3.628v.811a5.037 5.037 0 01-2.74-1.623c.815.531 1.767.813 2.74.812z"
      fill="#25F4EE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.925 5.225a5.021 5.021 0 01-1.24-3.306h-.965a5.027 5.027 0 002.205 3.306zM8.969 12.36A3.062 3.062 0 007.56 18.1a2.97 2.97 0 01-.581-1.79 3.062 3.062 0 013.061-3.062c.305.001.607.05.896.145V9.72a6.89 6.89 0 00-.896-.061h-.176v2.824a3.165 3.165 0 00-.895-.122zm11.704-2.695V6.848c.344.077.696.116 1.049.115v3.62a8.62 8.62 0 01-5.037-1.622v7.349a6.66 6.66 0 01-11.536 4.593 6.66 6.66 0 0010.48-5.497V8.042a8.635 8.635 0 005.044 1.623z"
      fill="#FE2C55"
    />
    <path
      d="M15.629 15.406V8.042a8.635 8.635 0 005.044 1.623V6.848a5.037 5.037 0 01-2.74-1.623 5.092 5.092 0 01-2.22-3.306h-2.649v14.544a3.062 3.062 0 01-5.504 1.66 3.062 3.062 0 011.409-5.74c.302-.013.604.02.895.1V9.664A6.66 6.66 0 005.15 20.903a6.659 6.659 0 0010.48-5.466v-.03z"
      fill="#000"
    />
  </>
);
