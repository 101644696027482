import { Container } from './index.styles';
import { Button } from '../Button';

export interface LightboxProps extends Component {
  /**
   * Close event emmiter. Optional.
   */
  close?: () => void;
  /**
   * Set dark mode colors or not. Optional.
   */
  darkmode?: boolean;
  /**
   * Aria close Label. Optional.
   */
  ariaCloseLabel?: string;
}

/**
 * Lightbox
 */
export const Lightbox = (props: LightboxProps) => {
  const { className = '', children, close, customClass = '', darkmode, testId = 'lightbox', ariaCloseLabel = '' } = props;

  return (
    <Container
      className={`ds-lightbox ${customClass} ${className}`}
      data-testid={testId}
      {...props}
    >
      <Button
        onClick={() => close && close()}
        customClass="close-button"
        darkmode={!!darkmode}
        data-testid={`${testId}-close`}
        icon="close"
        size={18}
        aria-label={ariaCloseLabel}
        autoFocus
      />
      {children}
    </Container>
  );
};
