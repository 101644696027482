import { Button } from '@sendible/design-system';
import { useTranslation } from 'react-i18next';
import { Container } from './index.styles';
import { useBackgroundUploaderContext } from '../../context';

export const SubHeader = () => {
  const { cancelAllUploads, canCancelAll } = useBackgroundUploaderContext();
  const { t } = useTranslation('common_terms');

  return (
    <Container>
      <Button
        data-testid="cancel-all-uploads"
        disabled={!canCancelAll}
        onClick={() => {
          cancelAllUploads();
        }}
        label={t('cancel') || 'cancel'}
      />
    </Container>
  );
};
