export default {
  audience_change_title: 'Audience Change',
  audience_chart_audience_legend: 'Audience',
  audience_chart_decreased_by: 'Your audience decreased by',
  audience_chart_growth_by_day: 'Audience Growth By Day',
  audience_chart_increased_by: 'Your audience increased by',
  audience_chart_split: 'Audience Split',
  audience_chart_title: 'Audience Growth',
  campaign_overview_title: 'Campaigns Overview',
  campaigns_report: 'Campaigns report',
  default_error_message:
    "We couldn't create your report. Please refresh your browser and try again. If the issue persists after 24 hours, contact our support team.",
  default_error_message_whitelabel:
    "We couldn't create your report. Please refresh your browser and try again. If the issue persists after 24 hours, contact your admin.",
  download_data_analytics_sidebar_link: 'Download data',
  download_report_tooltip: 'Download PDF report',
  empty_report: 'No data available. Check back after your first post has been published.',
  engaging_posts: 'Most engaging posts',
  error_message: 'Reports could not be loaded',
  facebook_post_engagement_title: 'Facebook Post Engagement',
  facebook_sidebar_link: 'Facebook Page',
  generate_report_button: 'Generate Report',
  google_analytics_sidebar_link: 'Google Analytics',
  instagram_post_engagement_title: 'Instagram Post Engagement',
  instagram_sidebar_link: 'Instagram',
  linkedin_post_engagemen_titlet: 'LinkedIn Post Engagement',
  linkedin_sidebar_link: 'LinkedIn',
  my_reports_sidebar_link: 'My reports',
  no_reports_available: 'No data available. Check back after your first post has been published.',
  not_defined: 'Not defined',
  not_more_data_error_message: 'No more data available.',
  report_builder_sidebar_link: 'Report builder',
  report_campaign_sidebar_link: 'Campaigns',
  report_engagement_sidebar_link: 'Engagement',
  report_sidebar_title: 'Reports Hub',
  since_previous_period: 'Since previous period',
  tooltip_posts_sent: 'Total posts published in the campaign across all social networks.',
  tooltip_audience: 'Total fans and followers generated by campaign posts (only Facebook, Instagram, and LinkedIn, if applicable).',
  tooltip_interactions: 'Total likes and comments on campaign posts (only Facebook, Instagram, and LinkedIn, if applicable).',
  top_posts_title: 'Top Posts',
  we_couldnt_create_your_report: "We couldn't create your report.",
  youtube_sidebar_link: 'YouTube',
};
