import { FC } from 'react';
import ErrorPage from '../ErrorPage';
import SendibleLoadingIcon from '../SendibleLoadingIcon';

interface Props {
  children: React.ReactNode;
  shouldDisplay: boolean;
  hasError: boolean;
}

const BackboneContainer: FC<Props> = (props) => {
  const { shouldDisplay, hasError } = props;
  const shouldDisplayBackboneContainer = shouldDisplay && !hasError;

  return (
    <>
      {hasError && <ErrorPage />}
      <div style={{ display: shouldDisplayBackboneContainer ? 'block' : 'none' }}>
        <div className="backbone-container">
          <div
            id="main-image-modal"
            className="modal hide fade"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
          >
            <div className="modal-body" />
          </div>
          <div
            id="account-select-modal"
            className="modal hide fade"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
          />
          <div
            id="critical-error-bar"
            className="alert alert-error"
          />
          <div
            id="top-control-bar"
            className="navbar"
          />
          <div
            id="top-bar"
            className="navbar"
          />
        </div>
        {props.children}
      </div>
      {!shouldDisplay && <SendibleLoadingIcon />}
      <div
        id="hiddenTinymcePrimerHolder"
        style={{ position: 'absolute', left: '-99999px', width: '600px' }}
      >
        <textarea
          id="hiddenTinymcePrimer"
          style={{ width: '600px' }}
        />
      </div>
      <div className="blank-state-backdrop" />
      <iframe
        title="image-editor-iframe"
        id="image-editor-iframe"
        seamless
        style={{
          border: '0',
          overflow: 'hidden',
          height: '100vh',
          width: '100vw',
          display: 'none',
          position: 'fixed',
          zIndex: '3000',
          top: '0',
        }}
        height="100%"
        width="100%"
        src={`https://${WEBPACK_APP}/editor`}
      />
    </>
  );
};

export default BackboneContainer;
