import styled, { css } from 'styled-components';
import { GridProps, fillRules } from './index';

interface TransientProps {
  $fillRule?: (typeof fillRules)[number];
}

export const Container = styled.div<TransientProps & Omit<GridProps, 'fillRule'>>`
  ${({
    theme: { spacings },
    $fillRule = 'auto-fill',
    horizontalGap = 's0',
    margin = ['s0', 's0', 's0', 's0'],
    minGridSize = '9rem',
    padding = ['s0', 's0', 's0', 's0'],
    verticalGap = 's0',
    numberOfColumns,
    numberOfRows,
  }) => css`
    display: grid;
    gap: ${spacings[verticalGap]} ${spacings[horizontalGap]};
    grid-template-columns: repeat(${$fillRule}, minmax(${minGridSize}, 1fr));
    margin: ${spacings[margin[0]]} ${spacings[margin[1]]} ${spacings[margin[2]]} ${spacings[margin[3]]};
    padding: ${spacings[padding[0]]} ${spacings[padding[1]]} ${spacings[padding[2]]} ${spacings[padding[3]]};
    ${numberOfColumns &&
    css`
      grid-template-columns: repeat(${numberOfColumns}, 1fr);
    `}
    ${numberOfRows &&
    css`
      grid-template-rows: repeat(${numberOfRows}, 1fr);
    `}
  `}
`;
