import { useTranslation } from 'react-i18next';
import { Block, Container, Line, Notice, Title, Topic } from './index.styles';

export const TooltipInfo = () => {
  const { t } = useTranslation('ai_assist');

  return (
    <Container>
      <Title>{t('tooltip_title')}</Title>
      <Block>
        <Topic>{t('tooltip_first_topic')}</Topic>
        <Topic>{t('tooltip_second_topic')}</Topic>
        <Topic>{t('tooltip_third_topic')}</Topic>
        <Topic>{t('tooltip_fourth_topic')}</Topic>
      </Block>
      <Line />
      <Block>
        <Notice>{t('tooltip_important_top')}</Notice>
        <Notice>{t('tooltip_important_bottom')}</Notice>
      </Block>
    </Container>
  );
};
