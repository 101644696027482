import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRelativeTime } from '@sendible/common';
import { MediaCard } from '@sendible/design-system';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { useInView } from 'react-intersection-observer';
import { useInfiniteGetMedias } from '@sendible/frontendv2/src/models/medias';
import { ContentStyled, GridStyled } from './index.styles';
import LoadingContentSection from './LoadingContentSection';
import Skeletons from './Skeletons';
import ErrorBoundary from '../../errorBoundaries/errorBoundary';
import { useMediaLibraryContext } from '../../context';
import { Header, LoadingHeader, Preview } from '..';

export const ContentSection = () => {
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [initialPreviewMediaId, setInitialPreviewMediaId] = useState(0);
  const { activeMediaLibrary, perPage, selectedMediasIds, setSelectedMediasIds, setMediaToDelete } = useMediaLibraryContext();
  const { ref, inView } = useInView();
  const { status, data, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteGetMedias();
  const { t } = useTranslation('media_library');

  const showPreview = isPreviewOpen && initialPreviewMediaId && data;
  const showSkeletons = isFetchingNextPage && hasNextPage;

  const ariaMediaCardLabel = t('aria_media_item');

  const [
    {
      user: { language, isWhiteLabel },
    },
  ] = useBridgeContext();

  useEffect(() => {
    if (inView) fetchNextPage();
  }, [inView]);

  const handleMediaAction = (action: string, media: MediaType) => {
    if (action === 'delete') setMediaToDelete({ media, source: 'content' });
  };

  const handleSelectClick = (media: MediaType) => {
    let newList = [];

    if (selectedMediasIds?.includes(media.id)) {
      newList = [...selectedMediasIds];

      newList.splice(newList.indexOf(media.id), 1);
    } else {
      newList = selectedMediasIds ? [...selectedMediasIds, media.id] : [media.id];
    }
    setSelectedMediasIds(newList);
  };

  const LoadingContentSectionJSX = (
    <LoadingContentSection perPage={perPage}>{activeMediaLibrary?.id ? <Header /> : <LoadingHeader />}</LoadingContentSection>
  );

  const ContentSectionJSX = (
    <ContentStyled>
      <Header />
      <GridStyled
        horizontalGap="s16"
        verticalGap="s16"
        padding={['s24', 's24', 's24', 's24']}
      >
        {data?.pages.map((page) =>
          page.map((media) => (
            <MediaCard
              ariaMediaCardLabel={`${ariaMediaCardLabel} - ${media.name}`}
              dropdownActions={[{ label: t('dropdown_delete'), action: 'delete' }]}
              dropdownButtonClick={(action) => handleMediaAction(action, media)}
              duration={media.metadata.duration}
              id={media.id}
              isSelected={selectedMediasIds?.includes(media.id)}
              key={media.id}
              name={media.name}
              open={() => {
                setPreviewOpen(true);
                setInitialPreviewMediaId(media.id);
              }}
              select={() => handleSelectClick(media)}
              showCheckboxWithoutHover={!!selectedMediasIds?.length}
              tabIndex={0}
              testId={`media-${media.name}`}
              thumbUrl={media.thumbnailUrl}
              type={media.type as string}
              updateDate={getRelativeTime(language, media.updatedAt)}
              uploadingText={t('media_uploading_state_text')}
            />
          ))
        )}
        {showSkeletons && <Skeletons perPage={perPage} />}
      </GridStyled>
      <div
        className="loader"
        ref={ref}
      />
      {showPreview && (
        <ErrorBoundary
          isWhiteLabel={isWhiteLabel}
          errorSection="previewMediasSection"
        >
          <Preview
            requestClose={() => {
              setInitialPreviewMediaId(0);
              setPreviewOpen(false);
            }}
            mediaList={data.pages.flatMap((page) => page)}
            initialMediaId={initialPreviewMediaId}
          />
        </ErrorBoundary>
      )}
    </ContentStyled>
  );

  return status === 'loading' ? LoadingContentSectionJSX : ContentSectionJSX;
};
