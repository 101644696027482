import styled from 'styled-components';

const Main = styled.main`
  display: flex;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  visibility: 'visible';
  width: 100%;

  .global-modal {
    z-index: 120;
  }

  .global-dropdown {
    z-index: 119;
  }
`;

export default Main;
