import styled, { css } from 'styled-components';
import { TextProps } from '.';

interface Custom {
  isLong: boolean;
}

export const Container = styled.p<TextProps & Custom>`
  ${({ theme: { colors, fontSizes, fontWeights }, align = 'left', color = 'brandDark', isLong, maxLines, variation }) => css`
    color: ${colors[color]};
    font-weight: ${fontWeights.regular};
    margin: 0;
    text-align: ${align};
    overflow-wrap: break-word;

    strong {
      font-weight: ${fontWeights.semiBold};
    }

    em,
    i {
      font-family: 'IBM Plex Sans', sans-serif;
      font-style: normal;
    }

    ${variation === 'body_12' &&
    css`
      font-size: ${fontSizes.s12};
      letter-spacing: 0.025em;
      line-height: 1.333em;
    `}

    ${variation === 'body_14' &&
    css`
      font-size: ${fontSizes.s14};
      letter-spacing: 0.005em;
      line-height: 1.285em;
    `}

    ${variation === 'body_16' &&
    css`
      font-size: ${fontSizes.s16};
      line-height: 1.375em;
    `}

    ${isLong &&
    css`
      line-height: 1.5em;
    `}

    ${maxLines &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${maxLines};
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  `}
`;
