import { Box, Grid } from '@sendible/design-system';
import styled, { css } from 'styled-components';

export const ContentStyled = styled.div`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
    },
  }) => css`
    width: calc(100% - 15rem);
    overflow: auto;
    overflow-x: hidden;
    background-color: ${colors.brandDarkTint95};

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      width: 100%;
    }
  `}
`;

export const GridStyled = styled(Grid)`
  width: 100%;
  box-sizing: border-box;
  grid-template-rows: 0fr 0fr;
  justify-items: center;
`;

export const LoadingMediaCard = styled(Box)`
  width: 100%;
`;
