import { helpers } from '@sendible/design-system';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useFloating, offset, flip, shift, autoUpdate } from '@floating-ui/react';
import { useInView } from 'react-intersection-observer';
import { createPortal } from 'react-dom';
import { Container, Color, Display, List } from './index.styles';

interface Props {
  onColorSelect: (color: string) => void;
  currentColor: string;
}

const colors = [
  '#333333',
  '#660000',
  '#663300',
  '#666600',
  '#336600',
  '#006633',
  '#006666',
  '#003366',
  '#330066',
  '#660066',
  '#4d4d4d',
  '#990000',
  '#994d00',
  '#999900',
  '#4d9900',
  '#00994d',
  '#009999',
  '#004d99',
  '#4d0099',
  '#990099',
  '#666666',
  '#cc0000',
  '#cc6600',
  '#cccc00',
  '#66cc00',
  '#00cc66',
  '#00cccc',
  '#0066cc',
  '#6600cc',
  '#cc00cc',
];

export const defaultColor = colors[27];

export const ColorPicker = ({ onColorSelect, currentColor }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const blurChecker = useRef(null);

  const { ref: inViewRef, inView } = useInView({
    threshold: 1,
    rootMargin: '-8px',
  });

  const { x, y, strategy, refs } = useFloating({
    open: isCollapsed,
    onOpenChange: setIsCollapsed,
    placement: 'bottom-start',
    middleware: [offset(8), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      if (blurChecker.current && target.id !== 'c-p') {
        setIsCollapsed(false);
      }
    };

    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [blurChecker]);

  useEffect(() => {
    if (isCollapsed && !inView) {
      setIsCollapsed(false);
    }
  }, [inView]);

  const colorList = colors.map((color, index) => (
    <Fragment key={helpers.generateId()}>
      <Color
        id="c-p"
        onClick={() => {
          onColorSelect(color);
          setIsCollapsed(false);
        }}
        role="menuitem"
        tabIndex={index}
        $color={color}
        $isCurrent={color === currentColor}
      />
    </Fragment>
  ));

  return (
    <Container>
      <Display
        $color={currentColor}
        ref={refs.setReference}
        onClick={() => setIsCollapsed((prev) => !prev)}
      />
      {isCollapsed &&
        createPortal(
          <List
            id="c-p"
            ref={refs.setFloating}
            style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
            tabIndex={-1}
          >
            <div
              className="dropdown-contentarea"
              id="c-p"
              ref={blurChecker}
              tabIndex={-1}
            >
              <ul
                id="c-p"
                role="menu"
                tabIndex={-1}
                ref={inViewRef}
              >
                {colorList}
              </ul>
            </div>
          </List>,
          document.getElementById('dropdown-root') as HTMLElement
        )}
    </Container>
  );
};
