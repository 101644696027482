import styled from 'styled-components';

export const Container = styled.div`
  background-color: transparent;
  border-radius: 50px;
  height: 94px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 96px;
  -moz-border-radius: 47px;
  -webkit-border-radius: 47px;
`;
