import { Button, Input, MOBILE_MAX_SIZE, Modal, Toggle, useWindowSize } from '@sendible/design-system';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import { Container, Row, ColorBox, Group, Hr, Title, Content, DateArea, Preview } from './index.styles';
import { Calendar, ColorPicker, defaultColor } from './components';
import { useCampaignsContext } from '../../context';

type UpdateStateObject = {
  name?: string;
  color?: string;
};

const MAX_NAME_LENGTH = 50;

export const NewCampaignModal = () => {
  const { activeCampaignObject, isCampaignSaving, setIsEditionModalOpen, isEditionModalOpen, saveCampaignFromObject } = useCampaignsContext();
  const isEditMode = typeof isEditionModalOpen === 'number';
  const [currentCampaign, setCurrentCampaign] = useState<CampaignsData>({ color: defaultColor } as CampaignsData);
  const [isInputError, setIsInputError] = useState(false);
  const [disableToSave, setDisableToSave] = useState(true);
  const { width } = useWindowSize();
  const { t } = useTranslation(['campaigns', 'common_terms']);
  const settingsSaveButtonText = t('settings_save_button');
  const modalTitle = isEditMode ? `${t('dropdown_edit')} ${currentCampaign.name}` : t('sibebar_new_campaigns_button');
  const savingLabel = `${t('saving', { ns: 'common_terms' })}...`;

  const saveButtonLabel = isCampaignSaving ? savingLabel : settingsSaveButtonText;
  const isSaveButtonDisabled = isCampaignSaving || isInputError || disableToSave;

  useEffect(() => {
    if (isEditMode) {
      setCurrentCampaign(activeCampaignObject);
    }
  }, [activeCampaignObject]);

  useEffect(() => {
    const isValidName = currentCampaign.name && currentCampaign.name.trim() !== '';
    const endDateNotDefined = !currentCampaign.endDate;
    const isEmptyDates = !currentCampaign.startDate && endDateNotDefined;
    const isValidRageDates =
      currentCampaign.startDate &&
      currentCampaign.endDate &&
      currentCampaign.startDate !== null &&
      currentCampaign.endDate !== null &&
      currentCampaign.startDate <= currentCampaign.endDate;

    const isValid = isValidName && (isEmptyDates || endDateNotDefined || isValidRageDates);

    setDisableToSave(!isValid);
  }, [currentCampaign]);

  const handleValueChange = (campaignSettings: UpdateStateObject) => {
    if (campaignSettings.name && campaignSettings.name.trim().length > MAX_NAME_LENGTH) {
      setIsInputError(true);

      return;
    }

    setIsInputError(false);

    setCurrentCampaign((prev) => ({
      ...prev,
      ...campaignSettings,
    }));
  };

  return (
    <Modal
      close={() => {
        setIsEditionModalOpen(false);
      }}
      variation={width > MOBILE_MAX_SIZE ? 'large' : 'fullscreen'}
      headingTitle={modalTitle}
      button={{
        action: () => saveCampaignFromObject(currentCampaign),
        actionLabel: saveButtonLabel,
        isDisabled: isSaveButtonDisabled,
        isLoading: isCampaignSaving,
      }}
    >
      <Container>
        <Content>
          <Group>
            <Row $alignment="flex-start">
              <Input
                initialValue={currentCampaign.name}
                feedback={isInputError ? { type: 'danger', message: t('campaign_name_error') || '' } : undefined}
                label={t('input_name_placeholder')}
                change={(value) => {
                  handleValueChange({
                    name: value,
                  });
                }}
              />
              {isEditMode && (
                <Row $height="2.5rem">
                  <Toggle
                    label={width > MOBILE_MAX_SIZE ? 'Active' : undefined}
                    size="sm"
                    checked={currentCampaign.status == 'active'}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setCurrentCampaign((prev) => ({
                        ...prev,
                        status: event.target.checked ? 'active' : 'inactive',
                      }))
                    }
                  />
                </Row>
              )}
            </Row>
            <Hr />
          </Group>
          <Group>
            <Row>
              <ColorBox>
                <Title
                  semantic="h6"
                  variation="component-title_16"
                >
                  {t('color')}
                </Title>
                <ColorPicker
                  currentColor={currentCampaign.color || defaultColor}
                  onColorSelect={(newColor) =>
                    setCurrentCampaign((prev) => ({
                      ...prev,
                      color: newColor,
                    }))
                  }
                />
              </ColorBox>
              <ColorBox>
                <Title
                  semantic="h6"
                  variation="component-title_16"
                >
                  {t('label_preview')}
                </Title>
                <Preview
                  data-testid="campaign-label-preview"
                  $color={currentCampaign.color || defaultColor}
                >
                  <span>{currentCampaign.name || t('label')}</span>
                </Preview>
              </ColorBox>
            </Row>
            <Hr />
          </Group>
          <Group>
            <DateArea>
              <Title
                semantic="h6"
                variation="component-title_16"
              >
                {t('daterange_heading')}
              </Title>
              <Row>
                <Calendar
                  currentCampaign={currentCampaign}
                  setCurrentCampaign={setCurrentCampaign}
                />
              </Row>
            </DateArea>
          </Group>
        </Content>
        {width > MOBILE_MAX_SIZE && (
          <Button
            appearance="primary"
            label={saveButtonLabel}
            isLoading={isCampaignSaving}
            variation="fill"
            disabled={isCampaignSaving || isInputError || disableToSave}
            onClick={() => {
              saveCampaignFromObject(currentCampaign);
            }}
            size={18}
          />
        )}
      </Container>
    </Modal>
  );
};
