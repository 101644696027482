import { EVENTS, publish } from '@sendible/event-manager';
import { advancedLocalStorage, errorUtils, localStorageConsts } from '@sendible/common';

export * from './BridgeContext';

export type composeTabs =
  | 'message'
  | 'twitter'
  | 'facebook'
  | 'googlemybusinesslocation'
  | 'linkedin'
  | 'pinterest'
  | 'tiktok'
  | 'instagram'
  | 'group'
  | 'other';

export interface CountInfo {
  scheduledCount?: number;
  bouncedCount?: number | string;
  taskCount?: number;
  scheduledTeamCount?: number;
}

export interface ComposeStore {
  activeComposeBoxTab: composeTabs;
  isComposeBoxOpen: boolean;
  isMediaAttacherOpen: boolean;
}

export interface UserStore {
  accessToken: string;
  userId: number;
  username: string;
  email: string;
  language: string;
  timezone: number;
  featureTags: object;
  isWhiteLabel: boolean;
  canNavigate: boolean;
  isSwitchedUser: boolean;
  countLabels?: CountInfo;
}

export interface AppStore {
  isCreativeEditorOpen: { url: string | null; serviceSet: null | string };
}

export interface SharedStateType {
  compose: ComposeStore;
  user: UserStore;
  app: AppStore;
}

export type BridgeContextType = [SharedStateType, React.Dispatch<React.SetStateAction<SharedStateType>>];

function getSharedState() {
  return advancedLocalStorage.getItem(localStorageConsts.sharedStateKey);
}

function setItem(value: SharedStateType) {
  advancedLocalStorage.setItem(localStorageConsts.sharedStateKey, value);
  publish(EVENTS.LOCAL_STORAGE_CHANGED, localStorageConsts.sharedStateKey);
}

function updateSpecificKey(store: string, key: string, value: unknown) {
  const existingBridgeStateValue = advancedLocalStorage.getItem(localStorageConsts.sharedStateKey);
  const newBridgeStateValue = {
    ...existingBridgeStateValue,
  };

  if (!newBridgeStateValue[store]) {
    return;
  }

  try {
    newBridgeStateValue[store][key] = value;

    setItem(newBridgeStateValue);
  } catch (error: unknown) {
    errorUtils.sendErrorDirectlyToOnerror(error as string);
  }
}

export default {
  getSharedState,
  setItem,
  updateSpecificKey,
};
