export const badge_instagram = () => (
  <>
    <path
      d="M14.325 4l1.33 2.06 2.451.12.124 2.451 2.06 1.33-1.117 2.184 1.117 2.18-2.06 1.33-.12 2.451-2.451.124-1.33 2.06-2.184-1.117-2.18 1.117-1.33-2.06-2.451-.12-.124-2.451L4 14.329l1.117-2.184L4 9.965l2.06-1.33.12-2.451L8.63 6.06 9.961 4l2.184 1.117L14.325 4z"
      fill="#42A5F5"
    />
    <path
      d="M11.133 14.957L8.616 12.44l.822-.822 1.71 1.706 3.708-3.595.81.834-4.533 4.394z"
      fill="#fff"
    />
  </>
);
