export const gear = () => (
  <>
    <path
      clipRule="evenodd"
      d="M20.11 13.457c.083-.473.135-.96.135-1.457 0-.498-.052-.985-.136-1.457L21.4 9.48c.635-.522.9-1.387.553-2.132a11.043 11.043 0 00-.946-1.644c-.47-.674-1.352-.877-2.122-.59l-1.566.587a8.234 8.234 0 00-2.529-1.455l-.276-1.66c-.134-.807-.745-1.469-1.56-1.543a10.584 10.584 0 00-1.916 0c-.814.074-1.424.736-1.559 1.542l-.277 1.661a8.233 8.233 0 00-2.528 1.455l-1.566-.586c-.77-.288-1.653-.085-2.123.59-.362.52-.679 1.07-.947 1.644-.347.744-.08 1.609.553 2.13l1.292 1.064A8.34 8.34 0 003.745 12c0 .498.053.985.137 1.457L2.59 14.52c-.633.522-.9 1.387-.553 2.13.268.575.585 1.126.947 1.646.47.674 1.353.877 2.123.59l1.566-.587A8.233 8.233 0 009.2 19.754l.277 1.66c.135.807.745 1.468 1.56 1.542a10.62 10.62 0 001.915 0c.815-.073 1.426-.735 1.56-1.542l.277-1.66a8.233 8.233 0 002.528-1.455l1.566.586c.77.288 1.653.085 2.122-.59.362-.52.679-1.07.947-1.643.347-.745.08-1.61-.553-2.132l-1.29-1.063z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15a3 3 0 100-6 3 3 0 000 6z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
