import { FC } from 'react';
import { Spinner } from '@sendible/design-system';
import { Container } from './index.styles';

const SendibleLoadingIcon: FC = () => (
  <Container>
    <Spinner size="xl" />
  </Container>
);

export default SendibleLoadingIcon;
