import { InputHTMLAttributes, useState, useRef, useEffect } from 'react';
import { Container } from './index.styles';

export const labelPositions = ['left', 'right'] as const;
export const sizes = ['sm', 'md', 'lg'] as const;

type RestProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'type'> & Component;

export interface ToggleProps extends RestProps {
  /**
   * ID attribute. Optional.
   */
  id?: string;
  /**
   * Label. Required.
   */
  label?: string;
  /**
   * Label position. Optional.
   */
  labelPosition?: (typeof labelPositions)[number];
  /**
   * Sizing variations. Optional.
   */
  size?: (typeof sizes)[number];
}

/**
 * Toggle
 */
export const Toggle = ({
  className = '',
  customClass = '',
  id,
  size = 'md',
  label,
  labelPosition = 'left',
  testId = 'toggle',
  ...rest
}: ToggleProps) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current?.disabled) setIsDisabled(true);
  }, [ref.current, ref.current?.disabled]);

  return (
    <Container
      className={`toggle toggle-${size} ${customClass} ${className}`}
      data-testid={testId}
      htmlFor={id}
      $isDisabled={isDisabled}
      $size={size}
    >
      {label && labelPosition === 'left' && label}
      <input
        id={id}
        type="checkbox"
        ref={ref}
        {...rest}
      />
      {label && labelPosition === 'right' && label}
    </Container>
  );
};
