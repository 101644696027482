import { MOBILE_MAX_SIZE, useWindowSize } from '@sendible/design-system/src';
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { mediasPerPageDesktop, mediasPerPageMobile, defaultMediaLibrary, mediaLibraryIdParam, mediaSearchParam } from './constants';

export interface MediaToDelete {
  media: MediaType;
  source: 'preview' | 'content';
}

type MediaLibraryContextType = {
  activeMediaLibrary: MediaLibraryType;
  isCreationModalOpen: boolean;
  isDeleteModalOpen: boolean;
  isMobile: boolean;
  isRenameModalOpen: boolean;
  mediaSearch: string;
  mediaToDelete: MediaToDelete | undefined;
  onNavigate: (mediaLibrary: MediaLibraryType) => void;
  perPage: number;
  selectedMediasIds: number[];
  setActiveMediaLibrary: Dispatch<SetStateAction<MediaLibraryType>>;
  setIsCreationModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsMobile: Dispatch<SetStateAction<boolean>>;
  setIsRenameModalOpen: Dispatch<SetStateAction<boolean>>;
  setMediaSearch: Dispatch<SetStateAction<string>>;
  setMediaToDelete: Dispatch<SetStateAction<MediaToDelete | undefined>>;
  setPerPage: Dispatch<SetStateAction<number>>;
  setSelectedMediasIds: Dispatch<SetStateAction<number[]>>;
  setSidebarIsVisible: Dispatch<SetStateAction<boolean>>;
  sidebarIsVisible: boolean;
};

export const MediaLibraryContext = createContext({} as MediaLibraryContextType);

export const MediaLibraryProvider = ({ children }: Component) => {
  const { width } = useWindowSize();
  const [mediaToDelete, setMediaToDelete] = useState<MediaToDelete | undefined>(undefined);
  const [activeMediaLibrary, setActiveMediaLibrary] = useState(defaultMediaLibrary);
  const [sidebarIsVisible, setSidebarIsVisible] = useState(false);
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(width <= MOBILE_MAX_SIZE);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [mediaSearch, setMediaSearch] = useState('');
  const [perPage, setPerPage] = useState(isMobile ? mediasPerPageMobile : mediasPerPageDesktop);
  const [selectedMediasIds, setSelectedMediasIds] = useState<number[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const onNavigate = (mediaLibrary: MediaLibraryType) => {
    setMediaSearch('');
    setSearchParams({ ...Object.fromEntries(searchParams), [mediaSearchParam]: '' });
    setActiveMediaLibrary(mediaLibrary);
    setSidebarIsVisible(false);
  };

  useEffect(() => setIsMobile(width <= MOBILE_MAX_SIZE), [width]);
  useEffect(() => setPerPage(isMobile ? mediasPerPageMobile : mediasPerPageDesktop), [isMobile]);

  useEffect(() => {
    const currentMediaSearch = searchParams.get(mediaSearchParam);

    if (currentMediaSearch) setMediaSearch(currentMediaSearch);
  }, []);

  useEffect(() => setSearchParams({ ...Object.fromEntries(searchParams), [mediaSearchParam]: mediaSearch }), [mediaSearch]);

  useEffect(() => {
    const setMediaLibraryIdParam = (mediaLibraryId: string) => {
      setSearchParams({ ...Object.fromEntries(searchParams), [mediaLibraryIdParam]: mediaLibraryId });
    };

    if (activeMediaLibrary?.id) setMediaLibraryIdParam(activeMediaLibrary.id);
    setSelectedMediasIds([]);
  }, [activeMediaLibrary.id]);

  return (
    <MediaLibraryContext.Provider
      value={{
        activeMediaLibrary,
        isCreationModalOpen,
        isDeleteModalOpen,
        isMobile,
        isRenameModalOpen,
        mediaSearch,
        mediaToDelete,
        onNavigate,
        perPage,
        selectedMediasIds,
        setActiveMediaLibrary,
        setIsCreationModalOpen,
        setIsDeleteModalOpen,
        setIsMobile,
        setIsRenameModalOpen,
        setMediaSearch,
        setMediaToDelete,
        setPerPage,
        setSelectedMediasIds,
        setSidebarIsVisible,
        sidebarIsVisible,
      }}
    >
      {children}
    </MediaLibraryContext.Provider>
  );
};

export const useMediaLibraryContext = (): MediaLibraryContextType => useContext(MediaLibraryContext);
