import styled from 'styled-components';
import { Box } from '@sendible/design-system';

export const Container = styled(Box)`
  gap: 0.5rem;
  margin-top: 1rem;
  width: 100%;

  .button {
    flex: 1;
  }
`;
