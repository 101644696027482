/* eslint-disable react/no-unescaped-entities */
import { Button, Icon, Text, Tooltip } from '@sendible/design-system';
import { EVENTS, publish } from '@sendible/event-manager';
import { Container, Content, ErrorContainer, Row } from './index.styles';
import { CaptionConfiguration } from '../CaptionConfiguration';
import { TextArea } from '../TextArea';
import { GenerateButton } from '../GenerateButton';
import { TooltipInfo } from '../TooltipInfo';
import { CharacterCounter } from '../CharacterCounter';
import { aIAssistantCloseWithNoContent } from '../../pendoEvents';
import { useAiAssistContext } from '../../AiAssistContext';

export const ComposeBox = () => {
  const { error } = useAiAssistContext();

  return (
    <Container data-testid="ai-assist-modal">
      <Content>
        <Row>
          <CaptionConfiguration />
          <Row $gap={0.5}>
            <Tooltip
              content={<TooltipInfo />}
              defaultPosition="top-end"
              useClick
            >
              <Icon
                name="info"
                color="brandDark"
              />
            </Tooltip>
            <Button
              onClick={() => {
                window.pendo.track(aIAssistantCloseWithNoContent);
                publish(EVENTS.CLOSE_AI_ASSIST, {});
              }}
              icon="close_lg"
              size={14}
            />
          </Row>
        </Row>
        <Row>
          <TextArea />
          <Row $gap={0.5}>
            <CharacterCounter />
            <GenerateButton />
          </Row>
        </Row>
        {error && error.message && (
          <ErrorContainer horizontalGap="s8">
            <Icon
              name="danger"
              color="semanticDangerDarkMode"
            />
            <Text color="semanticDangerDarkMode">{error.message}</Text>
          </ErrorContainer>
        )}
      </Content>
    </Container>
  );
};
