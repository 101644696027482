import { Box, Button, Modal } from '@sendible/design-system/src';
import { useTranslation } from 'react-i18next';
import { Container } from './index.styles';

type DeleteModalProps = {
  closeModalCallback: () => void;
  deleteCallback: () => void;
};

export const DeleteModal = ({ closeModalCallback, deleteCallback }: DeleteModalProps) => {
  const { t } = useTranslation('campaigns');

  const deleteModalHeaderText = t('delete_modal_header_text');
  const deleteModalText = t('delete_modal_text');
  const deleteModalCancelButton = t('delete_modal_cancel_text');
  const deleteModalYesButton = t('delete_modal_yes_text');

  return (
    <Modal
      close={closeModalCallback}
      customClass="global-modal"
      headingTitle={deleteModalHeaderText}
      testId="global-modal"
      variation="small"
      ariaCloseLabel="close delete campaign menu"
    >
      <Box
        orientation="column"
        flex={1}
      >
        <p>{deleteModalText}</p>
        <Container contentJustify="flex-end">
          <Button
            onClick={closeModalCallback}
            label={deleteModalCancelButton}
            size={16}
            variation="outline"
            autoFocus
          />
          <Button
            appearance="primary"
            onClick={deleteCallback}
            label={deleteModalYesButton}
            isLoading={false}
            size={16}
            variation="fill"
          />
        </Container>
      </Box>
    </Modal>
  );
};
