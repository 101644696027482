import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import { Container } from './index.styles';
import { NoDataLoadingComponent } from '../NoDataLoadingComponent';
import { getTSpanGroups } from '../../helpers';

type HorizontalPercentageBarChartProps = {
  children?: ReactNode;
  color?: string;
  data: BarDatum[];
  isLoading: boolean;
  profileLength: number;
  title?: string;
  total: number;
};

export const BarChart = ({
  children,
  color = '#000000',
  data,
  isLoading = false,
  profileLength = 1,
  title = '',
  total = 0,
}: HorizontalPercentageBarChartProps) => {
  const { t } = useTranslation('reports');
  const rowHeightRem = 2.5;
  const paddingRem = 6;

  if (!data.length || isLoading) {
    return (
      <NoDataLoadingComponent
        noData={!data.length}
        isLoading={isLoading}
        title={title}
      />
    );
  }

  const chartHeight = profileLength * rowHeightRem + paddingRem;
  const audienceLegendText = t('audience_chart_audience_legend');

  return (
    <Container height={chartHeight}>
      {children}
      <ResponsiveBar
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: audienceLegendText,
          legendPosition: 'middle',
          legendOffset: 40,
        }}
        axisLeft={{
          renderTick: ({ opacity, textAnchor, textX, textY, value, x, y }) => {
            return (
              <g
                transform={`translate(${x},${y})`}
                style={{ opacity }}
              >
                <text
                  textAnchor={textAnchor}
                  transform={`translate(${textX},${textY})`}
                >
                  {getTSpanGroups(value, 30, 2)}
                </text>
              </g>
            );
          },
        }}
        colors={[color]}
        data={data}
        indexBy="profile"
        labelTextColor="#FFFFFF"
        layout="horizontal"
        margin={{ top: 10, right: 10, bottom: 50, left: 250 }}
        padding={0.3}
        valueFormat={(value) => `${((value / total) * 100).toFixed(1)}%`}
      />
    </Container>
  );
};
