export const corner_up_right = () => (
  <>
    <path
      d="M15 14l5-5-5-5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 20v-7a4 4 0 014-4h12"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
