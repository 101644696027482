import { FC } from 'react';
import { ContentStyled, GridStyled } from './index.styles';
import Skeletons from './Skeletons';

type Props = {
  perPage: number;
};

const LoadingContentSection: FC<Props> = ({ perPage, children }) => {
  return (
    <ContentStyled>
      {children}
      <GridStyled
        horizontalGap="s16"
        verticalGap="s16"
        padding={['s24', 's24', 's24', 's24']}
      >
        <Skeletons perPage={perPage} />
      </GridStyled>
    </ContentStyled>
  );
};

export default LoadingContentSection;
