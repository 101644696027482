import { Box, Icon, Text } from '@sendible/design-system/src';
import { useTranslation } from 'react-i18next';
import { Container, AudienceChangeText } from './index.styles';
import { NoDataLoadingComponent } from '../NoDataLoadingComponent';

type AudienceChangeComponentProps = {
  audienceGainedPercentage: number | null;
  color?: string;
  isLoading: boolean;
  startDate?: string | null;
  endDate?: string | null;
};

export const AudienceChangeComponent = ({
  color = '#000000',
  audienceGainedPercentage,
  isLoading = false,
  startDate = null,
  endDate = null,
}: AudienceChangeComponentProps) => {
  const { t } = useTranslation('reports');

  const title = t('audience_change_title');

  if (!audienceGainedPercentage || isLoading) {
    return (
      <NoDataLoadingComponent
        noData={!audienceGainedPercentage}
        isLoading={isLoading}
        title={title}
      />
    );
  }

  const hasAudienceIncreased = audienceGainedPercentage > -1;
  const audienceChartText = hasAudienceIncreased ? t('audience_chart_increased_by') : t('audience_chart_decreased_by');
  const notDefinedText = t('not_defined');
  const sincePreviousPeriodText = t('since_previous_period');

  const getDateRangeComponent = () => {
    if (!startDate && endDate) {
      return (
        <>
          {notDefinedText} - {endDate}
        </>
      );
    }

    if (startDate && !endDate) {
      return (
        <>
          {startDate} - {notDefinedText}
        </>
      );
    }

    if (startDate && endDate) {
      return (
        <>
          {startDate} - {endDate}
        </>
      );
    }

    return sincePreviousPeriodText;
  };

  return (
    <Container $color={color}>
      <Text color="brandLight">{audienceChartText}</Text>
      <Box
        contentAlignment="center"
        margin={['s0', 's0', 's12', 's0']}
      >
        {hasAudienceIncreased ? (
          <Icon
            color="brandLight"
            name="arrow_up"
            strokeWidth={5}
          />
        ) : (
          <Icon
            color="brandLight"
            name="arrow_down"
            strokeWidth={5}
          />
        )}
        <AudienceChangeText
          color="brandLight"
          variation="body_16"
        >
          {audienceGainedPercentage.toFixed(2)}%
        </AudienceChangeText>
      </Box>
      {getDateRangeComponent()}
    </Container>
  );
};
