import { useState, ChangeEvent, useEffect } from 'react';
import { Container } from './index.styles';
import { generateId } from '../../utils/helpers';
import { Radio } from '../../atoms/Radio';

export const orientations = ['row', 'column'] as const;
export const sizes = ['sm', 'md', 'lg', 'xl'] as const;

export interface RadioGroupProps extends Component {
  /**
   * Change event emitter. Required.
   */
  change: (e: string) => void;
  /**
   * Indicates visual error feedback. Optional.
   */
  hasError?: boolean;
  /**
   * Disable state attribute. Optional.
   */
  isDisabled?: boolean;
  /**
   * Name attribute. Required.
   */
  name: string;
  /**
   * Option list. Required.
   */
  options: string[];
  /**
   * Display orientation. Optional.
   */
  orientation?: (typeof orientations)[number];
  /**
   * Sizing variations. Optional.
   */
  size?: (typeof sizes)[number];
  /**
   * To have a default selected option. Optional.
   */
  defaultOption?: string;
}

/**
 * RadioGroup
 */
export const RadioGroup = (props: RadioGroupProps) => {
  const {
    defaultOption,
    change,
    className = '',
    customClass = '',
    hasError,
    isDisabled,
    name,
    options,
    orientation,
    size = 'md',
    testId = 'radiogroup',
  } = props;

  const [selectedOption, SetSelectedOption] = useState<string>('');

  useEffect(() => {
    if (defaultOption) {
      SetSelectedOption(defaultOption);
    }
  }, []);

  const handleChange = (option: string) => {
    change(option);
    SetSelectedOption(option);
  };

  return (
    <Container
      className={`radiogroup radiogroup-${orientation}-${size} ${customClass} ${className}`}
      data-testid={testId}
      onChange={(e: ChangeEvent<HTMLDivElement>) => handleChange(e.target.id)}
      orientation={orientation}
      size={size}
    >
      {options.map((option) => (
        <Radio
          groupName={name}
          key={generateId()}
          label={option}
          hasError={hasError}
          isChecked={selectedOption === option}
          isDisabled={isDisabled}
          size={size}
        />
      ))}
    </Container>
  );
};
