declare global {
  const HUBSPOT_APP_ID: string;
  const WEBPACK_API: string;
  const WEBPACK_APP: string;
}
export default {
  baseUrl: `https://${WEBPACK_API}`,
  appBaseUrl: `https://${WEBPACK_APP}`,
  hubspotScripts: `https://js.hs-scripts.com/${HUBSPOT_APP_ID}.js`,
  hubspotForms: 'https://js.hsforms.net/forms/v2.js',
  firstPromoter: 'https://cdn.firstpromoter.com/fprom.js',
  signupUrl: `https://${WEBPACK_API}/1.1/api/signup`,
  hubspotSignupStyles: 'https://2235233.fs1.hubspotusercontent-na1.net/hubfs/2235233/Signup%20Assets/2022/style.css',
  hubspotSignupScript: 'https://2235233.fs1.hubspotusercontent-na1.net/hubfs/2235233/Signup%20Assets/2022/script.js',
};
