import styled, { css } from 'styled-components';

export const Container = styled.button<{ $hasExtraLabel: boolean; $useStroke: boolean }>`
  ${({ theme: { borderRadius, colors }, $hasExtraLabel, $useStroke }) => css`
    all: unset;
    align-items: center;
    border-radius: ${borderRadius.sm};
    box-sizing: border-box;
    color: ${colors.brandDark};
    cursor: pointer;
    display: flex;
    gap: 0.5rem;
    padding: 0.175rem 1.25rem 0.175rem 0.5rem;
    width: 100%;

    * {
      flex-shrink: 0;
    }

    .icon {
      height: 1.25rem;
      width: 1.25rem;
    }

    ${$hasExtraLabel &&
    css`
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;

      & .text:nth-child(1) {
        line-height: 1.125rem;
      }

      & .text:nth-child(2) {
        line-height: 1rem;
      }
    `}

    &:hover {
      background: ${colors.brandPrimaryAlpha10};

      .text {
        color: ${colors.brandPrimary};
      }

      .icon {
        path {
          stroke: ${$useStroke && colors.brandPrimary};
        }
      }
    }
  `}
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`;
