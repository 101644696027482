import { Box } from '@sendible/design-system/src';
import styled from 'styled-components';

export const OverviewMainContainer = styled(Box)`
  box-sizing: border-box;
  width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
`;

export const OverviewContainer = styled(Box)`
  flex-direction: column;
  width: 100%;
  padding: 1.875rem 1.875rem 2.5rem 1.875rem;
  border-radius: 0.375rem;
  background-color: #4d36ff;
  color: #ffffff;
  gap: 1rem;
`;

export const OverviewDataContainer = styled(Box)`
  flex-direction: row;
  width: 100%;
  gap: 0 0;
`;

export const ProfilesOverviewContainer = styled(Box)`
  flex-wrap: wrap;
  width: 8.125rem;
  padding: 0 3.5% 0 0;
`;

export const ProfileOverviewItemContainer = styled(Box)`
  border-radius: 0.375rem;
  box-shadow: 0 0 0 0.188rem rgba(255, 255, 255, 0.2);
  margin: 0.438rem 0.938rem 0.5rem 0;
  width: 3rem;
  height: 3rem;
  font-size: 1.063rem;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  line-height: 2.3;
`;
