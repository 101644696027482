export const flash_off = () => (
  <>
    <g clipPath="url(#prefix__clip0_904_856)">
      <path
        d="M13 6.75V2l-2.43 2.92M15.5 10H21l-2.356 3.142M8 8l-5 6h9v8l4.287-5.716M1 1l22 22"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_904_856">
        <path
          fill="inherit"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </>
);
