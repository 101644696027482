export default {
  cleanRoute: (pathname: string): string => pathname?.replace(/^\/?#?/gi, ''),
  getPathWithoutSubPaths: (path: string) => {
    const pathWithoutSubPathsMatch = path.match(/^([^/]*\/)[^/]*/);

    if (pathWithoutSubPathsMatch && pathWithoutSubPathsMatch.length > 0) {
      return pathWithoutSubPathsMatch[0];
    }

    return '';
  },
};
