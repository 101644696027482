import { AnimatePresence } from 'framer-motion';
import { LeftSidebar, NewCampaignModal, Overview } from './components';
import { useCampaignsContext } from './context';
import { BlankState } from './components/BlankState';
import { LoadingComponent } from './components/LoadingComponent';
import { Content } from './page.styles';
import { ErrorBoundary, ErrorState } from '../../components';

export const CampaignsPage = () => {
  const { campaignsError, isCampaignsLoading, campaignsList, isEditionModalOpen } = useCampaignsContext();

  if (isCampaignsLoading) {
    return <LoadingComponent />;
  }

  if (campaignsError) {
    return (
      <ErrorState
        errorMessage={campaignsError}
        translationNamespace="campaigns"
      />
    );
  }

  return (
    <>
      <AnimatePresence>{isEditionModalOpen && <NewCampaignModal />}</AnimatePresence>
      {campaignsList.length ? <LeftSidebar /> : ''}
      <ErrorBoundary translationNamespace="campaigns">
        <Content>{campaignsList.length ? <Overview /> : <BlankState />}</Content>
      </ErrorBoundary>
    </>
  );
};
