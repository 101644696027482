import styled, { css } from 'styled-components';
import { ButtonProps } from '.';

interface TransientProps {
  $appearance?: ButtonProps['appearance'];
  $darkmode?: ButtonProps['darkmode'];
  $floatAppearance?: ButtonProps['floatAppearance'];
  $hasIcon?: boolean;
  $iconPosition?: ButtonProps['iconPosition'];
  $isLoading?: ButtonProps['isLoading'];
  $label?: ButtonProps['label'];
  $size?: ButtonProps['size'];
  $variation?: ButtonProps['variation'];
}

export const Container = styled.button<TransientProps>`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
      fontSizes,
      fontWeights,
      motions,
      motionSpeeds,
    },
    $appearance = 'default',
    $darkmode,
    $floatAppearance,
    $hasIcon,
    $iconPosition = 'left',
    $isLoading,
    $label,
    $size = 12,
    $variation = 'default',
  }) => css`
    align-items: center;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 10rem;
    cursor: pointer;
    display: flex;
    font-family: inherit;
    flex-direction: row;
    flex-shrink: 0;
    gap: 0.5em;
    justify-content: center;
    line-height: 1em;
    position: relative;
    transition: all ${motionSpeeds.fast} ${motions.default};
    user-select: none;

    span {
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      font-weight: ${fontWeights.medium};
      font-size: inherit;
      line-height: 1.5em;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
    }

    &:focus-visible:active {
      transform: scale(0.95);
    }

    ${$hasIcon &&
    css`
      padding: 0;

      svg,
      .spinner {
        height: 1.5em;
        min-width: 1.5em;
        pointer-events: none;
        order: ${$iconPosition === 'left' ? 0 : 1};
        width: 1.5em;
      }
    `}

    ${$appearance === 'default' &&
    css`
      &:active {
        box-shadow: 0 0 0 3px ${colors.brandDarkAlpha20};

        ${$darkmode &&
        css`
          box-shadow: 0 0 0 3px ${colors.brandLightAlpha60};
        `}
      }
    `}

    ${$appearance === 'primary' &&
    css`
      &:active {
        box-shadow: 0 0 0 3px ${colors.brandPrimaryAlpha20};

        ${$darkmode &&
        css`
          box-shadow: 0 0 0 3px ${colors.brandPrimaryTint20};
        `}
      }
    `}

    ${$variation === 'default' || $variation === 'outline'
      ? css`
          ${$appearance === 'default' &&
          css`
            color: ${colors.brandDark};

            svg {
              stroke: ${colors.brandDark};

              * {
                stroke: ${colors.brandDark};
              }
            }

            &:hover:not([disabled]),
            &:active,
            &:focus-visible {
              background: ${colors.brandDarkAlpha5};
            }
          `}

          ${$appearance === 'primary' &&
          css`
            color: ${colors.brandPrimary};

            svg {
              stroke: ${colors.brandPrimary};

              * {
                stroke: ${colors.brandPrimary};
              }
            }

            &:hover:not([disabled]),
            &:active,
            &:focus-visible {
              background: ${colors.brandPrimaryAlpha5};
            }
          `}

      ${$appearance !== 'default' &&
          $appearance !== 'primary' &&
          css`
            display: none;
            visibility: hidden;
          `}
        `
      : null}

    ${$variation === 'outline' &&
    css`
      ${$appearance === 'default' &&
      css`
        border-color: ${colors.brandDarkAlpha20};

        &:active {
          border-color: ${colors.brandDark};
        }

        &:focus-visible {
          border-color: ${colors.brandDarkAlpha20};
        }
      `}

      ${$appearance === 'primary' &&
      css`
        border-color: ${colors.brandPrimaryAlpha50};

        &:active {
          border-color: ${colors.brandPrimary};
        }
      `}

      ${$appearance !== 'default' &&
      $appearance !== 'primary' &&
      css`
        display: none;
        visibility: hidden;
      `}
    `}

    ${$variation === 'fill' &&
    css`
      ${$appearance === 'default' &&
      css`
        background: ${colors.brandDark};
        color: ${colors.brandLight};

        svg {
          stroke: ${colors.brandLight};

          * {
            stroke: ${colors.brandLight};
          }
        }

        &:hover:not([disabled]),
        &:active,
        &:focus-visible {
          background: ${colors.brandDarkTint20};
        }
      `}

      ${$appearance === 'primary' &&
      css`
        background: ${colors.brandPrimary};
        color: ${colors.brandLight};

        svg {
          stroke: ${colors.brandLight};

          * {
            stroke: ${colors.brandLight};
          }
        }

        &:hover:not([disabled]),
        &:active,
        &:focus-visible {
          background: ${colors.brandPrimaryShade20};
        }
      `}

      ${$appearance === 'success' &&
      css`
        background: ${colors.semanticSuccess};
        color: ${colors.brandDark};

        svg {
          stroke: ${colors.brandDark};

          * {
            stroke: ${colors.brandDark};
          }
        }

        &:hover:not([disabled]),
        &:active,
        &:focus-visible {
          background: ${colors.semanticSuccessShade10};
        }

        &:active {
          box-shadow: 0 0 0 3px ${colors.semanticSuccessAlpha20};
        }
      `}

      ${$appearance === 'danger' &&
      css`
        background: ${colors.semanticDanger};
        color: ${colors.brandLight};

        svg {
          stroke: ${colors.brandLight};

          * {
            stroke: ${colors.brandLight};
          }
        }

        &:hover:not([disabled]),
        &:active,
        &:focus-visible {
          background: ${colors.semanticDangerShade10};
        }

        &:active {
          box-shadow: 0 0 0 3px ${colors.semanticDangerAlpha20};
        }
      `}

      ${$appearance === 'warning' &&
      css`
        background: ${colors.semanticWarning};
        color: ${colors.brandDark};

        svg {
          stroke: ${colors.brandDark};

          * {
            stroke: ${colors.brandDark};
          }
        }

        &:hover:not([disabled]),
        &:active,
        &:focus-visible {
          background: ${colors.semanticWarningShade10};
        }

        &:active {
          box-shadow: 0 0 0 3px ${colors.semanticWarningAlpha20};
        }
      `}

      ${$appearance === 'info' &&
      css`
        background: ${colors.semanticInfo};
        color: ${colors.brandLight};

        svg {
          stroke: ${colors.brandLight};

          * {
            stroke: ${colors.brandLight};
          }
        }

        &:hover:not([disabled]),
        &:active,
        &:focus-visible {
          background: ${colors.semanticInfoShade10};
        }

        &:active {
          box-shadow: 0 0 0 3px ${colors.semanticInfoAlpha20};
        }
      `}

      ${$appearance === 'secondary' &&
      css`
        display: none;
        visibility: hidden;
      `}
    `}

    ${$darkmode &&
    css`
      ${$variation === 'default' || $variation === 'outline'
        ? css`
            ${$appearance === 'default' &&
            css`
              color: ${colors.brandLight};

              svg {
                stroke: ${colors.brandLight};

                * {
                  stroke: ${colors.brandLight};
                }
              }

              &:hover:not([disabled]),
              &:active,
              &:focus-visible {
                background: ${colors.brandLightAlpha10};
              }
            `}

            ${$appearance !== 'default' &&
            css`
              display: none;
              visibility: hidden;
            `}
          `
        : null}

      ${$variation === 'outline' &&
      css`
        ${$appearance === 'default' &&
        css`
          border-color: ${colors.brandLightAlpha60};

          &:active {
            border-color: ${colors.brandLight};
          }

          &:focus-visible {
            border-color: ${colors.brandLightAlpha60};
          }
        `}
      `}

    ${$variation === 'fill' &&
      css`
        ${$appearance === 'default' &&
        css`
          background: ${colors.brandLight};
          color: ${colors.brandDark};

          svg {
            stroke: ${colors.brandDark};

            * {
              stroke: ${colors.brandDark};
            }
          }

          &:hover:not([disabled]),
          &:active,
          &:focus-visible {
            background: ${colors.brandLightTint90};
          }
        `}

        ${$appearance === 'primary' &&
        css`
          background: ${colors.brandPrimaryTint90};
          color: ${colors.brandPrimary};

          svg {
            stroke: ${colors.brandPrimary};

            * {
              stroke: ${colors.brandPrimary};
            }
          }

          &:hover:not([disabled]),
          &:active,
          &:focus-visible {
            background: ${colors.brandPrimaryTint80};
          }
        `}

        ${$appearance !== 'default' &&
        $appearance !== 'primary' &&
        css`
          display: none;
          visibility: hidden;
        `}
      `}
    `}

    ${$size === 12 &&
    css`
      font-size: ${fontSizes.s12};
      height: 2rem;
      min-width: 2rem;
    `}

    ${$size === 14 &&
    css`
      font-size: ${fontSizes.s14};
      height: 2.5rem;
      min-width: 2.5rem;
    `}

    ${$size === 16 &&
    css`
      font-size: ${fontSizes.s16};
      height: 3rem;
      min-width: 3rem;
    `}

    ${$size === 18 &&
    css`
      font-size: ${fontSizes.s18};
      height: 3.5rem;
      min-width: 3.5rem;
    `}

    ${$label &&
    css`
      padding: 0 1.5em;
    `}

    ${!$label &&
    css`
      justify-content: center;
      width: 1.5em;
    `}

    &:focus-visible {
      box-shadow: 0 0 0 3px transparent;
      outline: 2px solid ${colors.brandPrimaryAlpha60};
      outline-offset: 2px;

      ${$darkmode &&
      css`
        outline: 2px solid ${colors.brandPrimaryTint60};
      `}
    }

    &:disabled {
      background: ${colors.brandDarkAlpha5};
      border-color: transparent;
      box-shadow: none;
      color: ${colors.brandDarkAlpha60};
      cursor: not-allowed;
      outline: none !important;
      transform: none;

      svg {
        stroke: currentColor;
        * {
          stroke: currentColor;
        }
      }

      &:active {
        transform: none;
      }

      ${$darkmode &&
      css`
        color: ${colors.brandLightAlpha40};

        &:hover {
          background: ${colors.brandLightAlpha10};
        }
      `}
    }

    ${$isLoading &&
    css`
      cursor: wait;
    `}

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      ${!$label &&
      css`
        padding: 0;
      `}

      ${$floatAppearance &&
      css`
        box-shadow: 0px 0.25rem 0.5rem ${colors.brandDarkAlpha10}, 0px 0.25rem 0.5rem ${colors.brandDarkAlpha10};
        bottom: 1.5rem;
        display: flex;
        font-size: ${fontSizes.s16};
        height: 3rem;
        min-width: 3rem;
        position: fixed;
        right: 1.5rem;
        visibility: visible;
        padding: 0;
        z-index: 2;

        span {
          display: none;
          visibility: none;
        }

        ${$floatAppearance === 'default' &&
        css`
          background: ${colors.brandDark};

          svg {
            stroke: ${colors.brandLight};

            * {
              stroke: ${colors.brandLight};
            }
          }

          &:hover:not([disabled]),
          &:active,
          &:focus-visible {
            background: ${colors.brandDarkTint20};
          }
        `}

        ${$floatAppearance === 'primary' &&
        css`
          background: ${colors.brandPrimary};

          svg {
            stroke: ${colors.brandLight};

            * {
              stroke: ${colors.brandLight};
            }
          }

          &:hover:not([disabled]),
          &:active,
          &:focus-visible {
            background: ${colors.brandPrimaryShade20};
          }
        `}

        ${$floatAppearance === 'secondary' &&
        css`
          background: ${colors.brandLight};

          svg {
            stroke: ${colors.brandDark};

            * {
              stroke: ${colors.brandDark};
            }
          }

          &:hover:not([disabled]),
          &:active,
          &:focus-visible {
            background: ${colors.brandDarkAlpha5};
          }
        `}

        ${$floatAppearance !== 'default' &&
        $floatAppearance !== 'primary' &&
        $floatAppearance !== 'secondary' &&
        css`
          display: none;
          visibility: hidden;
        `}
      `}
    }
  `}
`;
