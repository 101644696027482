import { DateTime } from 'luxon';
import { DateButtonStyled } from './index.styles';

type DateButtonProps = {
  date: DateTime;
  index: number;
  selectedDateRanges: SelectedDateRangeType;
  dateSelectedCallback: (date: DateTime) => void;
};

function isDateBetween(dateToCheck: DateTime, startDate: DateTime, endDate: DateTime) {
  const minDate = DateTime.min(startDate, endDate);
  const maxDate = DateTime.max(startDate, endDate);

  const min = minDate.plus(1);
  const max = maxDate.minus(1);

  return dateToCheck >= min && dateToCheck <= max;
}

const isSelectedDay = (date: DateTime, startDate: CampaignsDateTime, endDate: CampaignsDateTime) => {
  if (startDate && date?.hasSame(startDate, 'day')) {
    return true;
  }
  if (endDate && date?.hasSame(endDate, 'day')) {
    return true;
  }

  return false;
};

export const DateButton = ({ index, date, selectedDateRanges, dateSelectedCallback }: DateButtonProps) => {
  const { startDate, endDate } = selectedDateRanges;
  const dateInBetween = startDate && endDate ? isDateBetween(date, startDate, endDate) : false;

  return (
    <DateButtonStyled
      onClick={() => {
        dateSelectedCallback(date);
      }}
      key={`day-${index}`}
      tabIndex={date ? 0 : -1}
      type="button"
      $isCurrentDay={isSelectedDay(date, startDate, endDate)}
      $isDateInbetween={dateInBetween}
      $isInteractive={!!date}
    >
      {date?.day}
    </DateButtonStyled>
  );
};
