export const camera_off = () => (
  <>
    <g clipPath="url(#prefix__clip0_904_847)">
      <path
        d="M1 1l22 22M7 21H3a2 2 0 01-2-2V8a2 2 0 012-2h3m3-3h6l2 3h4a2 2 0 012 2v9.34M9.47 9.471A7.003 7.003 0 0012 23a7.003 7.003 0 006.529-4.47"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_904_847">
        <path
          fill="inherit"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </>
);
