import { ApiMethodsPropType, fetchWithHeaders, getErrorFromFetchWithHeaders } from '@sendible/common';
import { CampaignsUrls } from '../../constants/urls';
import { useUrlWithCredentials } from '../../data-layer/useUrlWithCredentials';

export type CampaignsDataForApi = {
  name?: string;
  startDate?: string;
  endDate?: string;
  status: string;
  paramId?: string;
};

export const useCampaignsModel = () => {
  const getUrlWithCredentials = useUrlWithCredentials();

  const fetchApiHandler = async (url: string, method: ApiMethodsPropType = 'GET') => {
    return fetchWithHeaders({
      method,
      url,
      headers: { 'Content-Type': 'application/json' },
    }).then(({ result }) => result);
  };

  const getCampaigns = async () => {
    try {
      const url = getUrlWithCredentials(CampaignsUrls.campaigns);

      return await fetchApiHandler(url);
    } catch (error: unknown) {
      throw Error(getErrorFromFetchWithHeaders(error));
    }
  };

  const saveCampaign = async (campaignObject: CampaignsDataForApi, method: ApiMethodsPropType = 'POST') => {
    const url = `${getUrlWithCredentials(CampaignsUrls.campaign)}&${new URLSearchParams(campaignObject).toString()}`;

    return fetchApiHandler(url, method);
  };

  const deleteCampaign = async (campaignId: number) => {
    const url = `${getUrlWithCredentials(CampaignsUrls.campaign)}&campaignId=${campaignId}`;

    return fetchApiHandler(url, 'DELETE');
  };

  const getCampaignOverview = async (campaignId: number) => {
    try {
      const url = `${getUrlWithCredentials(CampaignsUrls.overview)}&campaignId=${campaignId}`;

      return await fetchApiHandler(url);
    } catch (error: unknown) {
      throw Error(getErrorFromFetchWithHeaders(error));
    }
  };

  return {
    getCampaigns,
    saveCampaign,
    deleteCampaign,
    getCampaignOverview,
  };
};
