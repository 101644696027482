export const corner_right_down = () => (
  <>
    <path
      d="M10 15l5 5 5-5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 4h7a4 4 0 014 4v12"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
