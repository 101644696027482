import { Container } from './index.styles';
import { Button, ButtonProps } from '../Button';
import { Checkbox } from '../Checkbox';
import { Text } from '../../atoms/Text';
import { Tooltip } from '../../atoms/Tooltip';
import { generateId } from '../../utils/helpers';

type ActionButtonProps = Required<Pick<ButtonProps, 'icon' | 'label'>> &
  Pick<ButtonProps, 'disabled' | 'size' | 'title'> & { action: string; isDisabled?: boolean };

export interface BulkActionsBarProps extends Component {
  /**
   * Action click event emitter. Optional.
   */
  act?: (action: string) => void;
  /**
   * Array of action buttons. Optional.
   */
  actionButtons?: ActionButtonProps[];
  /**
   * Deselect items event emitter. Required.
   */
  deselectAll: () => void;
  /**
   * List total amount of items. Required.
   */
  maxItemsCount: number;
  /**
   * Select items event emitter. Required.
   */
  selectAll: () => void;
  /**
   * Number of selected items. Required.
   */
  selectedItemsCount: number;
  /**
   * Text for selected items. Required.
   */
  selectedItemsLabel: string;
}

/**
 * BulkActionsBar
 */
export const BulkActionsBar = (props: BulkActionsBarProps) => {
  const {
    act,
    actionButtons,
    className = '',
    customClass = '',
    deselectAll,
    maxItemsCount,
    selectAll,
    selectedItemsCount,
    selectedItemsLabel,
    testId = 'bulk-actions-bar',
  } = props;

  const fillState = selectedItemsCount === 0 ? 'unchecked' : selectedItemsCount < maxItemsCount ? 'indeterminate' : 'checked';

  return (
    <Container
      className={`bulk-actions-bar ${customClass} ${className}`}
      data-testid={testId}
    >
      <Checkbox
        check={() => (fillState !== 'checked' ? selectAll() : deselectAll())}
        initialState={fillState}
        testId={`${testId}-checkbox`}
      />
      <Text
        align="left"
        color="brandDark"
        variation="body_12"
        testId="checkbox-bulk-bar-label"
      >{`${selectedItemsCount} ${selectedItemsLabel}`}</Text>
      {actionButtons &&
        actionButtons.map(({ action, icon, isDisabled, label }) => (
          <Tooltip
            key={generateId()}
            content={<>{label}</>}
          >
            <Button
              onClick={() => act && act(action)}
              icon={icon}
              disabled={isDisabled}
              key={generateId()}
              size={14}
              data-testid={`${testId}-button-${icon}`}
            />
          </Tooltip>
        ))}
    </Container>
  );
};
