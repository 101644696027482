import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal, Text, useKeyDown } from '@sendible/design-system';
import { Actions } from './index.styles';
import { useDeleteMedia, useDeleteMultipleMedias } from '../../../../models/medias';
import { useMediaLibraryContext } from '../../context';

interface DeleteMultipleMediasModalProps {
  pendoEvent: string;
  closeCallback?: () => void;
}

export const DeleteMultipleMediasModal = ({
  pendoEvent,
  closeCallback = function () {
    return null;
  },
}: DeleteMultipleMediasModalProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { selectedMediasIds, setSelectedMediasIds } = useMediaLibraryContext();
  const deleteMediaMutation = useDeleteMedia(pendoEvent);
  const deleteMultipleMediaMutation = useDeleteMultipleMedias(pendoEvent);
  const { t } = useTranslation('media_library');
  const closeButtonLabel = t('deletion_modal_button_back');
  const deleteButtonLabel = t('dropdown_delete');

  useKeyDown(closeCallback, 'Escape');

  const handleMediaLibraryDeletion = async () => {
    setIsDeleting(true);

    if (selectedMediasIds.length === 1) {
      deleteMediaMutation.mutate(
        { mediaId: selectedMediasIds[0] },
        {
          onSuccess: () => {
            setSelectedMediasIds([]);
            setIsDeleting(false);
            closeCallback();
          },
        }
      );
    } else if (selectedMediasIds.length > 1) {
      deleteMultipleMediaMutation.mutate(
        { selectedMediasIds },
        {
          onSuccess: () => {
            setSelectedMediasIds([]);
            setIsDeleting(false);
            closeCallback();
          },
        }
      );
    }
  };

  return (
    <Modal
      variation="small"
      close={() => {
        closeCallback();
      }}
    >
      <Box
        orientation="column"
        flex={1}
      >
        <Text>{t('deletion_multiple_medias_modal_text')}</Text>
        <Actions>
          <Button
            onClick={() => closeCallback()}
            label={closeButtonLabel}
            size={16}
            data-testid="modal-button-back"
            variation="outline"
            autoFocus
          />
          <Button
            appearance="primary"
            onClick={() => handleMediaLibraryDeletion()}
            label={deleteButtonLabel}
            isLoading={isDeleting}
            size={16}
            data-testid="modal-button-delete"
            variation="fill"
          />
        </Actions>
      </Box>
    </Modal>
  );
};
