export const download_pdf = () => (
  <>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h2m6-20 6 6m-6-6v6h6m0 0v12a2 2 0 0 1-2 2h-2M10 20l2 2m0 0 2-2m-2 2v-4"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7 13.2h1.1a1.1 1.1 0 1 0 0-2.2H7v4.4m8.2-2.2h1.65m-1.65 0V11h2.2m-2.2 2.2v2.2M11.05 11v4.4h1.1a1.1 1.1 0 0 0 1.1-1.1v-2.2a1.1 1.1 0 0 0-1.1-1.1z"
    />
  </>
);
