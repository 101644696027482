import { localStorageConsts } from '@sendible/common';

function unixTimestampIsInPast(timestamp: string) {
  const now = new Date();

  if (typeof timestamp !== 'number') {
    return false;
  }

  if (!timestamp || now.getTime() > timestamp) {
    return true;
  }

  return false;
}

function getDaysAsMiliseconds(days: number) {
  const numberOfDays = Number.isNaN(days) ? localStorageConsts.defaultDaysToExpire : days;

  return numberOfDays * 1000 * 60 * 60 * 24;
}

function getItem(key: string) {
  let item;

  item = localStorage.getItem(key);

  if (item === null) {
    return item;
  }

  try {
    item = JSON.parse(item);
  } catch {
    return item;
  }

  const itemHasExpired = unixTimestampIsInPast(item.expires);

  if (!itemHasExpired) {
    return item.value;
  }
  localStorage.removeItem(key);

  return null;
}

function setItem(key: string, value: string | object, ttlValue: number = localStorageConsts.defaultDaysToExpire) {
  const now = new Date();
  const ttl = getDaysAsMiliseconds(ttlValue);
  const item = {
    value,
    expires: now.getTime() + ttl,
  };

  return localStorage.setItem(key, JSON.stringify(item));
}

function removeItem(key: string) {
  return localStorage.removeItem(key);
}

export default {
  getItem,
  setItem,
  removeItem,
};
