export const mediaDownloadedFromPreview = 'Asset downloaded from preview';
export const mediaDeletedFromGridView = 'Asset deleted from grid view';
export const mediaDeletedFromPreview = 'Asset deleted from preview';
export const mediaRenameFromPreview = 'Asset renamed from preview mode';
export const singleMediaDeletedBulkBar = 'Single asset deleted from bulk bar';
export const multipleMediasDeletedBulkBar = 'Multiple assets deleted from bulk bar';
export const allMediasDeletedBulkBar = 'All assets deleted from bulk bar';
export const singleFileUploadSuccess = 'Asset uploaded';
export const singleFileUploadFailure = 'Asset upload failed';
export const singleFileUploadCancelled = 'Asset upload cancelled';
export const mediaLibraryCreated = 'Media library created';
export const previewMediasOpened = 'Preview opened';
export const mediaLibraryRenamed = 'Media library renamed';
export const mediasLoadedInfiniteScroll = 'Assets loaded with infinite scroll';
