import styled, { css } from 'styled-components';
import { Text } from '../../atoms/Text';
import { InputProps } from '.';

type ContentProps = Pick<InputProps, 'feedback' | 'icon' | 'isDisabled' | 'isReadonly' | 'size' | 'darkmode' | 'maxWidth' | 'minWidth' | 'inline'>;

interface LabelProps extends Pick<InputProps, 'icon' | 'darkmode'> {
  float: boolean;
  className: string;
}

export const Container = styled.div<ContentProps & Pick<InputProps, 'isDisabled' | 'isLoading'>>`
  ${({ isDisabled, isLoading }) => css`
    ${isDisabled &&
    css`
      cursor: not-allowed;
    `}
    ${isLoading &&
    css`
      cursor: wait;
    `}
  `}
`;

export const WidthMaster = styled.div`
  position: absolute;
  top: 0;
  left: -9999px;
  overflow: hidden;
  visibility: hidden;
  white-space: nowrap;
  height: 0;
  margin: 0;
`;

export const Content = styled.label<ContentProps & Pick<LabelProps, 'float'>>`
  ${({
    theme: { colors, fontSizes, motions, motionSpeeds },
    darkmode,
    feedback,
    float,
    icon,
    isDisabled,
    isReadonly,
    maxWidth,
    minWidth,
    size = 'md',
    inline,
  }) => css`
    align-items: center;
    background: ${darkmode ? colors.brandLightAlpha10 : colors.brandDarkAlpha5};
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-bottom: unset;
    max-width: ${maxWidth};
    min-width: ${minWidth};
    outline: 1px solid transparent;
    overflow: hidden;
    position: relative;
    transition: all ${motionSpeeds.fast} ${motions.default};

    span {
      box-sizing: unset;
    }

    .left-icon,
    .right-icon {
      height: 1.5em;
      min-width: 1.5em;
      pointer-events: none;
      transition: all ${motionSpeeds.fast} ${motions.default};
      width: 1.5em;

      path {
        stroke: ${darkmode ? colors.brandLight : colors.brandDark};
      }
    }

    .left-icon {
      padding-left: 0.75em;
    }

    .right-icon {
      padding: 0 0.75em;
    }

    .button {
      border-radius: 0 4px 4px 0;
      box-shadow: none;
      outline: none;
      padding: 0 1em;

      &:active {
        background: ${colors.brandDarkAlpha10};
        transform: none;
      }
    }

    input,
    div {
      text-overflow: ellipsis;
      background: transparent;
      border: 0;
      border-radius: 4px;
      color: ${darkmode ? colors.brandLight : colors.brandDark};
      flex: 1;
      font-size: inherit;
      line-height: 1em;
      min-width: 0;
      outline: none;
      padding: 0.5em 0 0.5em 1em;

      &::placeholder {
        color: transparent;

        ${size === 'sm' &&
        css`
          color: ${darkmode ? colors.brandLightAlpha50 : colors.brandDarkAlpha50};
        `}

        ${size === 'md' &&
        css`
          color: ${darkmode ? colors.brandLightAlpha50 : colors.brandDarkAlpha50};
        `}
      }

      ${float &&
      size !== 'sm' &&
      size !== 'md' &&
      css`
        padding: 0.8em 0 0.2em 1em;
      `}

      &:not(:placeholder-shown),
      &:-webkit-autofill {
        ${float &&
        size !== 'sm' &&
        size !== 'md' &&
        css`
          padding: 0.8em 0 0.2em 1em;
        `}

        & ~ span {
          color: ${darkmode ? colors.brandLightAlpha70 : colors.brandDark};
          font-size: 0.6em;
          padding: 0.33em 0 0.33em 1.66em;

          ${icon &&
          css`
            padding-left: 4.66em;
          `}
        }
      }
    }

    &:focus-within {
      outline-color: ${colors.brandPrimary};

      svg {
        path {
          stroke: ${colors.brandPrimary};
        }
      }

      .input-label {
        color: ${colors.brandPrimary};
      }

      span {
        color: ${colors.brandPrimary};
      }

      ${darkmode &&
      css`
        background: ${colors.brandLight};

        input {
          color: ${colors.brandDark};

          &::placeholder {
            ${size === 'sm' &&
            css`
              color: ${colors.brandDarkAlpha50};
            `}

            ${size === 'md' &&
            css`
              color: ${colors.brandDarkAlpha50};
            `}
          }
        }
      `}
    }

    ${size === 'sm' &&
    css`
      font-size: ${fontSizes.s14};
      height: 2rem;

      .left-icon,
      .right-icon {
        font-size: ${fontSizes.s12};
      }
    `}

    ${size === 'md' &&
    css`
      font-size: ${fontSizes.s16};
      height: 2.5rem;

      .left-icon,
      .right-icon {
        font-size: ${fontSizes.s14};
      }
    `}

    ${size === 'lg' &&
    css`
      font-size: ${fontSizes.s20};
      height: 3rem;

      .left-icon,
      .right-icon {
        font-size: ${fontSizes.s16};
      }
    `}

    ${size === 'xl' &&
    css`
      font-size: ${fontSizes.s24};
      height: 3.5rem;

      .left-icon,
      .right-icon {
        font-size: ${fontSizes.s18};
      }
    `}

    ${feedback &&
    darkmode &&
    css`
      background: ${colors.brandLight};

      input {
        color: ${colors.brandDark};
      }
    `}

    ${feedback?.type === 'danger' &&
    css`
      outline-color: ${colors.semanticDanger};

      .left-icon,
      .right-icon {
        path {
          stroke: ${colors.semanticDanger};
        }
      }

      .input-label {
        color: ${colors.semanticDanger} !important;
      }

      &:focus-within {
        .right-icon {
          path {
            stroke: ${colors.semanticDanger};
          }
        }
      }
    `}

    ${feedback?.type === 'success' &&
    css`
      outline-color: ${colors.semanticSuccess};

      .left-icon,
      .right-icon {
        path {
          stroke: ${colors.semanticSuccessShade50};
        }
      }

      .input-label {
        color: ${colors.semanticSuccessShade50} !important;
      }

      &:focus-within {
        .right-icon {
          path {
            stroke: ${colors.semanticSuccessShade50};
          }
        }
      }
    `}

    ${feedback?.type === 'warning' &&
    css`
      outline-color: ${colors.semanticWarningShade10};

      .left-icon,
      .right-icon {
        path {
          stroke: ${colors.semanticWarningShade50};
        }
      }

      .input-label {
        color: ${colors.semanticWarningShade50} !important;
      }

      &:focus-within {
        .right-icon {
          path {
            stroke: ${colors.semanticWarningShade50};
          }
        }
      }
    `}

    ${feedback?.type === 'info' &&
    css`
      outline-color: ${colors.semanticInfo};

      .left-icon,
      .right-icon {
        path {
          stroke: ${colors.semanticInfoShade10};
        }
      }

      .input-label {
        color: ${colors.semanticInfoShade10} !important;
      }

      &:focus-within {
        .right-icon {
          path {
            stroke: ${colors.semanticInfoShade10};
          }
        }
      }
    `}

    ${isDisabled &&
    css`
      background: ${colors.brandDarkAlpha5};
      color: ${colors.brandDarkAlpha60};
      outline-color: transparent;
      pointer-events: none;

      .left-icon,
      .right-icon {
        path {
          stroke: currentColor;
        }
      }

      .button {
        color: currentColor;

        svg {
          path {
            stroke: currentColor;
          }
        }
      }
    `}

    ${isReadonly &&
    css`
      input {
        &::selection {
          background: transparent;
        }
      }
    `}
    ${inline &&
    css`
      background: transparent;
      height: unset;
      &:hover {
        outline-color: ${colors.brandPrimary};
      }
      input {
        padding: 0;
      }
    `}
  `}
`;

export const Label = styled.span<LabelProps>`
  ${({ theme: { colors, motions, motionSpeeds }, darkmode, float, icon }) => css`
    color: ${darkmode ? colors.brandLightAlpha50 : colors.brandDarkAlpha50};
    display: -webkit-box;
    font-size: inherit;
    height: 1em;
    left: 0;
    overflow: hidden;
    padding: 0.5em 1em;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: 0;
    transition: all ${motionSpeeds.fast} ${motions.default};
    user-select: none;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    ${icon &&
    css`
      padding-left: 2.8em;
    `}

    ${float &&
    css`
      color: ${colors.brandDark};
      font-size: 0.6em;
      padding: 0.33em 0 0.33em 1.66em;

      ${icon &&
      css`
        padding-left: 4.66em;
      `}
    `}
  `}
`;

export const PopupBox = styled.div`
  ${({ theme: { borderRadius, colors, fontSizes } }) => css`
    align-items: center;
    background: ${colors.brandLight};
    border-radius: ${borderRadius.sm};
    cursor: default;
    color: ${colors.brandDark};
    display: block;
    font-size: ${fontSizes.s14};
    letter-spacing: 0.005em;
    line-height: 1.285em;
    padding: 0.5rem 1rem 0rem 1rem;
    position: absolute;
    text-decoration: none !important;
    user-select: none;
    width: max-content;
    max-width: 19rem;
    z-index: 1;
    box-shadow: 0px 0.25rem 0.25rem 0px ${colors.brandDarkAlpha30}, 0px 0px 0px 1px ${colors.brandDarkAlpha10},
      0px 0.75rem 0.5rem -0.25rem ${colors.brandDarkAlpha15}, 0px 1px 1px 0px ${colors.brandDarkAlpha20};

    * {
      display: inline-flex;
    }
  `}
`;

export const Feedback = styled(Text)<Pick<LabelProps, 'className'> & Pick<InputProps, 'feedback' | 'darkmode'>>`
  ${({ theme: { colors }, darkmode, feedback }) => css`
    font-size: 0.75rem;
    line-height: 1em;
    margin-top: 0.5em;

    ${feedback?.type === 'danger' &&
    css`
      color: ${darkmode ? colors.semanticDangerDarkMode : colors.semanticDanger};
    `}

    ${feedback?.type === 'success' &&
    css`
      color: ${colors.semanticSuccessShade50};
    `}

    ${feedback?.type === 'warning' &&
    css`
      color: ${darkmode ? colors.semanticWarningShade10 : colors.semanticWarningShade50};
    `}

    ${feedback?.type === 'info' &&
    css`
      color: ${colors.semanticInfoShade10};
    `}
  `}
`;
