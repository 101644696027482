export const google_my_business_color = () => (
  <>
    <path
      d="M3.51879 6.80518H20.4361C21.5074 6.80518 22.3759 7.67368 22.3759 8.74503V20.5677C22.3759 21.6391 21.5074 22.5076 20.4361 22.5076H3.51879C2.44744 22.5076 1.57894 21.6391 1.57894 20.5677V8.74503C1.57894 7.67368 2.44744 6.80518 3.51879 6.80518Z"
      fill="#4788F8"
    />
    <mask
      id="mask0_320_1433"
      maskUnits="userSpaceOnUse"
      x="1"
      y="6"
      width="22"
      height="17"
    >
      <path
        d="M3.51879 6.80518H20.4361C21.5074 6.80518 22.3759 7.67368 22.3759 8.74503V20.5677C22.3759 21.6391 21.5074 22.5076 20.4361 22.5076H3.51879C2.44744 22.5076 1.57894 21.6391 1.57894 20.5677V8.74503C1.57894 7.67368 2.44744 6.80518 3.51879 6.80518Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_320_1433)">
      <path
        d="M21.3122 6.69238H2.43812C1.85345 6.69238 1.39849 11.2172 1.39849 11.8019L11.7345 22.5128H21.3122C21.8986 22.5112 22.3737 22.0361 22.3753 21.4497V7.75524C22.3735 7.16887 21.8986 6.69399 21.3122 6.69238Z"
        fill="url(#paint0_linear_320_1433)"
      />
      <rect
        x="1.15939"
        y="5.97058"
        width="21.6812"
        height="8.43158"
        fill="url(#paint1_linear_320_1433)"
      />
    </g>
    <path
      d="M6.00067 9.43434H12.0007V1.55945H6.89909L6.00067 9.43434V9.43434Z"
      fill="#4A58BD"
    />
    <path
      d="M18.0007 9.43434H12.0007V1.55945H17.1023L18.0007 9.43434V9.43434Z"
      fill="#7DABFB"
    />
    <path
      d="M22.2056 3.05225L22.4044 3.6545C22.3562 3.44194 22.2598 3.24456 22.2056 3.05225Z"
      fill="#7BABF7"
    />
    <path
      d="M22.2131 3.07883L22.2057 3.05199C22.0154 2.18093 21.2445 1.55976 20.3529 1.5592H17.1023L18 9.43432H24L22.2131 3.07883Z"
      fill="#4A58BD"
    />
    <path
      d="M1.98701 3.05225L1.78826 3.6545C1.83644 3.44194 1.9328 3.24456 1.98701 3.05225Z"
      fill="#7BABF7"
    />
    <path
      d="M1.78805 3.07883L1.79549 3.05199C1.98572 2.18093 2.75668 1.55976 3.64827 1.5592H6.8991L6 9.43432H0L1.78805 3.07883Z"
      fill="#7DABFB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.01083 9.43237C6.01083 11.0883 4.66844 12.4307 3.01252 12.4307C1.3566 12.4307 0.014206 11.0883 0.0142059 9.43237H6.01083Z"
      fill="#7DA4E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0074 9.43237C12.0074 11.0883 10.665 12.4307 9.00912 12.4307C7.3532 12.4307 6.01081 11.0883 6.01081 9.43237H12.0074Z"
      fill="#4855AF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0041 9.43237C18.0041 11.0883 16.6617 12.4307 15.0058 12.4307C13.3498 12.4307 12.0074 11.0883 12.0074 9.43237H18.0041Z"
      fill="#7DA4E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 9.43237C24 11.0883 22.6576 12.4307 21.0017 12.4307C19.3458 12.4307 18.0034 11.0883 18.0034 9.43237H24Z"
      fill="#4855AF"
    />
    <path
      d="M20.6185 17.557C20.6092 17.4314 20.5937 17.3168 20.5717 17.1705H17.6966C17.6966 17.5726 17.6966 18.0114 17.695 18.4138H19.3606C19.2891 18.7925 19.0697 19.1271 18.7509 19.3435C18.7509 19.3435 18.7509 19.3366 18.7497 19.3364C18.5458 19.4702 18.3161 19.5599 18.0755 19.5995C17.8342 19.6436 17.5869 19.6426 17.346 19.5963C17.1014 19.5457 16.8699 19.4454 16.6657 19.3015C16.3643 19.0878 16.1319 18.7908 15.9969 18.4469C15.9866 18.4201 15.977 18.3931 15.9673 18.3658V18.3628L15.9699 18.3608C15.8389 17.9762 15.8385 17.5591 15.9689 17.1743C16.0608 16.9043 16.2119 16.6584 16.4113 16.4545C16.879 15.9707 17.5778 15.7887 18.222 15.983C18.4691 16.0587 18.6948 16.1916 18.8809 16.3708L19.4429 15.8089C19.542 15.7087 19.6449 15.6118 19.7403 15.5083V15.5083V15.5083C19.4554 15.2425 19.1209 15.0354 18.756 14.8987C18.0919 14.6579 17.3654 14.6516 16.6972 14.8807C16.674 14.8886 16.6508 14.8969 16.6278 14.9055C15.9069 15.1765 15.3139 15.708 14.9658 16.395C14.8429 16.6382 14.7534 16.8968 14.6997 17.164C14.3814 18.7457 15.3466 20.3027 16.9048 20.7212C17.4142 20.8576 17.9557 20.8543 18.4691 20.7377C18.9355 20.6324 19.3668 20.4082 19.7211 20.0871C20.0907 19.747 20.3556 19.2939 20.4944 18.8127C20.6104 18.4049 20.6525 17.9796 20.6185 17.557Z"
      fill="#EEEEEF"
    />
    <defs>
      <linearGradient
        id="paint0_linear_320_1433"
        x1="2.02779"
        y1="22.2753"
        x2="22.375"
        y2="22.2753"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#477CE8" />
        <stop
          offset="1"
          stopColor="#3F7EF1"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_320_1433"
        x1="22.8406"
        y1="14.4022"
        x2="22.8406"
        y2="5.97058"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop
          offset="1"
          stopOpacity="0.3"
        />
      </linearGradient>
    </defs>
  </>
);
