export const youtube_color = () => (
  <>
    <path
      fill="red"
      fillRule="evenodd"
      d="M20.784 7.35a2.3 2.3 0 0 0-1.621-1.631C17.733 5.333 12 5.333 12 5.333s-5.733 0-7.163.386a2.3 2.3 0 0 0-1.62 1.631c-.384 1.44-.384 4.442-.384 4.442s0 3.002.383 4.441a2.27 2.27 0 0 0 1.621 1.605c1.43.386 7.163.386 7.163.386s5.733 0 7.163-.386a2.27 2.27 0 0 0 1.62-1.605c.384-1.439.384-4.441.384-4.441s0-3.003-.383-4.442"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10.125 14.518V9.066l4.792 2.726z"
      clipRule="evenodd"
    />
  </>
);
