import { FC, ReactNode, useEffect } from 'react';
import { changeLanguage } from 'i18next';
import { useBridgeContext } from '@sendible/shared-state-bridge';

interface LocalizationProps {
  children: ReactNode;
}

export const Localization: FC<LocalizationProps> = (props) => {
  const { children } = props;

  const [
    {
      user: { language },
    },
  ] = useBridgeContext();

  useEffect(() => {
    changeLanguage(language);
  }, [language]);

  return <div>{children}</div>;
};
