export const eye_off = () => (
  <>
    <g clipPath="url(#prefix__clip0_904_887)">
      <path
        d="M17.94 17.94A10.07 10.07 0 0112 20c-7 0-11-8-11-8a18.45 18.45 0 015.06-5.94M9.9 4.24A9.12 9.12 0 0112 4m0 0c7 0 11 8 11 8a18.498 18.498 0 01-2.16 3.19M12 4a6 6 0 015.5 8.402M2.697 9.389L1 8m20.302 1.389L23 8m-4.518-1.533L20 4m-10.451.337L9 1.5m5.451 2.836L15 1.5M1 1l22 22M6.878 6.874a6 6 0 008.245 8.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_904_887">
        <path
          fill="inherit"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </>
);
