export const whitelabelColors: ThemeNames = {
  aeroBlue: {
    colorPrimary: '#6cc1e0',
    colorSecondary: '#404040',
    colorAccent: '#000000',
  },
  appleGreen: {
    colorPrimary: '#87a462',
    colorSecondary: '#3f3f3f',
    colorAccent: '#688443',
  },
  astronautBlue: {
    colorPrimary: '#004263',
    colorSecondary: '#515151',
    colorAccent: '#404041',
  },
  blackCoffee: {
    colorPrimary: '#1f1f1f',
    colorSecondary: '#737373',
    colorAccent: '#333333',
  },
  blackOrange: {
    colorPrimary: '#e30e13',
    colorSecondary: '#9a9cbe',
    colorAccent: '#1f2827',
  },
  blackWhite: {
    colorPrimary: '#222222',
    colorSecondary: '#31353a',
    colorAccent: '#00457f',
  },
  blackBlue: {
    colorPrimary: '#6699cc',
    colorSecondary: '#737373',
    colorAccent: '#1f1f1f',
  },
  blueAndOrange: {
    colorPrimary: '#f9ae3f',
    colorSecondary: '#47b6ee',
    colorAccent: '#036aa0',
  },
  blueDenim: {
    colorPrimary: '#2a3c90',
    colorSecondary: '#1d75bc',
    colorAccent: '#1d75bc',
  },
  blueGreyBlack: {
    colorPrimary: '#00a9e5',
    colorSecondary: '#333333',
    colorAccent: '#000000',
  },
  blueMoon: {
    colorPrimary: '#002446',
    colorSecondary: '#004180',
    colorAccent: '#004180',
  },
  blueProfessional: {
    colorPrimary: '#0e2382',
    colorSecondary: '#cff8fc',
    colorAccent: '#0082d8',
  },
  blueProfessionalBlack: {
    colorPrimary: '#001aa1',
    colorSecondary: '#000000',
    colorAccent: '#0082d8',
  },
  blueWatermelon: {
    colorPrimary: '#d20f67',
    colorSecondary: '#4f5f6f',
    colorAccent: '#003366',
  },
  beEverywhere: {
    colorPrimary: '#068dc0',
    colorSecondary: '#48b8e1',
    colorAccent: '#636697',
  },
  carmineRed: {
    colorPrimary: '#2e2a2b',
    colorSecondary: '#b3b3b3',
    colorAccent: '#ab001f',
  },
  casablancaToryblue: {
    colorPrimary: '#c13440',
    colorSecondary: '#1a3665',
    colorAccent: '#f7b356',
  },
  cinnabarOrange: {
    colorPrimary: '#e65525',
    colorSecondary: '#003976',
    colorAccent: '#111820',
  },
  citronBlue: {
    colorPrimary: '#ee730f',
    colorSecondary: '#96c11f',
    colorAccent: '#449bd5',
  },
  crayolaGreen: {
    colorPrimary: '#373737',
    colorSecondary: '#373737',
    colorAccent: '#20bb79',
  },
  danubeGrey: {
    colorPrimary: '#6590cd',
    colorSecondary: '#404041',
    colorAccent: '#404041',
  },
  darkblueOrange: {
    colorPrimary: '#e39a62',
    colorSecondary: '#585f62',
    colorAccent: '#494949',
  },
  darkGoldenrod: {
    colorPrimary: '#b58106',
    colorSecondary: '#00aeef',
    colorAccent: '#2a2b2f',
  },
  default: {
    colorPrimary: '#4d36ff',
    colorSecondary: '#1f1666',
    colorAccent: '#fe604d',
  },
  fjordBlue: {
    colorPrimary: '#157ffb',
    colorSecondary: '#af2438',
    colorAccent: '#3f4168',
  },
  fluorescentYellow: {
    colorPrimary: '#1e191a',
    colorSecondary: '#424242',
    colorAccent: '#ccff00',
  },
  flushedOrange: {
    colorPrimary: '#ff7a00',
    colorSecondary: '#f69221',
    colorAccent: '#231f20',
  },
  greenBlack: {
    colorPrimary: '#4baf0a',
    colorSecondary: '#9a9cbe',
    colorAccent: '#000000',
  },
  greenGrey: {
    colorPrimary: '#50b948',
    colorSecondary: '#9a9cbe',
    colorAccent: '#333333',
  },
  greyBlueGold: {
    colorPrimary: '#bc9c1a',
    colorSecondary: '#50505e',
    colorAccent: '#0085b2',
  },
  havelockBlue: {
    colorPrimary: '#4d95d1',
    colorSecondary: '#b2cde4',
    colorAccent: '#2e2e2e',
  },
  heliotropePurple: {
    colorPrimary: '#e849e3',
    colorSecondary: '#7b7b7b',
    colorAccent: '#ff6789',
  },
  hippieDove: {
    colorPrimary: '#558fb2',
    colorSecondary: '#558fb2',
    colorAccent: '#676767',
  },
  lavaRed: {
    colorPrimary: '#ca141f',
    colorSecondary: '#f3ccbc',
    colorAccent: '#57585a',
  },
  lightBlue: {
    colorPrimary: '#36bdee',
    colorSecondary: '#333333',
    colorAccent: '#333333',
  },
  lightGreen: {
    colorPrimary: '#8dc63f',
    colorSecondary: '#333333',
    colorAccent: '#333333',
  },
  newsocialcloudLightBlue: {
    colorPrimary: '#46a7d1',
    colorSecondary: '#2b709c',
    colorAccent: '#5385ac',
  },
  niagraBlue: {
    colorPrimary: '#022541',
    colorSecondary: '#0cb694',
    colorAccent: '#0cb694',
  },
  venture: {
    colorPrimary: '#f7921e',
    colorSecondary: '#e6e6e6',
    colorAccent: '#555555',
  },
  parsleySupernova: {
    colorPrimary: '#105620',
    colorSecondary: '#105620',
    colorAccent: '#f2c700',
  },
  raisinBlack: {
    colorPrimary: '#262626',
    colorSecondary: '#c5c8cb',
    colorAccent: '#3fc34c',
  },
  allMedia: {
    colorPrimary: '#2e2a2b',
    colorSecondary: '#e5ecf2',
    colorAccent: '#a51c2b',
  },
  sixdegreesRedBlack: {
    colorPrimary: '#333333',
    colorSecondary: '#e5ecf2',
    colorAccent: '#b20406',
  },
  royalConcrete: {
    colorPrimary: '#1c2896',
    colorSecondary: '#cfcece',
    colorAccent: '#8f8e8e',
  },
  satinLinenSunglowButtons: {
    colorPrimary: '#f1a40e',
    colorSecondary: '#c1bcaa',
    colorAccent: '#4a4a4a',
  },
  seaBlues: {
    colorPrimary: '#13486d',
    colorSecondary: '#cececf',
    colorAccent: '#61a7c3',
  },
  shadyCerulean: {
    colorPrimary: '#00abdb',
    colorSecondary: '#a5a3a4',
    colorAccent: '#be1e2e',
  },
  friendly: {
    colorPrimary: '#81bdc7',
    colorSecondary: '#b3dce4',
    colorAccent: '#373737',
  },
  smokyPink: {
    colorPrimary: '#8b4e8b',
    colorSecondary: '#9967a1',
    colorAccent: '#7b7b7b',
  },
  smoothBlack: {
    colorPrimary: '#2e3949',
    colorSecondary: '#d8d8d8',
    colorAccent: '#575757',
  },
  smoothBlue: {
    colorPrimary: '#012c72',
    colorSecondary: '#3a5c92',
    colorAccent: '#3a5c92',
  },
  smoothSeablue: {
    colorPrimary: '#309ad5',
    colorSecondary: '#4e4b4d',
    colorAccent: '#4e4b4d',
  },
  smoothness: {
    colorPrimary: '#232436',
    colorSecondary: '#9a9cbe',
    colorAccent: '#9a9cbe',
  },
  smoothnessLargeTop: {
    colorPrimary: '#232436',
    colorSecondary: '#9a9cbe',
    colorAccent: '#9a9cbe',
  },
  spanishSkyBlue: {
    colorPrimary: '#2bc6ce',
    colorSecondary: '#001833',
    colorAccent: '#001833',
  },
  steelblueGrey: {
    colorPrimary: '#13558c',
    colorSecondary: '#4885b6',
    colorAccent: '#4885b6',
  },
  sunshine: {
    colorPrimary: '#ff8000',
    colorSecondary: '#505050',
    colorAccent: '#ffb400',
  },
  troutPorcelain: {
    colorPrimary: '#393e46',
    colorSecondary: '#393e46',
    colorAccent: '#545962',
  },
  turquoiseBlue: {
    colorPrimary: '#2a3378',
    colorSecondary: '#666ea9',
    colorAccent: '#61c6c3',
  },
  vikingZest: {
    colorPrimary: '#0a4766',
    colorSecondary: '#77cddc',
    colorAccent: '#e78526',
  },
  violetWine: {
    colorPrimary: '#873694',
    colorSecondary: '#7b7b7b',
    colorAccent: '#544d79',
  },
  whiteBlueOrange: {
    colorPrimary: '#17365c',
    colorSecondary: '#17365c',
    colorAccent: '#e86d09',
  },
  whiteDenim: {
    colorPrimary: '#373737',
    colorSecondary: '#1b75ba',
    colorAccent: '#1b75ba',
  },
  whiteGrey: {
    colorPrimary: '#808080',
    colorSecondary: '#808080',
    colorAccent: '#808080',
  },
  whitePurered: {
    colorPrimary: '#1f2827',
    colorSecondary: '#1f2827',
    colorAccent: '#e20031',
  },
  yellowBlack: {
    colorPrimary: '#222222',
    colorSecondary: '#ff5714',
    colorAccent: '#dbe057',
  },
  yellowishGrey: {
    colorPrimary: '#455560',
    colorSecondary: '#455560',
    colorAccent: '#f7941e',
  },
};
