import { Box, Text } from '@sendible/design-system/src';
import styled from 'styled-components';

export const FullWidthBox = styled(Box)`
  width: 100%;
`;

export const AudienceContainer = styled(Box)`
  background: white;
  border-radius: 0.375rem;
`;

export const AudienceByDaySection = styled(Box)`
  background: white;
  width: 100%;
`;

export const SectionHeader = styled(Text)`
  text-transform: uppercase;
`;

export const SplitAndGrowthContainer = styled(Box)`
  width: 100%;
  gap: 1rem;

  > div {
    width: 49%;
  }

  @media (max-width: 1000px) {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
`;
