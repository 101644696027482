import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { helpers } from '@sendible/design-system';
import { useTranslation } from 'react-i18next';
import { Errors, QuickActions } from './types';
import { inputMaxLength } from './components/TextArea';
import { useAiAssistModel } from '../../models/aiAssist';

type ErrorType = {
  type: Errors | null;
  message: string | null;
};

export type AiAssistContextType = {
  quickAction: QuickActions | null;
  promptValue: string;
  setPromptValue: Dispatch<SetStateAction<string>>;
  toneOfVoice: string;
  setToneOfVoice: Dispatch<SetStateAction<string>>;
  results: AiAssistResults[];
  setResults: Dispatch<SetStateAction<AiAssistResults[]>>;
  isGenerating: boolean;
  setIsGenerating: Dispatch<SetStateAction<boolean>>;
  targetAudience: string;
  setTargetAudience: Dispatch<SetStateAction<string>>;
  activeResultIndex: number;
  generateContentHandler: () => void;
  regenerateContentHandler: (regenerateAction: QuickActions, regenerateContent: string) => void;
  importPreviousContent: (index: number) => void;
  composeText: string;
  sessionId: string;
  setSessionId: Dispatch<SetStateAction<string>>;
  error: ErrorType;
  isGenerationEnabled: boolean;
};

export interface PropsType {
  quickAction: QuickActions | null;
  children: ReactNode;
  composeText: string;
}

export const AiAssistContext = createContext({} as AiAssistContextType);

export const AiAssistProvider: FC<PropsType> = ({ quickAction, children, composeText }) => {
  const defaultError = {
    type: null,
    message: null,
  };
  const { t } = useTranslation('ai_assist');
  const defaultErrorMessage = t('default_error_message');
  const { generateContent, regenerateContent } = useAiAssistModel();

  const [promptValue, setPromptValue] = useState('');
  const [toneOfVoice, setToneOfVoice] = useState('');
  const [results, setResults] = useState([] as AiAssistResults[]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [targetAudience, setTargetAudience] = useState('');
  const [sessionId, setSessionId] = useState(helpers.generateId());
  const [activeResultIndex, setactiveResultIndex] = useState(0);
  const [error, setError] = useState<ErrorType>(defaultError);

  const generateContentHandler = async () => {
    if (isGenerating) return;

    setIsGenerating(true);
    setError(defaultError);

    try {
      const newResults = await generateContent({
        composeText,
        sessionId,
        previousResults: results,
        promptValue,
        targetAudience,
        toneOfVoice,
      });

      if (newResults) {
        setResults(newResults);
        setactiveResultIndex(newResults.length - 1);
        setPromptValue('');
        setIsGenerating(false);
      } else {
        throw Error(defaultErrorMessage);
      }
    } catch (errorObject) {
      if (errorObject instanceof Error) {
        setError({
          type: Errors.GenericError,
          message: errorObject.message,
        });
      }
    }

    setIsGenerating(false);
  };

  const regenerateContentHandler = async (quickActionParameter: QuickActions, regenerateContentString: string) => {
    setIsGenerating(true);
    setError(defaultError);

    try {
      const newResults = await regenerateContent({
        quickAction: quickActionParameter,
        sessionId,
        content: regenerateContentString,
        previousResults: results,
        promptValue,
        targetAudience,
        toneOfVoice,
      });

      if (newResults) {
        setResults(newResults);
        setactiveResultIndex(newResults.length - 1);
        setPromptValue('');
        setIsGenerating(false);
      } else {
        throw Error(defaultErrorMessage);
      }
    } catch (errorObject) {
      if (errorObject instanceof Error) {
        if (quickAction && !results.length) {
          setError({
            type: Errors.QuickActionError,
            message: errorObject.message,
          });
        }
      }
    }

    setIsGenerating(false);
  };

  useEffect(() => {
    if (quickAction && composeText) {
      regenerateContentHandler(quickAction, composeText);
    }
  }, []);

  const importPreviousContent = (index: number) => {
    setactiveResultIndex(index);
  };

  const isGenerationEnabled = promptValue.length > 0 && promptValue.length <= inputMaxLength && !isGenerating;

  return (
    <AiAssistContext.Provider
      value={{
        quickAction,
        promptValue,
        setPromptValue,
        toneOfVoice,
        setToneOfVoice,
        results,
        setResults,
        isGenerating,
        setIsGenerating,
        targetAudience,
        setTargetAudience,
        activeResultIndex,
        generateContentHandler,
        regenerateContentHandler,
        importPreviousContent,
        composeText,
        sessionId,
        setSessionId,
        error,
        isGenerationEnabled,
      }}
    >
      {children}
    </AiAssistContext.Provider>
  );
};

export const useAiAssistContext = (): AiAssistContextType => useContext(AiAssistContext);
