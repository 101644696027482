export const video_off = () => (
  <>
    <g clipPath="url(#prefix__clip0_904_841)">
      <path
        d="M10.66 5H14a2 2 0 012 2v3.34l1 1L23 7v10M1 1l22 22m-7-7v1a2 2 0 01-2 2H3a2 2 0 01-2-2V7a2 2 0 012-2h2l11 11z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_904_841">
        <path
          fill="inherit"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </>
);
