import { Button, Text, Spinner, Heading } from '@sendible/design-system';
import { EVENTS, publish } from '@sendible/event-manager';
import { useTranslation } from 'react-i18next';
import { ActionsSection, Container, FullWidthBox } from './index.styles';
import { Actions } from './components/Actions';
import { Error } from './components/Error';
import { useAiAssistContext } from '../../AiAssistContext';
import { Pagination } from '../Pagination';
import { aIAssistantAddMessageToCompose, aIAssistantReplaceMessageToCompose } from '../../pendoEvents';
import { Errors } from '../../types';

export const Preview = () => {
  const { quickAction, activeResultIndex, composeText, error, results, isGenerating, importPreviousContent } = useAiAssistContext();
  const { t } = useTranslation('ai_assist');
  const addToComposeText = t('add_to_compose');
  const replaceComposeText = t('replace_compose');
  const resultsObject = results[activeResultIndex];
  const showSpinner = !!(quickAction && !resultsObject);
  const disableButtons = isGenerating || showSpinner;

  let content = '';

  if (!quickAction && !resultsObject) {
    return null;
  }

  if (error && error.type === Errors.QuickActionError && error.message) {
    return <Error message={error.message} />;
  }

  if (resultsObject) {
    content = results[activeResultIndex].content;
  }

  return (
    <Container
      data-testid="ai-assist-preview-modal"
      $disabled={isGenerating}
    >
      <FullWidthBox
        contentJustify="space-between"
        contentAlignment="center"
      >
        {quickAction ? (
          <>
            <Heading
              semantic="h4"
              variation="component-title_20"
            >
              {t(quickAction.toLowerCase().replace(/\s/g, '-'))}
            </Heading>
            <Button
              data-testid="ai-assist-close-btn"
              onClick={() => {
                publish(EVENTS.CLOSE_AI_ASSIST, {});
              }}
              icon="close_lg"
              size={14}
            />
          </>
        ) : (
          <Pagination
            currentPage={activeResultIndex + 1}
            disabled={isGenerating}
            pages={results.length}
            onBack={() => {
              const prevPageNumber = activeResultIndex - 1;

              if (prevPageNumber > -1) {
                importPreviousContent(prevPageNumber);
              }
            }}
            onNext={() => {
              const nextPageNumber = activeResultIndex + 1;

              if (nextPageNumber <= results.length) {
                importPreviousContent(nextPageNumber);
              }
            }}
          />
        )}
      </FullWidthBox>
      {showSpinner ? (
        <FullWidthBox contentJustify="center">
          <Spinner size="md" />
        </FullWidthBox>
      ) : (
        <Text>{content}</Text>
      )}
      <ActionsSection>
        {quickAction ? (
          <Button
            data-testid="ai-assist-secondary-btn"
            appearance="primary"
            disabled={disableButtons}
            onClick={() => {
              if (content) {
                window.pendo.track(aIAssistantAddMessageToCompose);
                publish(EVENTS.AI_ASSIST_GENERATED_CONTENT, `${composeText} ${content}`);
                publish(EVENTS.CLOSE_AI_ASSIST, {});
              }
            }}
            label={addToComposeText}
            variation="outline"
            size={14}
          />
        ) : (
          <Actions
            content={content}
            disableButtons={disableButtons}
          />
        )}
        <Button
          data-testid="ai-assist-primary-btn"
          appearance="primary"
          disabled={disableButtons}
          onClick={() => {
            if (content) {
              // eslint-disable-next-line no-unused-expressions
              quickAction ? window.pendo.track(aIAssistantReplaceMessageToCompose) : window.pendo.track(aIAssistantAddMessageToCompose);
              publish(EVENTS.AI_ASSIST_GENERATED_CONTENT, content);
              publish(EVENTS.CLOSE_AI_ASSIST, {});
            }
          }}
          label={quickAction ? replaceComposeText : addToComposeText}
          variation={quickAction ? 'outline' : 'fill'}
          size={14}
        />
      </ActionsSection>
    </Container>
  );
};
