import { Box } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';

export const ReportsContainer = styled(Box)<{ $isPrint?: boolean }>`
  ${({ $isPrint = false }) => css`
    width: 100%;
    overflow-y: auto;
    position: relative;

    ${$isPrint &&
    css`
      overflow: clip;
    `}
  `}
`;
