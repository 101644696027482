import { useEffect, useRef, useState, ChangeEvent } from 'react';
import { Check, Container, Text } from './index.styles';
import { Icon } from '../../atoms/Icon';

export const initialStates = ['unchecked', 'checked', 'indeterminate'] as const;
export const sizes = ['sm', 'md', 'lg', 'xl'] as const;

export interface CheckboxProps extends Component {
  /**
   * Check event emitter. Optional.
   */
  check?: (e: boolean) => void;
  /**
   * Indicates visual error feedback. Optional.
   */
  hasError?: boolean;
  /**
   * ID attribute. Optional.
   */
  id?: string;
  /**
   * Initial check status ("Indeterminate" is readonly). Optional.
   */
  initialState?: (typeof initialStates)[number];
  /**
   * Disable state attribute. Optional.
   */
  isDisabled?: boolean;
  /**
   * Label. Required.
   */
  label?: string;
  /**
   * Sizing variations. Optional.
   */
  size?: (typeof sizes)[number];
}

/**
 * Checkbox
 */
export const Checkbox = (props: CheckboxProps) => {
  const {
    check,
    initialState = 'unchecked',
    className = '',
    customClass = '',
    hasError,
    id,
    isDisabled,
    label,
    size = 'md',
    tabIndex = 0,
    testId = 'checkbox',
  } = props;

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isIndeterminate, setIsIndeterminate] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      if (initialState === 'indeterminate') {
        ref.current.indeterminate = true;
        setIsIndeterminate(true);
        ref.current.checked = false;
        setIsChecked(false);
      }
      if (initialState === 'checked') {
        ref.current.indeterminate = false;
        setIsIndeterminate(false);
        ref.current.checked = true;
        setIsChecked(true);
      }
      if (initialState === 'unchecked') {
        ref.current.indeterminate = false;
        setIsIndeterminate(false);
        ref.current.checked = false;
        setIsChecked(false);
      }
    }
  }, [initialState]);

  const handleCheck = (e: boolean) => {
    setIsChecked(e);
    setIsIndeterminate(false);
    check && check(e);
  };

  return (
    <Container
      className={`checkbox checkbox-${size} ${customClass} ${className}`}
      data-testid={testId}
      htmlFor={id}
      isDisabled={isDisabled}
      isIndeterminate={isIndeterminate}
      size={size}
    >
      <Check
        hasError={hasError}
        isChecked={isChecked}
        isIndeterminate={isIndeterminate}
        isDisabled={isDisabled}
      >
        {isChecked && <Icon name="check" />}
        {isIndeterminate && <Icon name="minus" />}
        <input
          disabled={isDisabled}
          id={id}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleCheck(e.target.checked)}
          ref={ref}
          tabIndex={tabIndex}
          type="checkbox"
        />
      </Check>
      <Text data-testid={`${testId}-label`}>{label}</Text>
    </Container>
  );
};
