import { ReactNode, useRef, useState } from 'react';
import { useFloating, offset, flip, autoUpdate } from '@floating-ui/react';
import { Container, Content, Wrapper } from './index.styles';
import { Positions } from '../../../theme';
import { useClickOutsideChecker } from '../../utils/useClickOutsideChecker';

export interface TooltipProps extends Component {
  /**
   * Tooltip information content. HTML content type. Required.
   */
  content: ReactNode;
  /**
   * Tooltip opening position. Optional.
   */
  defaultPosition?: Positions;
  /**
   * Use max width? Optional.
   */
  maxWidth?: boolean;
  /**
   * Do not underline the text. Optional.
   */
  noDecoration?: boolean;
  /**
   * Trigger on click instead of hover. Optional.
   */
  useClick?: boolean;
}

/**
 * Tooltip
 */
export const Tooltip = (props: TooltipProps) => {
  const {
    children,
    className = '',
    content,
    noDecoration = false,
    customClass = '',
    defaultPosition = 'top',
    maxWidth,
    testId = 'tooltip',
    useClick = false,
  } = props;

  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useClickOutsideChecker(wrapperRef, () => setIsVisible(false));

  const { x, y, strategy, refs } = useFloating({
    open: isVisible,
    onOpenChange: setIsVisible,
    placement: defaultPosition,
    middleware: [offset(8), flip()],
    whileElementsMounted: autoUpdate,
  });

  return (
    <Wrapper ref={wrapperRef}>
      <Container
        aria-describedby="tooltip"
        className={`ds-tooltip ${customClass} ${className}`}
        data-testid={testId}
        ref={refs.setReference}
        onClick={() => useClick && setIsVisible((prev) => !prev)}
        onPointerEnter={() => !useClick && setIsVisible(true)}
        onPointerLeave={() => !useClick && setIsVisible(false)}
        noDecoration={noDecoration}
      >
        {children}
        {isVisible && (
          <Content
            data-testid={`${testId}-tip`}
            maxWidth={maxWidth}
            ref={refs.setFloating}
            role="tooltip"
            style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
          >
            {content}
          </Content>
        )}
      </Container>
    </Wrapper>
  );
};
