import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondarySidebar, SidebarNavItem, useWindowSize } from '@sendible/design-system';
import { useNavigate } from 'react-router-dom';
import { DropdownOption } from '@sendible/design-system/src/components/molecules/Dropdown';
import { pages } from '@sendible/frontendv2/src/routes';
import { CustomAvatar } from './components';
import { useCampaignsContext } from '../../context';
import { CAMPAIGN_ACTION_DELETE, CAMPAIGN_ACTION_EDIT, CAMPAIGN_ACTION_VIEW_REPORT } from '../../constants';

export const LeftSidebar = () => {
  const { campaignsList, activeCampaignObject, setDeleteModalId, isSidebarVisible, setIsSidebarVisible, setIsEditionModalOpen } =
    useCampaignsContext();
  const { t } = useTranslation('campaigns');
  const { width } = useWindowSize();

  const campaignActions: DropdownOption[] = [
    { action: CAMPAIGN_ACTION_EDIT, label: t('dropdown_edit') },
    { action: CAMPAIGN_ACTION_DELETE, label: t('dropdown_delete') },
    { action: CAMPAIGN_ACTION_VIEW_REPORT, label: t('dropdown_view_report') },
  ];

  const ctaButtonLabel = t('sibebar_new_campaigns_button');
  const title = t('sidebar_title');
  const ariaCampaignsListLabel = t('aria_campaigns_list');
  const ariaCampaignsLabel = t('aria_campaigns');
  const ariaAddNewLabel = t('aria_add_new');

  const navigate = useNavigate();

  const secondarySidebarContent = campaignsList.map((campaign) => (
    <SidebarNavItem
      click={() => {
        navigate(`${pages.Campaigns.base}/${campaign.id}`);
        setIsSidebarVisible(false);
      }}
      data-testid={campaign.name}
      dropdownButtonClick={(action) => {
        if (campaign.id) {
          if (action === CAMPAIGN_ACTION_EDIT) {
            navigate(`${pages.Campaigns.base}/${campaign.id}`);
            setIsEditionModalOpen(campaign.id);
          } else if (action === CAMPAIGN_ACTION_DELETE) {
            setDeleteModalId(campaign.id || null);
          } else if (action === CAMPAIGN_ACTION_VIEW_REPORT) {
            navigate(`${pages.Measure.campaigns}/${campaign.id}`);
          }
        }
      }}
      dropDownOptions={campaignActions}
      id={`${campaign.id}`}
      isActive={activeCampaignObject?.id === campaign.id}
      key={`campaigns_left_sidebar_item_${campaign.id}`}
      label={campaign.name || ''}
      ariaItemLabel={`${ariaCampaignsLabel}: ${campaign.name}`}
      CustomAvatar={
        <CustomAvatar
          status={campaign.status}
          color={campaign.color}
        />
      }
    />
  ));

  useEffect(() => setIsSidebarVisible(false), [width]);

  return (
    <SecondarySidebar
      ctaButtonIcon="plus"
      ctaButtonLabel={ctaButtonLabel}
      cta={() => {
        setIsEditionModalOpen(true);
        setIsSidebarVisible(false);
      }}
      close={() => setIsSidebarVisible(false)}
      isVisible={isSidebarVisible}
      isLoading={false}
      title={title}
      ariaLabel={ariaCampaignsListLabel}
      ariaAddNewLabel={ariaAddNewLabel}
    >
      {secondarySidebarContent}
    </SecondarySidebar>
  );
};
