/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Container } from './index.styles';
import { generateId } from '../../utils/helpers';

interface Options {
  count?: number | string;
  groupCount?: number | string;
  label: string;
  route: string;
  isBeta?: boolean;
}

export interface SidebarProps {
  /**
   * Will feature option[activeIndex] as active. Required.
   */
  activeIndex: number;
  /**
   * Header name. Required.
   */
  header: string;
  /**
   * Navigation event emmiter. It emmit the event to the parent with the clicked option label. Required.
   */
  onNavClick: (route: string) => void;
  /**
   * Navigation options. E.g.: [{ label: 'calendar', url: '#calendar', count: 0, groupCount: 0 }]. Required.
   */
  options: Options[];
  /**
   * Add 'open' class when in small viewport if sidebar is open. Optional.
   */
  responsiveIsOpen?: boolean;
}

/**
 * Sidebar
 */
export const Sidebar = (props: SidebarProps) => {
  const { activeIndex, header, onNavClick, options, responsiveIsOpen = false } = props;

  const optionList = options.map((option, index) => (
    <li
      key={generateId()}
      data-testid={`legacy-sidebar-li-${index}`}
      className={`${index === activeIndex ? 'active' : ''} ${index === options.length - 1 ? 'last' : ''}`}
      title=""
    >
      <a
        className="feature-check"
        onClick={(e) => {
          e.preventDefault();
          onNavClick(option.route);
        }}
        style={{ cursor: 'default' }}
      >
        {option.label}
        {option.isBeta && <span className="badge badge-warning text-uppercase">Beta</span>}
        {option.count !== undefined && (
          <span
            className="badge badge-info scheduled-info"
            data-original-title
          >
            <span className="schedule-count">{option.count}</span>
            {option.groupCount !== undefined && <span className="schedule-team-count">{` (${option.groupCount})`}</span>}
          </span>
        )}
      </a>
    </li>
  ));

  return (
    <Container
      id="left-sidebar"
      className={`well sidebar-nav equal-height ${responsiveIsOpen ? 'open' : ''}`}
    >
      <ul
        id="engage-sidebar"
        className="nav nav-list"
      >
        <li className="nav-header outbox-header">{header}</li>
        {optionList}
      </ul>
    </Container>
  );
};
