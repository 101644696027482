import { useRef } from 'react';
import { Check, Container, Mark } from './index.styles';
import { Text } from '../../molecules/Checkbox/index.styles';

export const sizes = ['sm', 'md', 'lg', 'xl'] as const;

export interface RadioProps extends Component {
  /**
   * Name attribute. Required.
   */
  groupName: string;
  /**
   * Indicates visual error feedback. Optional.
   */
  hasError?: boolean;
  /**
   * Is checked? Optional.
   */
  isChecked?: boolean;
  /**
   * Disable state attribute. Optional.
   */
  isDisabled?: boolean;
  /**
   * Label and ID. Required.
   */
  label: string;
  /**
   * Sizing variations. Optional.
   */
  size?: (typeof sizes)[number];
}

/**
 * Radio
 */
export const Radio = (props: RadioProps) => {
  const { className = '', customClass = '', groupName, hasError, isChecked, isDisabled, label, size = 'md', testId = `radio-${label}` } = props;

  const ref = useRef<HTMLInputElement | null>(null);

  return (
    <Container
      className={`radio radio-${size} ${customClass} ${className}`}
      data-testid={testId}
      htmlFor={label}
      isDisabled={isDisabled}
      size={size}
    >
      <Check
        hasError={hasError}
        isChecked={isChecked}
        isDisabled={isDisabled}
      >
        {isChecked && (
          <Mark
            isChecked={isChecked}
            isDisabled={isDisabled}
          />
        )}
        <input
          defaultChecked={isChecked}
          disabled={isDisabled}
          id={label}
          name={groupName}
          ref={ref}
          type="radio"
          data-testid={`${testId}-input`}
        />
      </Check>
      <Text>{label}</Text>
    </Container>
  );
};
