export const brandColors = [
  'brandPrimary',
  'brandPrimaryShade10',
  'brandPrimaryShade20',
  'brandPrimaryShade30',
  'brandPrimaryShade40',
  'brandPrimaryShade50',
  'brandPrimaryTint10',
  'brandPrimaryTint20',
  'brandPrimaryTint30',
  'brandPrimaryTint40',
  'brandPrimaryTint50',
  'brandPrimaryTint60',
  'brandPrimaryTint70',
  'brandPrimaryTint80',
  'brandPrimaryTint90',
  'brandPrimaryAlpha5',
  'brandPrimaryAlpha10',
  'brandPrimaryAlpha20',
  'brandPrimaryAlpha30',
  'brandPrimaryAlpha40',
  'brandPrimaryAlpha50',
  'brandPrimaryAlpha60',
  'brandPrimaryAlpha70',
  'brandPrimaryAlpha80',
  'brandPrimaryAlpha90',
  'brandSecondary',
  'brandSecondaryTint10',
  'brandSecondaryTint20',
  'brandSecondaryTint30',
  'brandSecondaryTint40',
  'brandSecondaryTint50',
  'brandSecondaryTint60',
  'brandSecondaryTint70',
  'brandSecondaryTint80',
  'brandSecondaryTint90',
  'brandAccent',
  'brandAccentShade10',
  'brandAccentShade20',
  'brandAccentShade30',
  'brandAccentShade40',
  'brandAccentShade50',
  'brandAccentTint10',
  'brandAccentTint20',
  'brandAccentTint30',
  'brandAccentTint40',
  'brandAccentTint50',
  'brandAccentTint60',
  'brandAccentTint70',
  'brandAccentTint80',
  'brandAccentTint90',
  'brandAccentAlpha5',
  'brandAccentAlpha10',
  'brandAccentAlpha20',
  'brandAccentAlpha30',
  'brandAccentAlpha40',
  'brandAccentAlpha50',
  'brandAccentAlpha60',
  'brandAccentAlpha70',
  'brandAccentAlpha80',
  'brandAccentAlpha90',
  'brandDark',
  'brandDarkTint10',
  'brandDarkTint20',
  'brandDarkTint30',
  'brandDarkTint40',
  'brandDarkTint50',
  'brandDarkTint60',
  'brandDarkTint70',
  'brandDarkTint80',
  'brandDarkTint90',
  'brandDarkTint95',
  'brandDarkAlpha5',
  'brandDarkAlpha10',
  'brandDarkAlpha15',
  'brandDarkAlpha20',
  'brandDarkAlpha30',
  'brandDarkAlpha40',
  'brandDarkAlpha50',
  'brandDarkAlpha60',
  'brandDarkAlpha70',
  'brandDarkAlpha80',
  'brandDarkAlpha90',
  'brandLight',
  'brandLightTint90',
  'brandLightAlpha10',
  'brandLightAlpha40',
  'brandLightAlpha50',
  'brandLightAlpha60',
  'brandLightAlpha70',
  'brandLightAlpha90',
  'brandLightAlpha95',
] as const;

export const semanticColors = [
  'semanticInfo',
  'semanticInfoTint80',
  'semanticInfoAlpha5',
  'semanticInfoAlpha20',
  'semanticSuccess',
  'semanticSuccessTint80',
  'semanticSuccessTint90',
  'semanticSuccessAlpha5',
  'semanticSuccessAlpha20',
  'semanticWarning',
  'semanticWarningTint80',
  'semanticWarningAlpha5',
  'semanticWarningAlpha20',
  'semanticDanger',
  'semanticDangerTint80',
  'semanticDangerShade20',
  'semanticDangerTint90',
  'semanticDangerAlpha5',
  'semanticDangerAlpha20',
  'semanticDangerDarkMode',
] as const;

export const primaryColors = [
  'brandPrimary',
  'brandPrimaryShade10',
  'brandPrimaryShade20',
  'brandPrimaryShade30',
  'brandPrimaryShade40',
  'brandPrimaryShade50',
  'brandPrimaryTint10',
  'brandPrimaryTint20',
  'brandPrimaryTint30',
  'brandPrimaryTint40',
  'brandPrimaryTint50',
  'brandPrimaryTint60',
  'brandPrimaryTint70',
  'brandPrimaryTint80',
  'brandPrimaryTint90',
  'brandPrimaryAlpha5',
  'brandPrimaryAlpha10',
  'brandPrimaryAlpha20',
  'brandPrimaryAlpha30',
  'brandPrimaryAlpha40',
  'brandPrimaryAlpha50',
  'brandPrimaryAlpha60',
  'brandPrimaryAlpha70',
  'brandPrimaryAlpha80',
  'brandPrimaryAlpha90',
] as const;

export const secondaryColors = [
  'brandSecondary',
  'brandSecondaryTint10',
  'brandSecondaryTint20',
  'brandSecondaryTint30',
  'brandSecondaryTint40',
  'brandSecondaryTint50',
  'brandSecondaryTint60',
  'brandSecondaryTint70',
  'brandSecondaryTint80',
  'brandSecondaryTint90',
] as const;

export const accentColors = [
  'brandAccent',
  'brandAccentShade10',
  'brandAccentShade20',
  'brandAccentShade30',
  'brandAccentShade40',
  'brandAccentShade50',
  'brandAccentTint10',
  'brandAccentTint20',
  'brandAccentTint30',
  'brandAccentTint40',
  'brandAccentTint50',
  'brandAccentTint60',
  'brandAccentTint70',
  'brandAccentTint80',
  'brandAccentTint90',
  'brandAccentAlpha5',
  'brandAccentAlpha10',
  'brandAccentAlpha20',
  'brandAccentAlpha30',
  'brandAccentAlpha40',
  'brandAccentAlpha50',
  'brandAccentAlpha60',
  'brandAccentAlpha70',
  'brandAccentAlpha80',
  'brandAccentAlpha90',
] as const;

export const darkColors = [
  'brandDark',
  'brandDarkTint10',
  'brandDarkTint20',
  'brandDarkTint30',
  'brandDarkTint40',
  'brandDarkTint50',
  'brandDarkTint60',
  'brandDarkTint70',
  'brandDarkTint80',
  'brandDarkTint90',
  'brandDarkTint95',
  'brandDarkAlpha5',
  'brandDarkAlpha10',
  'brandDarkAlpha15',
  'brandDarkAlpha20',
  'brandDarkAlpha30',
  'brandDarkAlpha40',
  'brandDarkAlpha50',
  'brandDarkAlpha60',
  'brandDarkAlpha70',
  'brandDarkAlpha80',
  'brandDarkAlpha90',
] as const;

export const lightColors = [
  'brandLight',
  'brandLightTint90',
  'brandLightAlpha10',
  'brandLightAlpha40',
  'brandLightAlpha50',
  'brandLightAlpha60',
  'brandLightAlpha70',
  'brandLightAlpha90',
  'brandLightAlpha95',
] as const;

export const infoColors = [
  'semanticInfo',
  'semanticInfoShade10',
  'semanticInfoShade50',
  'semanticInfoTint80',
  'semanticInfoAlpha5',
  'semanticInfoAlpha20',
] as const;

export const successColors = [
  'semanticSuccess',
  'semanticSuccessShade10',
  'semanticSuccessShade50',
  'semanticSuccessTint80',
  'semanticSuccessTint90',
  'semanticSuccessAlpha5',
  'semanticSuccessAlpha20',
] as const;

export const warningColors = [
  'semanticWarning',
  'semanticWarningShade10',
  'semanticWarningShade50',
  'semanticWarningTint80',
  'semanticWarningAlpha5',
  'semanticWarningAlpha20',
] as const;

export const dangerColors = [
  'semanticDanger',
  'semanticDangerShade10',
  'semanticDangerShade20',
  'semanticDangerShade50',
  'semanticDangerTint80',
  'semanticDangerTint90',
  'semanticDangerAlpha5',
  'semanticDangerAlpha20',
  'semanticDangerDarkMode',
] as const;

export const socialColors = [
  'socialFacebook',
  'socialGoogleMyBusiness',
  'socialInstagram',
  'socialLinkedin',
  'socialPinterest',
  'socialTiktok',
  'socialTwitter',
  'socialYoutube',
] as const;

export const fontSizes = [
  's12',
  's14',
  's16',
  's18',
  's20',
  's24',
  's28',
  's32',
  's36',
  's42',
  's48',
  's54',
  's60',
  's68',
  's76',
  's84',
  's92',
] as const;

export const fontWeights = ['regular', 'medium', 'semiBold'] as const;

export const motions = ['default'] as const;

export const motionSpeeds = ['fast', 'normal', 'slow'] as const;

export const borderRadius = ['sm'] as const;

export const spacings = [
  's0',
  's4',
  's8',
  's12',
  's16',
  's20',
  's24',
  's28',
  's32',
  's36',
  's42',
  's48',
  's64',
  's80',
  's96',
  's128',
  's160',
  's240',
  's320',
] as const;

export const themeNames = [
  'aeroBlue',
  'appleGreen',
  'astronautBlue',
  'blackCoffee',
  'blackOrange',
  'blackWhite',
  'blackBlue',
  'blueAndOrange',
  'blueDenim',
  'blueGreyBlack',
  'blueMoon',
  'blueProfessional',
  'blueProfessionalBlack',
  'blueWatermelon',
  'beEverywhere',
  'carmineRed',
  'casablancaToryblue',
  'cinnabarOrange',
  'citronBlue',
  'crayolaGreen',
  'danubeGrey',
  'darkblueOrange',
  'darkGoldenrod',
  'default',
  'fjordBlue',
  'fluorescentYellow',
  'flushedOrange',
  'greenBlack',
  'greenGrey',
  'greyBlueGold',
  'havelockBlue',
  'heliotropePurple',
  'hippieDove',
  'lavaRed',
  'lightBlue',
  'lightGreen',
  'newsocialcloudLightBlue',
  'niagraBlue',
  'venture',
  'parsleySupernova',
  'raisinBlack',
  'allMedia',
  'sixdegreesRedBlack',
  'royalConcrete',
  'satinLinenSunglowButtons',
  'seaBlues',
  'shadyCerulean',
  'friendly',
  'smokyPink',
  'smoothBlack',
  'smoothBlue',
  'smoothSeablue',
  'smoothness',
  'smoothnessLargeTop',
  'spanishSkyBlue',
  'steelblueGrey',
  'sunshine',
  'troutPorcelain',
  'turquoiseBlue',
  'vikingZest',
  'violetWine',
  'whiteBlueOrange',
  'whiteDenim',
  'whiteGrey',
  'whitePurered',
  'yellowBlack',
  'yellowishGrey',
];

export const iconNames = [
  'academy',
  'activity',
  'align_center',
  'align_justify',
  'align_left',
  'align_right',
  'archive',
  'arrow_down',
  'arrow_down_left',
  'arrow_down_right',
  'arrow_left',
  'arrow_right',
  'arrow_up',
  'arrow_up_left',
  'arrow_up_right',
  'at_sign',
  'badge_facebook',
  'badge_instagram',
  'bar_chart',
  'bar_chart_alt',
  'bell',
  'bell_off',
  'best_time',
  'book',
  'bookmark',
  'box',
  'briefcase',
  'bulb',
  'bulk',
  'calendar',
  'camera',
  'camera_off',
  'canva',
  'chat',
  'check',
  'chevron_down',
  'chevron_left',
  'chevron_right',
  'chevron_up',
  'clipboard',
  'clock',
  'close',
  'close_circle',
  'close_lg',
  'close_sm',
  'compass',
  'compress',
  'conversation',
  'corner_down_left',
  'corner_down_right',
  'corner_left_down',
  'corner_left_up',
  'corner_right_down',
  'corner_right_up',
  'corner_up_left',
  'corner_up_right',
  'creditcard',
  'crop',
  'danger',
  'download',
  'download_cloud',
  'download_pdf',
  'dropbox',
  'edit',
  'emoji_meh',
  'emoji_sad',
  'emoji_smile',
  'expand',
  'external_link',
  'eye',
  'eye_closed',
  'eye_off',
  'facebook',
  'facebook_color',
  'file',
  'file_copy',
  'file_edit',
  'flag',
  'flash',
  'flash_off',
  'flickr',
  'folder',
  'folder_minus',
  'folder_plus',
  'gear',
  'gift',
  'giphy',
  'globe',
  'google',
  'google_color',
  'google_drive',
  'google_my_business',
  'google_my_business_color',
  'grid',
  'hashtag',
  'headphones',
  'heart',
  'help',
  'home',
  'hourglass',
  'image',
  'inbox',
  'info',
  'instagram',
  'instagram_color',
  'key',
  'layout',
  'layout_alt',
  'link',
  'linkedin',
  'linkedin_color',
  'lock',
  'log_in',
  'log_out',
  'mail',
  'map_pin',
  'megaphone',
  'menu_burger',
  'menu_kebab',
  'mic',
  'microscope',
  'minimise',
  'minimise_alt',
  'minimise_alt_reverse',
  'minus',
  'minus_circle',
  'monitor',
  'moon',
  'more_horizontal',
  'more_vertical',
  'paperclip',
  'pause_circle',
  'pexels',
  'pie_chart',
  'pin',
  'pinterest',
  'pinterest_color',
  'play',
  'play_circle',
  'plug',
  'plus',
  'plus_circle',
  'printer',
  'queue',
  'refresh_ccw',
  'refresh_cw',
  'retweet',
  'rocket',
  'rotate_ccw',
  'rotate_cw',
  'ruler',
  'schedule',
  'search',
  'select',
  'send',
  'sendible',
  'sendible_color',
  'share',
  'shield',
  'shield_off',
  'shuffle',
  'sliders',
  'smartphone',
  'sort_down',
  'sort_left',
  'sort_right',
  'sort_up',
  'star',
  'sun',
  'tag',
  'text',
  'tiktok',
  'tiktok_color',
  'trash',
  'twitter',
  'twitter_color',
  'twitter_dm',
  'twitter_follow',
  'twitter_mention',
  'twitter_retweet',
  'twitter_tweets',
  'unlock',
  'upload',
  'upload_cloud',
  'user',
  'user_minus',
  'user_plus',
  'users',
  'video',
  'video_off',
  'warning',
  'wifi',
  'wifi_off',
  'x',
  'x_color',
  'youtube',
  'youtube_color',
  'youtube_rounded',
  'youtube_rounded_color',
] as const;

export const positions = [
  'top-start',
  'top',
  'top-end',
  'right-start',
  'right',
  'right-end',
  'bottom-start',
  'bottom',
  'bottom-end',
  'left-start',
  'left',
  'left-end',
] as const;
