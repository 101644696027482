import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './index.styles';
import { useAiAssistContext } from '../../AiAssistContext';
import { Option, SelectWithInput } from '../SelectWithInput';

export const CaptionConfiguration = () => {
  const { t } = useTranslation('ai_assist');
  const { targetAudience, setTargetAudience, toneOfVoice, setToneOfVoice } = useAiAssistContext();

  const [audienceOptions, setAudienceOptions] = useState<Option[]>([
    { label: t('option_audience_food_enthusiasts'), value: 'Food enthusiasts' },
    { label: t('option_audience_health_and_wellness_enthusiasts'), value: 'Health and wellness enthusiasts' },
    { label: t('option_audience_home_buyers'), value: 'Home buyers' },
    { label: t('option_audience_patients'), value: 'Patients' },
    { label: t('option_audience_retail_shoppers'), value: 'Retail shoppers' },
    { label: t('option_audience_small_business_owner'), value: 'Small business owners' },
    { label: t('option_audience_students'), value: 'Students' },
    { label: t('option_audience_tech_enthusiasts'), value: 'Tech enthusiasts' },
    { label: t('option_audience_travellers'), value: 'Travellers' },
  ]);

  const toneOptions = [
    { label: t('option_tone_assertive'), value: 'Assertive' },
    { label: t('option_tone_catchy'), value: 'Catchy' },
    { label: t('option_tone_engaging'), value: 'Engaging' },
    { label: t('option_tone_funny'), value: 'Funny' },
    { label: t('option_tone_inspirational'), value: 'Inspirational' },
    { label: t('option_tone_professional'), value: 'Professional' },
    { label: t('option_tone_promotional'), value: 'Promotional' },
    { label: t('option_tone_shocking'), value: 'Shocking' },
  ];

  const handleEnter = (opt: string) => {
    const exists = audienceOptions.map(({ value }) => value.toLowerCase()).includes(opt.toLocaleLowerCase());

    if (!exists) setAudienceOptions((prev) => [{ label: opt, value: opt }, ...prev]);

    setTargetAudience(opt);
  };

  return (
    <Container>
      <SelectWithInput
        id="select-audience"
        testid="ai-audience-component"
        onEnter={(value) => handleEnter(value)}
        onSelect={(o) => setTargetAudience(o)}
        options={audienceOptions}
        title={targetAudience || t('audience')}
        showInput
      />
      <SelectWithInput
        id="select-tone"
        testid="ai-tone-component"
        onEnter={(value) => setToneOfVoice(value)}
        onSelect={(o) => setToneOfVoice(o)}
        options={toneOptions}
        title={toneOfVoice || t('tone')}
      />
    </Container>
  );
};
