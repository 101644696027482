import styled, { css } from 'styled-components';

export const ModalContent = styled.div`
  ${({
    theme: {
      breakPoints: { smallDevices },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: 2rem 0;
    width: 30rem;

    .input {
      input {
        margin-bottom: unset;
      }

      span {
        line-height: 1.2em;
      }
    }

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      width: 100%;
    }
  `}
`;
