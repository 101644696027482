export default {
  uploader_item_failed: 'Failed to upload',
  uploader_items_cancelled: '{{totalCanceledItems}} cancelled',
  uploader_items_failed: '{{totalFailedMedias}} failed',
  uploader_items_invalid: 'You can only upload videos and images',
  uploader_items_uploaded: 'Uploading {{totalUploadingMedias}} of {{totalMedias}} items',
  uploader_items_uploading: 'Uploading {{totalUploadingMedias}} items',
  uploader_items_complete: '{{itemsCompleted}} items complete',
  uploader_time_left: '{{timeRemaining}} {{unitsRemaining}} left',
};
