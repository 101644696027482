import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({
    theme: {
      breakPoints: { smallDevices },
    },
  }) => css`
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.25rem -0.125rem rgba(49, 49, 49, 0.15), 0 1.5rem 2.5rem -0.25rem rgba(49, 49, 49, 0.15),
      0 3.5rem 3.5rem -0.5rem rgba(49, 49, 49, 0.15);
    padding: 1rem;
    width: 20rem;
    z-index: 1;

    @media all and (${smallDevices.max}) {
      padding: unset;
      width: 100%;
    }
  `}
`;

export const Header = styled.div`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
      fontSizes,
      fontWeights,
    },
  }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: ${colors.brandDark};
      flex: 1;
      font-size: ${fontSizes.s18};
      font-weight: ${fontWeights.medium};
      text-transform: capitalize;
      margin: 0;
    }

    p:nth-child(even) {
      padding-left: 1rem;
      text-align: center;
    }

    p:nth-child(odd) {
      padding-right: 1rem;
      text-align: right;
    }

    @media all and (${smallDevices.max}) {
      p {
        font-size: ${fontSizes.s16};
      }
    }
  `}
`;

export const Actions = styled.div`
  ${({ theme: { colors } }) => css`
    align-items: center;
    display: flex;
    flex: 1;

    .button {
      &:disabled {
        background: transparent;

        svg {
          path {
            stroke: ${colors.brandDarkAlpha20};
          }
        }
      }
    }
  `}
`;

export const Grid = styled.div`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
    },
  }) => css`
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    div {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 2.5rem;
      text-transform: uppercase;
      width: 2.5rem;

      p {
        color: ${colors.brandPrimary};
        font-weight: bold;
        margin: 0;
      }
    }

    @media all and (${smallDevices.max}) {
      place-items: center;

      div {
        height: 2rem;
        width: 2rem;
      }
    }
  `}
`;
