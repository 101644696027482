import { EVENTS, publish, subscribe } from '@sendible/event-manager';
import { Sidebar } from '@sendible/design-system';
import { useBridgeContext, CountInfo } from '@sendible/shared-state-bridge';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { routeUtils } from '@sendible/common';
import { ReportsSidebar } from './components/ReportsSidebar';
import { SidebarWrapper } from './index.styles';
import { routes, SideBarConfigurationType, pages, PageBaseKeys } from '../../routes';

export const LegacySideBar = () => {
  const location = useLocation();
  const [sidebarConfig, setSideBarConfig] = useState<SideBarConfigurationType | null>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isReports, setIsReports] = useState(false);
  const [responsiveIsOpen, setResponsiveIsOpen] = useState(false);
  const { t } = useTranslation('common_terms');
  const [{ user }] = useBridgeContext();

  useEffect(() => {
    const { pathname } = location;
    const pathWihtoutSubPaths = routeUtils.getPathWithoutSubPaths(pathname);
    const routeConfigObject = routes[pathWihtoutSubPaths as PageBaseKeys];

    if (!routeConfigObject) return;

    const { sidebar: sidebarConfigObject } = routeConfigObject;

    const { links, header } = sidebarConfigObject;
    const sidebarOptions = links.map(({ route, label, isBeta, countLabel, groupCountLabel, defaultCountValue }, index) => {
      if (route === pathWihtoutSubPaths) setActiveIndex(index);

      let count;
      let groupCount;

      if (user.countLabels && countLabel) count = user.countLabels[countLabel as keyof CountInfo] || defaultCountValue;
      if (user.countLabels && groupCountLabel) groupCount = user.countLabels[groupCountLabel as keyof CountInfo] || defaultCountValue;

      return {
        count,
        groupCount,
        isBeta,
        label: t(label),
        route,
      };
    });

    setSideBarConfig((prev) => ({
      ...prev,
      header: t(header),
      links: sidebarOptions,
    }));

    setIsReports(pathWihtoutSubPaths === pages.Measure.base);
  }, [location, user]);

  useEffect(() => {
    const unsubscribe = subscribe(EVENTS.LEGACY_SIDEBAR_SHOW, (isOpen) => {
      setResponsiveIsOpen(isOpen);
    });

    return () => unsubscribe();
  }, []);

  if (!sidebarConfig) return null;

  if (isReports) {
    return (
      <SidebarWrapper
        $isReports
        $responsiveIsOpen={responsiveIsOpen}
      >
        <ReportsSidebar responsiveIsOpen={responsiveIsOpen} />
      </SidebarWrapper>
    );
  }

  return (
    <SidebarWrapper $responsiveIsOpen={responsiveIsOpen}>
      <Sidebar
        activeIndex={activeIndex}
        header={sidebarConfig.header}
        onNavClick={(route) => publish(EVENTS.CHANGE_PAGE, route)}
        options={sidebarConfig.links}
        responsiveIsOpen={responsiveIsOpen}
      />
    </SidebarWrapper>
  );
};
