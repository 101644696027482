import { Box, Icon } from '@sendible/design-system/src';
import { DateTime } from 'luxon';
import { imageUtils } from '@sendible/common';
import { PostModel } from '@sendible/frontendv2/src/models/reports';
import { PostImageContainer, PostItemMainContainer, ProfilePicture, ProfileUserName, PostFooter, PostFooterItem } from './index.styles';
import { FullWidthBox } from '../index.styles';
import { useReportsContext } from '../../context';

type PostProps = {
  postItem: PostModel;
};

export const Post = ({ postItem }: PostProps) => {
  const { isPrint } = useReportsContext();

  return (
    <PostItemMainContainer>
      <FullWidthBox margin={['s0', 's0', 's0', 's8']}>
        <ProfilePicture>
          {isPrint ? (
            <img
              src={postItem.profilePicture}
              alt={postItem.profileName}
              onError={imageUtils.removeImageOnError}
              crossOrigin="anonymous"
            />
          ) : (
            <img
              src={postItem.profilePicture}
              alt={postItem.profileName}
              onError={imageUtils.removeImageOnError}
            />
          )}
        </ProfilePicture>
        <ProfileUserName>
          {postItem.profileName}
          <p>{DateTime.fromFormat(postItem.dateMessage, 'yyyy/MM/dd HH:mm:ss ZZZ').toFormat('dd LLL yyyy HH:mm')}</p>
        </ProfileUserName>
      </FullWidthBox>
      {postItem.socialNetwork !== 'Instagram' ? <Box margin={['s0', 's8', 's0', 's8']}>{postItem.message}</Box> : null}
      {postItem.postPicture ? (
        <PostImageContainer>
          {isPrint ? (
            <img
              src={postItem.postPicture}
              alt={postItem.socialNetwork}
              onError={imageUtils.removeImageOnError}
              crossOrigin="anonymous"
            />
          ) : (
            <img
              src={postItem.postPicture}
              alt={postItem.socialNetwork}
              onError={imageUtils.removeImageOnError}
            />
          )}
        </PostImageContainer>
      ) : null}
      {postItem.socialNetwork === 'Instagram' ? <Box margin={['s0', 's8', 's0', 's8']}>{postItem.message}</Box> : null}
      <PostFooter>
        <PostFooterItem>
          {postItem.likes}
          <Icon
            className="type-icon"
            color="brandDark"
            name="heart"
          />
        </PostFooterItem>
        <PostFooterItem>
          {postItem.comments}
          <Icon
            className="type-icon"
            color="brandDark"
            name="chat"
          />
        </PostFooterItem>
      </PostFooter>
    </PostItemMainContainer>
  );
};
