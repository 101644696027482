import { Container } from './index.styles';
import { BrandColors, FontWeights } from '../../../theme';

export const alignments = ['left', 'right', 'center', 'justify'] as const;
export const semantics = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;
export const variations = [
  'list-title_12',
  'component-title_14',
  'component-title_16',
  'component-title_20',
  'layout-title_28',
  'layout-title_32',
  'layout-title_42',
  'layout-title_54',
] as const;

export interface HeadingProps extends Component {
  /**
   * Custom alignment. E.g: left, justify. Optional.
   */
  align?: (typeof alignments)[number];
  /**
   * Custom Color. Optional.
   */
  color?: BrandColors;
  /**
   * Max number of lines. If set, ellipsis will be added to the text. Optional.
   */
  maxLines?: number;
  /**
   * Semantic name. E.g: h1, h2, h3... Required.
   */
  semantic: (typeof semantics)[number];
  /**
   * Variation name. Optional.
   */
  variation?: (typeof variations)[number];
  /**
   * Custom font-weight?. Optional.
   */
  weight?: FontWeights;
}

/**
 * Heading
 */
export const Heading = (props: HeadingProps) => {
  const { align = 'left', children, className = '', customClass = '', semantic, testId = 'heading', variation = 'layout-title_28' } = props;

  return (
    <Container
      as={semantic}
      className={`heading heading-${semantic}-${variation}--${align} ${customClass} ${className}`}
      data-testid={testId}
      {...props}
    >
      {children}
    </Container>
  );
};
