export const flickr = () => (
  <>
    <path
      fill="#FF0084"
      fillRule="evenodd"
      d="M16.63 15.24a3.704 3.704 0 1 0 0-7.406 3.704 3.704 0 0 0 0 7.407"
      clipRule="evenodd"
    />
    <path
      fill="#0063DC"
      fillRule="evenodd"
      d="M7.37 15.24a3.704 3.704 0 1 0 0-7.407 3.704 3.704 0 0 0 0 7.408"
      clipRule="evenodd"
    />
  </>
);
