export default {
  aria_add_new: 'New Campaign',
  aria_campaigns: 'Campaigns',
  aria_campaigns_list: 'Campaign list',
  aria_options_for: 'Option for campaign',
  campaign_name_error: 'The campaign name must be 50 characters or less.',
  click_to_create: 'Use the New Campaign button below to create your first campaign.',
  color: 'Color',
  contact_support: 'contact support',
  daterange_heading: 'Date range',
  delete_modal_cancel_text: 'Cancel',
  delete_modal_header_text: 'Delete campaign',
  delete_modal_text: 'Are you sure you wish to delete this campaign?',
  delete_modal_yes_text: 'Yes',
  dropdown_edit: 'Edit',
  dropdown_delete: 'Delete',
  dropdown_view_report: 'View report',
  error_deleting:
    "We couldn't delete your campaign.<br />Try again. If it still doesn't work, our <a href='https://support.sendible.com/hc/en-us/requests/new' target='_blank'>support team is here to help</a>!",
  error_deleting_wl: "We couldn't delete your campaign.<br />Try again. If it still doesn't work, get in touch with your admin.",
  error_if_persists: 'Try refreshing and if the problem persists,',
  error_if_persists_wl: 'Try refreshing and if the problem persists, get in touch with your admin.',
  error_message: 'The content could not be loaded',
  error_saving:
    "We couldn't save your campaign.<br />Try again. If it still doesn't work, our <a href='https://support.sendible.com/hc/en-us/requests/new' target='_blank'>support team is here to help</a>!",
  error_saving_wl: "We couldn't save your campaign.<br />Try again. If it still doesn't work, get in touch with your admin.",
  error_type: 'Error',
  heading: 'Campaign Settings',
  input_name_placeholder: 'Campaign name',
  name_subheading: 'Campaign name',
  no_campaigns_setup: "You don't have any campaigns set up yet.",
  label: 'Label',
  label_preview: 'Label preview',
  overview_header: 'Campaign overview',
  pending_posts_header: 'Pending approval',
  posts_approved_header: 'Approved',
  posts_drafted_header: 'Drafted',
  posts_published_header: 'Sent',
  posts_queued_header: 'Queued',
  posts_rejected_header: 'Rejected',
  posts_scheduled_header: 'Scheduled',
  posts_undelivered_header: 'Undelivered',
  report: 'Report',
  sibebar_new_campaigns_button: 'New Campaign',
  sibebar_right_performance_link: 'Campaign Performance',
  sibebar_right_status_link: 'Campaign Overview',
  sibebar_right_settings_link: 'Campaign Settings',
  sidebar_title: 'Campaigns',
  settings_save_button: 'Save',
  tooltip_approved: 'Number of tasks approved for publishing.',
  tooltip_drafted: 'Number of messages drafted for future editing.',
  tooltip_pending_approval: 'Number of tasks awaiting approval.',
  tooltip_queued: 'Number of messages in the queue to be sent out.',
  tooltip_rejected: 'Number of tasks not approved for publishing.',
  tooltip_sent: 'Number of messages sent out to social networks.',
  tooltip_scheduled: 'Number of messages scheduled to go out to social networks.',
  tooltip_undelivered: 'Number of posts that failed to publish on social networks.',
  try_again: 'Try again',
  try_again_button: 'Refresh',
};
