import styled, { css } from 'styled-components';

export const Container = styled.main`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;

  @media all and (max-width: 1024px) {
    width: 80%;
  }
`;

export const Content = styled.div`
  ${({ theme: { fontSizes } }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: 'IBM Plex Sans';
    gap: 2rem;
    justify-content: center;

    .icon {
      height: ${fontSizes.s84};
      width: auto;
    }

    @media all and (max-width: 1024px) {
      .icon {
        height: ${fontSizes.s68};
      }

      h1 {
        font-size: ${fontSizes.s36};
      }
    }
  `}
`;
