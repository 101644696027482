export const google_drive = () => (
  <>
    <path
      fill="#0066DA"
      d="m4.244 17.95.822 1.422c.171.299.417.534.705.705L8.71 14.99H2.833c0 .332.086.663.257.962z"
    />
    <path
      fill="#00AC47"
      d="M12.16 9.009 9.222 3.923a1.94 1.94 0 0 0-.705.705L3.09 14.03a1.94 1.94 0 0 0-.257.962H8.71z"
    />
    <path
      fill="#EA4335"
      d="M18.55 20.077a1.94 1.94 0 0 0 .704-.705l.342-.588 1.635-2.831c.17-.3.256-.63.256-.961h-5.876l1.25 2.457z"
    />
    <path
      fill="#00832D"
      d="m12.16 9.009 2.938-5.086a1.9 1.9 0 0 0-.961-.256h-3.953a2 2 0 0 0-.962.256z"
    />
    <path
      fill="#2684FC"
      d="M15.611 14.992H8.71l-2.938 5.085c.289.17.62.256.962.256h10.855c.341 0 .673-.096.961-.256z"
    />
    <path
      fill="#FFBA00"
      d="m18.517 9.33-2.714-4.702a1.94 1.94 0 0 0-.705-.705L12.16 9.01l3.451 5.983h5.866c0-.332-.086-.663-.257-.962z"
    />
  </>
);
