export const youtube_rounded_color = () => (
  <>
    <circle
      cx="12"
      cy="12"
      r="11"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM17.1572 7.86322C17.7251 8.00808 18.1724 8.43492 18.3242 8.9769C18.6 9.95927 18.6 12.0089 18.6 12.0089C18.6 12.0089 18.6 14.0585 18.3242 15.0409C18.1724 15.5829 17.7251 15.9919 17.1572 16.1368C16.1277 16.4 12 16.4 12 16.4C12 16.4 7.87227 16.4 6.84285 16.1368C6.27493 15.9919 5.82764 15.5829 5.67583 15.0409C5.4 14.0585 5.4 12.0089 5.4 12.0089C5.4 12.0089 5.4 9.95927 5.67583 8.9769C5.82764 8.43492 6.27493 8.0081 6.84285 7.86322C7.87227 7.6 12 7.6 12 7.6C12 7.6 16.1278 7.6 17.1572 7.86322ZM14.1 12.0089L10.65 10.148V13.8698L14.1 12.0089Z"
      fill="#FF0000"
    />
  </>
);
