export const map_pin = () => (
  <>
    <path
      d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 1118 0z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13a3 3 0 100-6 3 3 0 000 6z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
