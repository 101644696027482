import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import ai_assist from './translations/en/ai_assist';
import campaigns from './translations/en/campaigns';
import common_terms from './translations/en/common_terms';
import media_library from './translations/en/media_library';
import reports from './translations/en/reports';
import uploader from './translations/en/uploader';

const isProduction = process.env.SENDIBLE_ENV === 'production';

export const resources = {
  en: {
    ai_assist,
    campaigns,
    common_terms,
    media_library,
    reports,
    uploader,
  },
};

const options: InitOptions = {
  debug: false,
  lng: 'en',
  fallbackLng: 'en',
  resources,
  interpolation: {
    escapeValue: false,
  },
  saveMissing: !isProduction,
};

i18n.use(initReactI18next).init(options);

export default i18n;
