import styled, { css } from 'styled-components';
import { Text } from '@sendible/design-system';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 20rem;
  padding: 1rem;
`;

export const Title = styled(Text)`
  ${({ theme: { colors } }) => css`
    color: ${colors.brandLight};
    font-weight: 600;
    margin-bottom: 1rem;
  `}
`;

export const Block = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  `}
`;

export const Topic = styled(Text)`
  ${({ theme: { colors } }) => css`
    color: ${colors.brandLight};

    &::before {
      content: '•';
      margin-right: 0.5rem;
    }
  `}
`;

export const Line = styled.hr`
  ${({ theme: { colors } }) => css`
    border-color: ${colors.brandLightAlpha10};
    margin: 1rem 0;
  `}
`;

export const Extra = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const Notice = styled(Text)`
  ${({ theme: { colors } }) => css`
    color: ${colors.brandLightAlpha40};
  `}
`;
