import SharedStateBridge from '@sendible/shared-state-bridge';

export default () => {
  const newUserData = window.loginContext.getCurrentDetails();
  const oldUserSharedState = SharedStateBridge.getSharedState();

  SharedStateBridge.setItem({
    compose: {
      activeComposeBoxTab: 'message',
      isComposeBoxOpen: false,
      isMediaAttacherOpen: false,
    },
    user: {
      ...oldUserSharedState.user,
      userId: newUserData.id,
      username: newUserData.login,
      email: newUserData.email,
      language: newUserData.language,
      timezone: newUserData.timezone,
      isSwitchedUser: !window.loginContext.isLoggedInUserActive(),
    },
    app: {
      isCreativeEditorOpen: {
        url: null,
        serviceSet: null,
      },
    },
  });
};
