export default function cookie(name: string) {
  return {
    get() {
      const nameEQ = `${name}=`;
      const ca = document.cookie.split(';');

      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length);
        }

        if (c.indexOf(nameEQ) === 0) {
          return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
      }

      return null;
    },

    set(value: string, days: number) {
      let expires;

      if (days) {
        const date = new Date();

        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
      } else {
        expires = '';
      }

      document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/`;
    },

    remove() {
      this.set('', -1);
    },
  };
}
