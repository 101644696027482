import { useEffect, useRef } from 'react';
import lottie from 'lottie-web/build/player/lottie_light';
import { Container } from './index.styles';
import animationData from '../../../assets/lottie/spinner.json';

export const sizes = ['xs', 'sm', 'md', 'lg', 'xl'] as const;

export interface SpinnerProps extends Component {
  /**
   * Spinner size. Optional.
   */
  size?: (typeof sizes)[number];
}

/**
 * Spinner
 */
export const Spinner = (props: SpinnerProps) => {
  const { className = '', customClass = '', testId = 'spinner' } = props;

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const instance = lottie.loadAnimation({
        animationData,
        container: ref.current,
        renderer: 'svg',
      });

      return () => instance.destroy();
    }

    lottie.setSpeed(0.66);
  }, [ref.current]);

  return (
    <Container
      {...props}
      ref={ref}
      className={`spinner ${customClass} ${className}`}
      data-testid={testId}
    />
  );
};
