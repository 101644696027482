import styled, { css } from 'styled-components';

export const CustomAvatarStyled = styled.div`
  ${({ color, theme: { colors, borderRadius } }) => css`
    background-color: ${color};
    width: 24px;
    height: 24px;
    border-radius: ${borderRadius.sm};
    border: 1px solid ${colors.brandDarkAlpha30};
    position: relative;
    > div {
      border-radius: 100%;
      background-color: white;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      right: -3px;
    }
  `}
`;

export const Status = styled.div<{ status: string }>`
  ${({ status, theme: { colors } }) => css`
    background-color: ${status === 'active' ? colors.semanticSuccess : 'white'};
    width: 4px;
    height: 4px;
    position: absolute;
    top: 2px;
    right: 2px;
    border-radius: 100%;
    border: 1px solid ${status === 'active' ? colors.semanticSuccess : colors.brandDarkAlpha30};
  `}
`;
