/* eslint-disable */
import {isAutomationEnvironment} from '@sendible/common';
// prettier-ignore
// GTM
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-TVMNTV3G');

// prettier-ignore
// pendo
if(!isAutomationEnvironment()){
  (function (apiKey) {
    (function (p, e, n, d, o) {
      let v; let w; let x; let y; let z; o = p[d] = p[d] || {}; o._q = [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; for (w = 0, x = v.length; w < x; ++w) {
        (function (m) {
          o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
        }(v[w]));
      }
      y = e.createElement(n); y.async = !0; y.src = `https://content.pendo.sendible.com/agent/static/${apiKey}/pendo.js`;
      z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
    }(window, document, 'script', 'pendo'));
  }('4770dd6b-a3cf-43ad-5b9b-6df4025fdb45'));
}else{
  window.pendo = {
    initialize: () => {},
    identify: () => {},
    updateOptions: () => {},
    pageLoad: () => {},
    track: () => {},
  }
}
/* eslint-enable */
export {};
