export const twitter_follow = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.257 10.56c-.968 1.106-2.485 1.256-3.84 1.256v.002c-1.355 0-2.872-.149-3.84-1.257-.815-.931-1.078-2.368-.805-4.393.379-2.826 2.116-4.513 4.646-4.513 2.529 0 4.265 1.687 4.645 4.513.271 2.025.007 3.462-.806 4.392zm-3.84-7.404c-2.374 0-3 2.012-3.16 3.212-.208 1.549-.057 2.627.45 3.205.454.52 1.266.743 2.71.743s2.255-.223 2.71-.743c.506-.578.657-1.655.45-3.205-.162-1.199-.787-3.212-3.16-3.212zM8.417 13.247c3.997 0 7.4 2.463 8.28 5.988h-.001c.172.695.029 1.402-.396 1.942-.407.52-1.04.82-1.73.82H2.265c-.693 0-1.325-.3-1.733-.822-.425-.54-.567-1.247-.395-1.94.877-3.525 4.282-5.988 8.28-5.988zm6.153 7.251c.234 0 .424-.085.55-.245a.757.757 0 00.12-.655c-.711-2.856-3.517-4.85-6.824-4.85s-6.114 1.995-6.824 4.85a.753.753 0 00.12.654c.126.161.317.246.552.246H14.57z"
      fill="inherit"
    />
    <path
      d="M19.812 5.576h2.75c.43 0 .859.172.859.601 0 .43-.344.688-.687.688h-2.836v2.921c0 .43-.258.688-.688.688a.678.678 0 01-.687-.688V6.951h-2.836c-.43 0-.687-.258-.687-.688 0-.43.344-.687.687-.687h2.836V2.74c0-.344.172-.687.602-.687s.687.343.687.687v2.836z"
      fill="inherit"
    />
  </>
);
