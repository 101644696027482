import { Button, Text, Icon, Box } from '@sendible/design-system';
import { EVENTS, publish } from '@sendible/event-manager';
import { Container, ErrorContainer, IconContainer } from '../index.styles';

type ErrorProps = {
  message: string;
};

export const Error = ({ message }: ErrorProps) => {
  return (
    <Container $disabled={false}>
      <ErrorContainer
        contentJustify="space-between"
        orientation="column"
      >
        <IconContainer margin={['s0', 's12', 's0', 's0']}>
          <Icon
            color="semanticDanger"
            name="danger"
          />
        </IconContainer>
        <Box margin={['s8', 's12', 's8', 's12']}>
          <Text variation="body_16">{message}</Text>
        </Box>
        <Box>
          <Button
            onClick={() => {
              publish(EVENTS.CLOSE_AI_ASSIST, {});
            }}
            icon="close_lg"
            size={14}
          />
        </Box>
      </ErrorContainer>
    </Container>
  );
};
