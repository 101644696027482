import { CampaignsProvider } from './context';
import { CampaignsPage } from './page';
import { ErrorBoundary } from '../../components';

export const Campaigns = () => {
  return (
    <ErrorBoundary translationNamespace="campaigns">
      <CampaignsProvider>
        <CampaignsPage />
      </CampaignsProvider>
    </ErrorBoundary>
  );
};
