export const corner_down_left = () => (
  <>
    <path
      d="M9 10l-5 5 5 5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 4v7a4 4 0 01-4 4H4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
