import styled, { css } from 'styled-components';
import { SecondarySidebarProps } from './index';

interface HeadProps {
  showLine: boolean;
}

export const Container = styled.aside<Omit<SecondarySidebarProps, 'title'>>`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
      motions,
      motionSpeeds,
    },
    isVisible,
  }) => css`
    background: ${colors.brandLight};
    box-shadow: 1px 0 0 0 ${colors.brandDarkAlpha20};
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    width: 15rem;

    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: ${colors.brandLight};
    }

    &::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      background-color: ${colors.brandDarkAlpha10};
      border-radius: 8px;
      height: 8px;
      -webkit-border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: ${colors.brandDarkAlpha20};
    }

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      border-radius: 0.5rem 0.5rem 0 0;
      box-shadow: none;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: fixed;
      top: 8rem;
      transition: all ${motionSpeeds.normal} ${motions.default};
      width: 100%;
      z-index: 11;

      .dropdown-backdrop {
        z-index: 99;
      }

      ${!isVisible &&
      css`
        opacity: 0;
        top: 100%;
        visibility: hidden;
      `}
    }
  `}
`;

export const Head = styled.header<HeadProps>`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
      motions,
      motionSpeeds,
    },
    showLine,
  }) => css`
    backdrop-filter: blur(0.5rem);
    background: ${colors.brandLightAlpha95};
    left: 0;
    padding: 1rem 1.5rem 0.5rem;
    position: sticky;
    top: 0;
    z-index: 1;

    .heading {
      margin-bottom: 1rem;
    }

    .button {
      width: 100%;
    }

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      align-items: center;
      backdrop-filter: none;
      background: ${colors.brandLight};
      border-radius: 0.5rem 0.5rem 0 0;
      display: flex;
      justify-content: space-between;
      min-width: calc(100% - 1rem);
      padding: 0.5rem;
      transition: all ${motionSpeeds.normal} ${motions.default};
      z-index: 1;

      .button {
        width: auto;
      }

      ${showLine &&
      css`
        box-shadow: 0 1px 0 ${colors.brandDarkAlpha20};
      `}
    }
  `}
`;

export const Nav = styled.ul`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
    },
  }) => css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    padding: 0;

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      display: block;
      height: calc(100% - 6rem);
      margin: 0;
      overflow-y: auto;
      padding-bottom: 2rem;

      .heading {
        margin-bottom: 1.5rem;
        margin-left: 1.5rem;
      }

      &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: white;
      }

      &::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: ${colors.brandDarkAlpha20};
        border-radius: 8px;
        height: 8px;
        -webkit-border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #bbb;
      }
    }
  `}
`;

export const Backdrop = styled.div<Omit<SecondarySidebarProps, 'title'>>`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
      motions,
      motionSpeeds,
    },
    isVisible,
  }) => css`
    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      background: ${colors.brandDarkAlpha90};
      cursor: pointer;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      transition: all ${motionSpeeds.normal} ${motions.default};
      width: 100%;
      z-index: 10;

      ${!isVisible &&
      css`
        opacity: 0;
        visibility: hidden;
        z-index: -1;
      `}
    }
  `}
`;
