import styled, { css } from 'styled-components';
import { Box, Lightbox } from '@sendible/design-system';

export const LightboxStyled = styled(Lightbox)`
  ${({
    theme: {
      breakPoints: { smallDevices },
    },
  }) => css`
    box-sizing: border-box;
    flex-wrap: wrap;
    z-index: 6;

    .swiper {
      flex: auto;
      max-width: 100%;
      margin: unset;
      width: 100%;
    }

    .swiper-button-next,
    .swiper-button-prev {
      background: transparent;
      padding: 0;
      border-width: 0;
      font-size: 1.125rem;
      position: absolute;
      top: 50%;
      width: calc(var(44px) / (44 * 27));
      height: var(44px);
      margin-top: calc(0px - (var(44px) / 2));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;

      &.swiper-button-disabled {
        opacity: 0.35;
        cursor: auto;
        pointer-events: none;
      }

      > svg {
        height: 1.5em;
        width: 1.5em;
      }
    }

    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      left: 10px;
      right: auto;
    }

    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
      right: 10px;
      left: auto;
    }

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;

      img,
      video {
        width: auto;
        max-width: 90%;
        max-height: 90%;
        user-select: none;
      }
    }

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      .swiper-button-next,
      .swiper-button-prev {
        bottom: 0;
        top: auto;
        width: 10%;
      }

      .swiper-button-next {
        left: 50%;
        transform: translate(calc(-50% + 2rem), 0);
      }

      .swiper-button-prev {
        left: 50%;
        transform: translate(calc(-50% - 2rem), 0);
      }
    }
  `}
`;

export const SwiperContainerBox = styled(Box)`
  flex-flow: column;
  justify-content: space-between;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  z-index: -1;
`;

export const MediaContainerBox = styled(Box)`
  width: 100%;
`;

export const Action = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const InputHolder = styled.div`
  height: 3.2rem;

  .input {
    input {
      margin: 0;
      padding-right: 0.25rem;
    }
  }
`;
