import { Route, Routes } from 'react-router-dom';
import { ReportsContainer } from './index.styles';
import CampaignsReport from './campaigns';
import { useReportsContext } from './context';
import { ErrorBoundary } from '../../components';

export const ReportsPage = () => {
  const { isPrint } = useReportsContext();

  return (
    <ErrorBoundary translationNamespace="campaigns">
      <ReportsContainer
        $isPrint={isPrint}
        contentJustify="space-between"
      >
        <Routes>
          <Route
            path="/campaigns/:id?"
            element={<CampaignsReport />}
          />
        </Routes>
      </ReportsContainer>
    </ErrorBoundary>
  );
};
