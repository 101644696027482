export const bell_off = () => (
  <>
    <g clipPath="url(#prefix__clip0_904_662)">
      <path
        d="M18 13V8a6 6 0 00-9.317-5M17 17H3l3-4V8c0-.594.088-1.18.257-1.738M1 1l22 22m-9.27-2a2 2 0 01-3.46 0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_904_662">
        <path
          fill="inherit"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </>
);
