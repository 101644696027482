import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Modal } from '@sendible/design-system';
import { ModalContent } from './index.styles';
import { useCreateMediaLibrary, useRenameMediaLibrary, useGetMediaLibraries } from '../../../../models/mediaLibraries';
import { defaultGetMediaLibrariesResponse } from '../../constants';
import { useMediaLibraryContext } from '../../context';

interface Props {
  mediaLibrary?: MediaLibraryType;
}

export const CreateMediaLibraryModal = ({ mediaLibrary }: Props) => {
  const [isCreating, setIsCreating] = useState(false);
  const [nameFeedback, setNameFeedback] = useState({});
  const [isSubmitPossible, setIsSubmitPossible] = useState(!!mediaLibrary?.name);
  const [newMediaLibraryName, setNewMediaLibraryName] = useState(mediaLibrary?.name || '');
  const { setActiveMediaLibrary, setIsCreationModalOpen, setIsRenameModalOpen } = useMediaLibraryContext();
  const createMediaLibraryMutation = useCreateMediaLibrary(newMediaLibraryName);
  const renameMediaLibraryMutation = useRenameMediaLibrary(newMediaLibraryName, mediaLibrary?.name || '');
  const { data: { mediaLibraries } = defaultGetMediaLibrariesResponse } = useGetMediaLibraries();
  const { t } = useTranslation('media_library');

  const headingTitle = t('new_media_library_modal_title');
  const buttonLabel = t(mediaLibrary ? 'button_save' : 'new_media_library_button_label');
  const inputLabel = t('new_media_library_input_label');

  const handleMediaLibraryCreation = useCallback(() => {
    setIsCreating(true);

    createMediaLibraryMutation.mutate(
      { name: newMediaLibraryName },
      {
        onSuccess: (newMediaLibrary: MediaLibraryType) => {
          setActiveMediaLibrary(newMediaLibrary);
        },
        onSettled: () => {
          setIsCreating(false);
          setIsCreationModalOpen(false);
        },
      }
    );
  }, [newMediaLibraryName]);

  const handleMediaLibraryRename = useCallback(() => {
    setIsCreating(true);
    renameMediaLibraryMutation.mutate(
      { mediaLibraryId: mediaLibrary?.id, method: 'PATCH' },
      {
        onSettled: () => {
          setIsCreating(false);
          setIsRenameModalOpen(false);
        },
      }
    );
  }, [newMediaLibraryName]);

  const isMediaLibraryNameValid = (mediaLibraryName: string) => {
    if (mediaLibraryName && mediaLibraries.length > 0) {
      const lowerCaseMediaLibraryName = mediaLibraryName.toLowerCase();
      const isNameUsed = mediaLibraries.find(({ name }: MediaLibraryType) => name.toLowerCase() === lowerCaseMediaLibraryName);

      if (isNameUsed) {
        setNameFeedback({ type: 'danger', message: 'This name is already in use. Try a different name.' });

        return false;
      }
      if (!/\S/.test(mediaLibraryName)) {
        setNameFeedback({ type: 'danger', message: 'Name must include at least 1 character' });

        return false;
      }

      setNameFeedback({});

      return true;
    }

    setNameFeedback({});

    return false;
  };

  const handleInputChange = (mediaLibraryName: string) => {
    setNewMediaLibraryName(mediaLibraryName);

    const isSubmitPoss = isMediaLibraryNameValid(mediaLibraryName);

    setIsSubmitPossible(isSubmitPoss);
  };

  const onButtonClick = useCallback(() => {
    if (mediaLibrary) {
      handleMediaLibraryRename();
    } else {
      handleMediaLibraryCreation();
    }
  }, [handleMediaLibraryRename, handleMediaLibraryCreation]);

  const keyDownHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (isSubmitPossible) {
          onButtonClick();
        }
      }
    },
    [isSubmitPossible, onButtonClick]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyDownHandler]);

  return (
    <Modal
      close={() => (mediaLibrary ? setIsRenameModalOpen(false) : setIsCreationModalOpen(false))}
      headingTitle={mediaLibrary ? undefined : headingTitle}
      animation={mediaLibrary ? 'fade' : 'bottom'}
      variation={mediaLibrary ? 'medium' : 'fullscreen'}
    >
      <ModalContent>
        <Input
          autoFocus
          change={handleInputChange}
          isRequired
          label={inputLabel}
          maxLength={200}
          size="xl"
          testId="new-media-input"
          type="text"
          feedback={nameFeedback}
          value={newMediaLibraryName}
        />
        <Button
          appearance="primary"
          onClick={onButtonClick}
          data-testid="button-create-lib"
          disabled={!isSubmitPossible}
          isLoading={isCreating}
          label={buttonLabel}
          size={18}
          variation="fill"
        />
      </ModalContent>
    </Modal>
  );
};
