import { Box, Button, Tooltip } from '@sendible/design-system/src';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { toPng } from 'html-to-image';
import { jsPDF as JsPDF } from 'jspdf';
import { pages } from '@sendible/frontendv2/src/routes';
import { HeaderContainer, PrintIconContainer } from './index.styles';
import { ReportsList, ReportWidth } from '../../consts';
import { useReportsContext } from '../../context';

export type ReportList = {
  name: string;
  id: number;
};

type HeaderProps = {
  reportsList: ReportsList[];
  currentReportId: number | 0;
};

export const Header = ({ reportsList, currentReportId }: HeaderProps) => {
  const { t } = useTranslation('reports');
  const generateReportButtonText = t('generate_report_button');
  const downloadReportTooltipText = t('download_report_tooltip');
  const navigate = useNavigate();
  const { setIsPrint } = useReportsContext();
  const [selectedReport, setSelectedReport] = useState(currentReportId);

  const handleGenerateReport = () => {
    window.pendo.track('[Report hub] Generate campaign report');
    navigate(`${pages.Measure.campaigns}/${selectedReport}`);
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedReport(parseInt(event.target.value, 10));
  };

  const downloadAsPDF = async () => {
    setIsPrint(true);

    try {
      setTimeout(async () => {
        const node = document.getElementsByClassName('v2-report-container')[0];

        if (node) {
          const dataUrl = await toPng(node as HTMLElement, { quality: 0.95, canvasWidth: ReportWidth, skipAutoScale: true, cacheBust: true });
          const imageCapture = new Image();

          imageCapture.src = dataUrl;

          imageCapture.onload = () => {
            const pdf = new JsPDF({
              orientation: imageCapture.width < imageCapture.height ? 'p' : 'l',
              unit: 'pt',
              format: [imageCapture.width, imageCapture.height],
            });

            pdf.addImage(imageCapture, 'JPEG', 0, 0, imageCapture.width, imageCapture.height);
            pdf.save('campaign-report.pdf');

            setIsPrint(false);
          };
        }
      }, 1500);
    } catch {
      setIsPrint(false);
    }
  };

  return (
    <HeaderContainer
      padding={['s12', 's12', 's12', 's12']}
      contentJustify="space-between"
    >
      <Box horizontalGap="s12">
        <Button
          label={generateReportButtonText}
          variation="outline"
          appearance="primary"
          onClick={handleGenerateReport}
        />
        <select
          value={selectedReport}
          onChange={handleChange}
        >
          {reportsList.map(({ name, id }) => {
            return (
              <option
                value={id}
                key={`report_header_${id}`}
              >
                {name}
              </option>
            );
          })}
        </select>
      </Box>
      <PrintIconContainer margin={['s0', 's12', 's0', 's0']}>
        <Tooltip
          content={downloadReportTooltipText}
          defaultPosition="bottom-end"
        >
          <Button
            onClick={downloadAsPDF}
            icon="download_pdf"
            appearance="primary"
          />
        </Tooltip>
      </PrintIconContainer>
    </HeaderContainer>
  );
};
