import { Container } from './index.styles';
import { AllColors } from '../../../theme';

export const alignments = ['left', 'right', 'center', 'justify'] as const;
export const variations = ['body_12', 'body_14', 'body_16'] as const;

export interface TextProps extends Component {
  /**
   * Custom alignment. E.g: left, justify. Optional. (Long text will always be left)
   */
  align?: (typeof alignments)[number];
  /**
   * Custom Color. Optional.
   */
  color?: AllColors;
  /**
   * Max number of lines. If set, ellipsis will be added to the text. Optional.
   */
  maxLines?: number;
  /**
   * Variation name. Optional.
   */
  variation?: (typeof variations)[number];
  /**
   * title. Optional.
   */
  title?: string;
}

/**
 * Text
 */
export const Text = (props: TextProps) => {
  const { align = 'left', children, className = '', customClass = '', testId = 'text', variation = 'body_16' } = props;

  return (
    <Container
      className={`text text-${variation}--${align} ${customClass} ${className}`}
      isLong={String(children).length > 200}
      data-testid={testId}
      {...props}
    >
      {children}
    </Container>
  );
};
