/* eslint-disable no-console */
import cookie from './cookie';

export default {
  error: (...args: any) => {
    const isDebugMode = !!cookie('debug').get();

    if (isDebugMode) {
      console.log(...args);
    }
  },
};
