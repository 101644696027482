import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Text } from '@sendible/design-system';
import { Actions, Content } from './index.styles';
import { useDeleteMediaLibrary } from '../../../../models/mediaLibraries';
import { useMediaLibraryContext } from '../../context';

interface DeleteMediaLibraryModalProps {
  mediaLibrary: MediaLibraryType;
}

export const DeleteMediaLibraryModal = ({ mediaLibrary }: DeleteMediaLibraryModalProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { setIsDeleteModalOpen } = useMediaLibraryContext();
  const deleteMediaLibraryMutation = useDeleteMediaLibrary();
  const { t } = useTranslation('media_library');

  const closeButtonLabel = t('deletion_modal_button_back');
  const deleteButtonLabel = t('dropdown_delete');
  const ariaCloseDeleteModalLabel = t('aria_close_delete_modal');
  const ariaDeleteMediaLabel = t('aria_delete_media');
  const modalTitle = t('delete_media_library_modal_title');

  const handleMediaLibraryDeletion = () => {
    setIsDeleting(true);

    deleteMediaLibraryMutation.mutate(
      { mediaLibraryId: mediaLibrary.id },
      {
        onSettled: () => {
          setIsDeleting(false);
          setIsDeleteModalOpen(false);
        },
      }
    );
  };

  return (
    <Modal
      close={() => setIsDeleteModalOpen(false)}
      headingTitle={modalTitle}
      variation="small"
    >
      <Content>
        <Text>{t('deletion_modal_text', { mediaName: mediaLibrary.name })}</Text>
        <Actions>
          <Button
            onClick={() => setIsDeleteModalOpen(false)}
            label={closeButtonLabel}
            size={16}
            data-testid="modal-button-back"
            variation="outline"
            autoFocus
            aria-label={ariaCloseDeleteModalLabel}
          />
          <Button
            appearance="primary"
            onClick={() => handleMediaLibraryDeletion()}
            label={deleteButtonLabel}
            isLoading={isDeleting}
            size={16}
            data-testid="modal-button-delete"
            variation="fill"
            aria-label={ariaDeleteMediaLabel}
          />
        </Actions>
      </Content>
    </Modal>
  );
};
