export const google_color = () => (
  <>
    <path
      d="M2.12855 7.11584L5.8361 9.95086C5.62413 10.5947 5.50951 11.2835 5.50951 12C5.50951 12.7163 5.62411 13.4051 5.83604 14.0489L2.12839 16.8838C1.40545 15.4138 1 13.7565 1 12C1 10.2433 1.40551 8.58591 2.12855 7.11584Z"
      fill="#FBBC05"
    />
    <path
      d="M12.0233 0.976685C14.6733 0.976685 17.0552 1.91524 18.9578 3.45467L19.2886 3.7325L16.0818 6.93926C14.9795 6.03736 13.5765 5.4862 12.0233 5.4862C9.13255 5.4862 6.69222 7.35209 5.8365 9.94965L2.1284 7.11614C3.92131 3.47049 7.66692 0.976685 12.0233 0.976685Z"
      fill="#EA4335"
    />
    <path
      d="M22.2949 9.99573C22.4452 10.6471 22.5454 11.3486 22.5454 12C22.5454 19.0148 17.5349 23.0232 12.0232 23.0232C7.6658 23.0232 3.91939 20.5281 2.12702 16.881L5.8333 14.0405C6.68613 16.6432 9.12894 18.5137 12.0232 18.5137C14.9743 18.5137 17.2247 17.0657 17.8725 14.5305L17.9357 14.2547H12.0232V9.99573H22.2949Z"
      fill="#34A853"
    />
    <path
      d="M22.2949 9.99573C22.4452 10.6471 22.5455 11.3486 22.5455 12C22.5455 15.5924 21.2313 18.3963 19.2088 20.2772L15.689 17.5526C16.751 16.8822 17.5148 15.8763 17.8586 14.5838L17.9357 14.2547H12.0233V9.99573H22.2949Z"
      fill="#4285F4"
    />
  </>
);
