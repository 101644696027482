import { createPortal } from 'react-dom';
import { ToastNotificationData, useToastNotificationContext } from './context';
import { Container } from './index.styles';

import { Toast, defaultPosition, positions as positionsArray } from '../../molecules/Toast';

const sortToastsByPosition = (toastNotificationState: ToastNotificationData[]) => {
  return toastNotificationState.sort(({ position: A = defaultPosition }, { position: B = defaultPosition }) => {
    if (A < B) return -1;
    if (A > B) return 1;

    return 0;
  });
};

function chunkArrayByPosition(sortedToastsArray: ToastNotificationData[]) {
  type resultType = {
    key?: ToastNotificationData[];
  };

  const result: resultType = {};

  sortedToastsArray.forEach((item: ToastNotificationData) => {
    const key = item.position as keyof resultType;

    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
  });

  return Object.values(result);
}

export const ToastNotification = () => {
  const { toastNotificationState, closeToastNotification } = useToastNotificationContext();

  const toastsElements = () => {
    const sortedToastsDataArray = sortToastsByPosition(toastNotificationState);
    const chunkedToastsDataArray = chunkArrayByPosition(sortedToastsDataArray);

    const chunkElements = positionsArray.map((position) => {
      const positionToastsArray =
        chunkedToastsDataArray.find((chunk) => {
          return chunk[0].position === position;
        }) || [];

      return (
        <div
          key={`toast-container-${position}`}
          className={`toast-container ${position} ${position.includes('top') ? 'top-container' : 'bottom-container'}`}
        >
          {positionToastsArray.map((props) => {
            if (props.parentElement) {
              return createPortal(
                <Toast
                  {...props}
                  key={`toast-${props.id}`}
                  close={() => {
                    closeToastNotification(props.id);
                  }}
                />,
                props.parentElement
              );
            }

            return (
              <Toast
                {...props}
                key={`toast-${props.id}`}
                close={() => {
                  closeToastNotification(props.id);
                }}
              />
            );
          })}
        </div>
      );
    });

    return <>{chunkElements}</>;
  };

  return <Container>{toastsElements()}</Container>;
};
