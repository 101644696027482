import { useEffect } from 'react';

const useKeyDown = (callback: () => void, keyType?: string | undefined, dependencies?: boolean[]) => {
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (!keyType || event.key === keyType) {
        event.preventDefault();
        callback();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, dependencies);
};

export default useKeyDown;
