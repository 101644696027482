export const google = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.023 1c2.644 0 5.021.937 6.92 2.473l.33.277-3.2 3.2a6.36 6.36 0 00-4.05-1.45 6.487 6.487 0 00-6.5 6.5 6.485 6.485 0 006.5 6.5c1.563 0 2.929-.407 3.963-1.168l.217-.168.156-.134.123-.113.175-.176.076-.082.165-.193.108-.14.136-.189c.053-.079.105-.16.154-.242l.142-.254.11-.229.097-.225.094-.255.045-.139.065-.227.074-.316h-5.9V10h10.25a9.126 9.126 0 01.234 2.667l-.04.596-.03.287-.057.437-.043.272-.076.41-.074.334-.087.345-.113.392-.083.262-.127.358-.102.263-.137.327a10.41 10.41 0 01-.622 1.193l-.127.203-.187.282-.216.301-.172.224-.155.192-.246.285c-1.971 2.193-4.807 3.37-7.79 3.37-4.231 0-7.885-2.357-9.726-5.837l-.148-.29A11.01 11.01 0 011.023 12a10.968 10.968 0 0111-11z"
      fill="inherit"
    />
  </>
);
