import { Box } from '@sendible/design-system';
import styled from 'styled-components';

export const ErrorBoundaryStyled = styled(Box)`
  max-width: 25rem;
  padding: 0 1.5rem 1.5rem;
  margin: auto;
  flex-flow: column;
  .inline {
    display: inline;
  }
  .error_boundary-icon-danger {
    flex-shrink: 0;
    height: 3rem;
    margin-bottom: 1rem;
    width: 3rem;
  }

  .error_boundary-error-text {
    text-transform: uppercase;
  }
`;
