import styled from 'styled-components';

export const CeSDKWrapper = styled('div')`
  height: 100vh;
  width: 100vw;
  background: #1a181d !important;

  .cesdk-photo-editor {
    height: 100%;
  }

  .ubq-public {
    --ubq-canvas: hsl(260, 9.09%, 12.94%);
    --ubq-elevation-1: hsl(255, 8.7%, 18.04%);
    --ubq-elevation-2: hsl(261.82, 9.4%, 22.94%);
    --ubq-elevation-3: hsl(258.75, 9.52%, 32.94%);
    --ubq-foreground-default: hsla(0, 0%, 100%, 0.9);
    --ubq-foreground-light: hsla(0, 0%, 100%, 0.7);
    --ubq-foreground-info: hsla(0, 0%, 100%, 0.5);
    --ubq-foreground-active: hsla(247.2, 100%, 85.29%, 0.9);
    --ubq-foreground-accent: hsl(0, 0%, 0%);
    --ubq-foreground-danger-default: hsl(208 14% 18%);
    --ubq-foreground-notice-default: hsla(0, 0%, 0%, 0.9);
    --ubq-interactive-default: hsl(258.46, 9.09%, 28.04%);
    --ubq-interactive-hover: hsl(261.82, 9.4%, 22.94%);
    --ubq-interactive-pressed: hsl(257.14, 8.86%, 15.49%);
    --ubq-interactive-selected: hsl(260, 9.23%, 25.49%);
    --ubq-interactive-active-default: hsl(247.2, 100%, 85.29%);
    --ubq-interactive-active-hover: hsl(246.12, 100%, 90.39%);
    --ubq-interactive-active-pressed: hsl(247.2, 100%, 80.39%);
    --ubq-interactive-accent-default: hsl(247.2, 100%, 85.29%);
    --ubq-interactive-accent-hover: hsl(246.12, 100%, 90.39%);
    --ubq-interactive-accent-pressed: hsl(247.2, 100%, 80.39%);
    --ubq-interactive-danger-default: hsl(346 98% 81%);
    --ubq-interactive-danger-hover: hsl(347 100% 88%);
    --ubq-interactive-danger-pressed: hsl(344 89% 75%);
    --ubq-interactive-template-default: hsl(273 100% 82%);
    --ubq-interactive-template-hover: hsl(273 100% 89%);
    --ubq-interactive-template-pressed: hsl(273 100% 77%);
    --ubq-interactive-group-default: hsl(0 0% 0% / 0);
    --ubq-interactive-group-hover: hsl(208 14% 18%);
    --ubq-interactive-group-active-default: hsl(208 13% 23%);
    --ubq-interactive-group-active-hover: hsl(208 12% 28%);
    --ubq-input-default: hsl(240, 6.67%, 2.94%);
    --ubq-input-hover: hsl(240, 20%, 0.98%);
    --ubq-border-default: hsla(261.18, 8.99%, 62.94%, 0.1);
    --ubq-stroke-contrast-1: hsla(0, 0%, 100%, 0.15);
    --ubq-stroke-contrast-2: hsla(0, 0%, 100%, 0.3);
    --ubq-stroke-contrast-3: hsl(200 11% 95% / 0.9);
    --ubq-focus-default: hsl(243.24, 73.62%, 80.24%);
    --ubq-focus-outline: hsl(260, 9.09%, 12.94%);
    --ubq-overlay: hsl(207 18% 10% / 0.8);
    --ubq-notice-info: hsl(0, 0%, 100%);
    --ubq-notice-success: hsl(159.96, 53.24%, 60.29%);
    --ubq-notice-warning: hsl(36.62, 64.62%, 66.58%);
    --ubq-notice-error: hsl(335.3, 85.64%, 78.41%);
    --ubq-effect-shadow: 0px 1px 2px 0px hsla(0, 0%, 0%, 0.24), 0px 0px 0px 0.5px hsla(0, 0%, 0%, 0.12);
    --ubq-effect-focus: 0px 1px 2px 0px hsla(0, 0%, 0%, 0.24), 0px 0px 0px 0.5px hsla(0, 0%, 0%, 0.12), 0px 0px 0px 2px hsl(210 15% 15%),
      0px 0px 0px 3px hsl(221 100% 80%), 0px 0px 0px 7px hsl(221 100% 80% / 0.35);
    --ubq-progress: hsla(0, 0%, 100%, 0.7);
    --ubq-static-selection-frame: hsl(230, 100%, 60%);
    --ubq-static-contrast-white: hsl(0, 0%, 100%);
    --ubq-static-contrast-black: hsl(0, 0%, 0%);
    --ubq-static-snapping: hsl(338, 100%, 50%);
    --ubq-static-bleed: hsl(334, 73%, 43%);
    --ubq-static-text-variable: hsl(274, 97%, 60%);
    --ubq-static-card-label-background: linear-gradient(180deg, rgba(0, 0, 0, 0) 14.46%, rgba(0, 0, 0, 0.6) 100%);
    --ubq-static-card-background: linear-gradient(180deg, hsla(0, 0%, 100%, 0.08), hsla(0, 0%, 0%, 0.08)), hsla(0, 0%, 67%, 0.16);
  }
`;
