import { useTranslation } from 'react-i18next';
import { Button } from '@sendible/design-system';
import { useAiAssistContext } from '../../AiAssistContext';

export const GenerateButton = () => {
  const { t } = useTranslation('ai_assist');
  const { generateContentHandler, isGenerating, isGenerationEnabled } = useAiAssistContext();

  const label = t('generate_button');
  const generatingLabel = t('generating_button');

  return (
    <Button
      onClick={generateContentHandler}
      disabled={!isGenerationEnabled}
      isLoading={isGenerating}
      label={isGenerating ? generatingLabel : label}
      size={14}
      appearance="primary"
      variation="fill"
    />
  );
};
