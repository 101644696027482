import styled, { css } from 'styled-components';
import { RadioProps } from '.';

interface CheckProps {
  isChecked?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
}

type ContainerProps = Omit<RadioProps, 'label' | 'groupName' | 'select'> & Pick<CheckProps, 'isDisabled'>;

export const Container = styled.label<ContainerProps>`
  ${({ theme: { colors, fontSizes }, isDisabled, size }) => css`
    align-items: flex-start;
    color: ${colors.brandDark};
    cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
    display: flex;
    gap: 1em;
    user-select: none;

    * {
      cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
      font-size: inherit;
    }

    ${size === 'sm' &&
    css`
      font-size: ${fontSizes.s12};
    `}

    ${size === 'md' &&
    css`
      font-size: ${fontSizes.s16};
    `}

    ${size === 'lg' &&
    css`
      font-size: ${fontSizes.s20};
    `}

    ${size === 'xl' &&
    css`
      font-size: ${fontSizes.s24};
    `}
  `}
`;

export const Check = styled.span<CheckProps>`
  ${({ theme: { colors, motionSpeeds, motions }, hasError, isChecked }) => css`
    align-items: center;
    border-radius: 5rem;
    display: flex;
    flex-shrink: 0;
    height: 2em;
    justify-content: center;
    position: relative;
    width: 2em;

    input {
      appearance: none;
      background: ${isChecked ? colors.brandPrimary : colors.brandLight};
      border-radius: 5rem;
      height: 1.5em;
      margin: 0;
      outline-offset: 0px;
      outline: 3px solid transparent;
      transition: all ${motionSpeeds.fast} ${motions.default};
      width: 1.5em;

      ${isChecked &&
      css`
        background: ${colors.brandPrimary};

        ${hasError &&
        css`
          background: ${colors.brandPrimary};
        `}
      `}

      ${!isChecked &&
      css`
        background: ${colors.brandLight};
        box-shadow: inset 0 0 0 1px ${colors.brandDarkAlpha20};

        &:hover {
          box-shadow: inset 0 0 0 1px ${colors.brandPrimary};
        }

        ${hasError &&
        css`
          background: ${colors.brandLight};
          box-shadow: inset 0 0 0 1px ${colors.semanticDanger};
        `}
      `}

      &:focus-visible,
      &:focus {
        box-shadow: inset 0 0 0 1px ${colors.brandPrimary};
        outline-color: ${colors.brandPrimaryAlpha20};
      }

      &:disabled {
        background: ${colors.brandDarkAlpha5};
        box-shadow: none;
      }
    }
  `}
`;

export const Mark = styled.span<Pick<CheckProps, 'isChecked' | 'isDisabled'>>`
  ${({ theme: { colors, motionSpeeds, motions }, isChecked, isDisabled }) => css`
    background: ${isChecked ? colors.brandLight : 'transparent'};
    border-radius: 5rem;
    display: block;
    height: 0.5em;
    left: 50%;
    position: absolute;
    top: 50%;
    transition: all ${motionSpeeds.fast} ${motions.default};
    transform: translate(-50%, -50%);
    width: 0.5em;
    z-index: 1;

    ${isDisabled &&
    css`
      background: ${colors.brandDarkAlpha10};
    `}
  `}
`;
