import { Heading, Icon, IconProps, Tooltip } from '@sendible/design-system/src';
import { Container, Row } from './index.styles';

type MetricOverviewProps = {
  iconName: IconProps['name'];
  title: string;
  tooltip: string;
  value: number;
};

export const MetricOverview = ({ title, tooltip, value, iconName }: MetricOverviewProps) => {
  return (
    <Container>
      <Icon
        className="type-icon"
        color="brandLight"
        name={iconName}
      />
      <Row>
        <Heading
          align="center"
          semantic="h3"
          color="brandLight"
          variation="component-title_16"
        >
          {title}
        </Heading>
        <Tooltip
          content={tooltip}
          maxWidth
        >
          <Icon name="help" />
        </Tooltip>
      </Row>
      <p>{value}</p>
    </Container>
  );
};
