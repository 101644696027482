import styled, { css } from 'styled-components';
import { Box, Button } from '@sendible/design-system';

export const FullWidthBox = styled(Box)`
  margin-bottom: -0.5rem;
  width: 100%;
`;

export const ActionButton = styled(Button)`
  margin-left: 1rem;
`;

export const Container = styled.section<{ $disabled: boolean }>`
  ${({ theme: { colors }, $disabled }) => css`
    align-items: flex-start;
    background: ${colors.brandLight};
    border-radius: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem 1.5rem;
    box-shadow: 0 0.75rem 1.5rem rgba(0, 0, 0, 0.2);

    .text {
      overflow-y: auto;
      max-height: 50vh;
      word-break: break-word;

      &:focus-visible {
        outline: none;
      }

      &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: white;
      }

      &::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: ${colors.brandDarkTint80};
        border-radius: 8px;
        height: 8px;
        -webkit-border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: ${colors.brandDarkTint70};
      }
    }

    ${$disabled &&
    css`
      .text {
        color: ${colors.brandDarkAlpha50};
        user-select: none;
      }
    `}
  `}
`;

export const ActionsSection = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  place-self: flex-end;
`;

export const ErrorContainer = styled(Box)`
  flex-flow: row;
  width: 100%;
`;

export const IconContainer = styled(Box)`
  align-self: center;
`;
