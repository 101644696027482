import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid;
  min-height: 8.125rem;
  padding: 0 3.5%;
  align-items: center;

  p {
    margin: 0.313rem 0 0 0;
    font-size: 2.125rem;
    letter-spacing: -1px;
    font-weight: 900;
    white-space: nowrap;
    padding: 0.313rem 0 0.625rem 0;
  }

  .type-icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const Row = styled.div`
  ${({ theme: { colors } }) => css`
    align-items: center;
    display: flex;
    gap: 0.5rem;
    margin-top: 0.938rem;

    .icon {
      height: 1rem;
      width: 1rem;

      path {
        stroke: ${colors.brandLight};
        stroke-width: 2px;
      }
    }
  `}
`;
