export const defaultMediaLibrary = { id: '', name: '' };
export const defaultGetMediaLibrariesResponse = { mediaLibraries: [], canDelete: false };
export const mediaLibraryIdParam = 'mediaLibraryId';
export const mediaSearchParam = 'filtername';
export const searchInputThrottle = 1000;
export const mediasPerPageMobile = 25;
export const mediasPerPageDesktop = 50;
export const allValuesForMediasPerPage = [mediasPerPageMobile, mediasPerPageDesktop];
export const MEDIA_LIBRARY_ACTION_RENAME = 'rename';
export const MEDIA_LIBRARY_ACTION_DELETE = 'delete';
