import styled, { css } from 'styled-components';
import { Positions } from '../../../theme';
import { Box } from '../../atoms/Box';
import { Button } from '../Button';
import { NotificationTypeEnum } from '.';

interface ToastTypePropsInterface {
  type: NotificationTypeEnum;
  position: Positions;
}

export const BoxFullWidth = styled(Box)`
  width: 80%;
`;

export const PaginationControlContainer = styled('div')`
  display: grid;
  margin-right: 1rem;
  min-width: 4rem;
  grid-auto-flow: column;
`;

export const ButtonBack = styled(Button)`
  justify-self: start;
`;

export const ButtonForward = styled(Button)`
  justify-self: end;
`;

export const PaginationContainer = styled('div')`
  font-size: 0.75rem;
`;

export const Container = styled('div')<ToastTypePropsInterface>`
  ${({ theme: { colors }, type = '' }) => css`
    align-items: center;
    background-color: ${colors.brandDarkTint30};
    box-shadow: 0px 1px 18px rgba(32, 30, 36, 0.12), 0px 6px 10px rgba(32, 30, 36, 0.14), 0px 3px 5px -1px rgba(32, 30, 36, 0.2);

    border-color #fbeed5;
    border-radius: 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 8px 14px 8px 14px;
    width: 350px;
    word-break: break-word;
    z-index: 9999999;
    p {
      margin: unset;
    }
    .icon {
      width: 1rem;
      height: 1rem;

      path {
        stroke: black;
        stroke-width: 2.5;
      }
    }

    .close {
      &:hover, &:focus, &:active {
        background: transparent;
        box-shadow: none !important;
      }

      > svg {
        stroke: white;
        stroke-width: 2;
        font-size: 0.5rem;
      }
    }

    ${
      type === 'info' &&
      css`
        background-color: ${colors.semanticInfoTint80};
        color: ${colors.semanticInfoShade50};

        button {
          color: ${colors.semanticInfoShade50};
        }

        .icon {
          path {
            stroke: ${colors.semanticInfoShade50};
          }
        }

        .close > svg {
          path {
            stroke: ${colors.semanticInfoShade50};
          }
        }
      `
    }

    ${
      type === 'success' &&
      css`
        background-color: ${colors.semanticSuccessTint80};
        color: ${colors.semanticSuccessShade50};

        button {
          color: ${colors.semanticSuccessShade50};
        }

        .icon {
          path {
            stroke: ${colors.semanticSuccessShade50};
          }
        }

        .close > svg {
          path {
            stroke: ${colors.semanticSuccessShade50};
          }
        }
      `
    }

    ${
      type === 'warning' &&
      css`
        background-color: ${colors.semanticWarningTint80};
        color: ${colors.semanticWarningShade50};

        button {
          color: ${colors.semanticWarningShade50};
        }

        .icon {
          path {
            stroke: ${colors.semanticWarningShade50};
          }
        }

        .close > svg {
          path {
            stroke: ${colors.semanticWarningShade50};
          }
        }
      `
    }

    ${
      type === 'error' &&
      css`
        background-color: ${colors.semanticDangerTint80};
        color: ${colors.semanticDangerShade50};

        button {
          color: ${colors.semanticDangerShade50};
        }

        .icon {
          path {
            stroke: ${colors.semanticDangerShade50};
          }
        }

        .close > svg {
          path {
            stroke: ${colors.semanticDangerShade50};
          }
        }
      `
    }
  `}
`;
