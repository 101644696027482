import { FC } from 'react';

interface Props {
  isFrontendv2Route: boolean;
}

const PageContent: FC<Props> = (props) => {
  const { isFrontendv2Route } = props;

  return (
    <div
      className="backbone-container backbone-container-page-content"
      style={{ display: isFrontendv2Route ? 'none' : 'block', visibility: isFrontendv2Route ? 'hidden' : 'visible' }}
    >
      <div
        className="container-fluid"
        id="page-holder"
      >
        <div
          className="row-fluid"
          id="page"
        >
          <div className="span2 left-sidebar">
            <div
              id="left-sidebar"
              className="well sidebar-nav equal-height"
            />
          </div>
          <div className="js-main-col span10">
            <div
              id="tool-bar"
              className="navbar"
            />
            <div className="main-content">
              <div className="loading" />
            </div>
          </div>
          <div className="overlay" />
          <div className="extraOverlay" />

          <div className="modal-backdrop"> </div>
        </div>
      </div>
      <div id="footer" />
    </div>
  );
};

export default PageContent;
