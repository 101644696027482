import * as icons from './iconlist.exports';
import { Container } from './index.styles';
import { BrandColors, IconNames, SemanticColors } from '../../../theme';

export interface IconProps extends Component {
  /**
   * Color. Optional.
   */
  color?: BrandColors | SemanticColors;
  /**
   * Icon name. Required. (You can see the full list at branding/icons)
   */
  name: IconNames;
  /**
   * Stroke width. Optional.
   */
  strokeWidth?: number;
  /**
   * Use path strokes (borders) or not. Optional.
   */
  useStroke?: boolean;
}

/**
 * Icon
 */
export const Icon = (props: IconProps) => {
  const { className = '', customClass = '', name, testId = `svg-${name}` } = props;

  const Path = icons[name];

  return (
    <Container
      className={`icon ${customClass} ${className}`}
      data-testid={testId}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path />
    </Container>
  );
};
