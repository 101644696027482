export const corner_up_left = () => (
  <>
    <path
      d="M9 14L4 9l5-5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 20v-7a4 4 0 00-4-4H4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
