import styled, { css } from 'styled-components';

export const Content = styled.section`
  ${({
    theme: {
      breakPoints: { smallDevices },
    },
  }) => css`
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 3rem;
    flex: 1;

    @media all and (${smallDevices.max}) {
      box-sizing: border-box;
      padding: 1rem;
    }
  `}
`;
