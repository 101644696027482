import { Icon, Tooltip } from '@sendible/design-system';
import { GridItem, GridItemNumber, GridItemTitle } from './index.styles';

type GridItemComponentProps = {
  count: number;
  title: string;
  tooltip: string;
};

export const GridItemComponent = ({ title, count, tooltip }: GridItemComponentProps) => {
  return (
    <GridItem
      orientation="column"
      contentAlignment="center"
    >
      <Tooltip
        content={tooltip}
        defaultPosition="top-end"
        maxWidth
      >
        <Icon name="help" />
      </Tooltip>
      <GridItemTitle>{title}</GridItemTitle>
      <GridItemNumber>{count}</GridItemNumber>
    </GridItem>
  );
};
