import styled, { css } from 'styled-components';
import { SkeletonProps } from '.';

export const Container = styled.div<Omit<SkeletonProps, 'count'>>`
  ${({ theme: { borderRadius, colors, motions }, height = '0.5rem', shape = 'free', width }) => css`
    animation: skeleton 1.5s ${motions.default} infinite;
    background: ${colors.brandDarkAlpha50};
    border-radius: ${borderRadius.sm};
    flex-shrink: 0;
    height: ${height};
    width: ${width};
    pointer-events: none;

    ${shape === 'square' &&
    css`
      width: ${width};
      height: unset;
      aspect-ratio: 1 / 1;
    `}

    ${shape === 'circle' &&
    css`
      border-radius: 1000rem;
      height: ${width};
      width: ${width};
    `}

    @keyframes skeleton {
      from {
        opacity: 0.1;
      }
      50% {
        opacity: 0.2;
      }
      to {
        opacity: 0.1;
      }
    }
  `}
`;
