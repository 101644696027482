import { Box, Skeleton } from '@sendible/design-system';
import { ContainerStyled } from './index.styles';
import { BoxStyled } from '../../index.styles';

export const LoadingHeader = () => (
  <ContainerStyled padding={['s24', 's24', 's8', 's24']}>
    <BoxStyled
      contentAlignment="center"
      contentJustify="space-between"
      padding={['s0', 's0', 's32', 's0']}
      overflow="nowrap"
    >
      <Box
        className="name-container"
        horizontalGap="s28"
      >
        <Skeleton
          height="1rem"
          width="13rem"
          data-testid="heading-skeleton"
        />
      </Box>
      <Skeleton
        className="hide-in-mobile"
        height="0.75rem"
        width="6rem"
        data-testid="upload-button-skeleton"
      />
    </BoxStyled>
    <Skeleton
      height="2rem"
      width="100%"
    />
  </ContainerStyled>
);
