import styled, { css } from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

export const RouterLink = styled(ReactRouterLink)`
  ${({ theme: { colors, motions, motionSpeeds } }) => css`
    align-items: center;
    border-radius: 2px;
    color: ${colors.brandPrimary};
    display: inline-flex;
    font-size: inherit;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: all ${motionSpeeds.fast} ${motions.default};

    svg {
      margin-right: 0.5em;
      height: 1em;
      width: 1em;
    }

    &:hover,
    &:focus-visible,
    &:visited {
      color: ${colors.brandPrimary};
      text-decoration-color: ${colors.brandPrimary};
    }

    &:focus {
      color: ${colors.brandPrimaryShade30};
      text-decoration-color: ${colors.brandPrimaryShade30};
    }

    &:focus-visible {
      outline: 2px solid ${colors.brandPrimaryAlpha60};
      outline-offset: 2px;
    }
  `}
`;

export const CommonLink = styled.a`
  ${({ theme: { colors, motions, motionSpeeds } }) => css`
    align-items: center;
    border-radius: 2px;
    color: ${colors.brandPrimary};
    display: inline-flex;
    font-size: inherit;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: all ${motionSpeeds.fast} ${motions.default};

    svg {
      margin-right: 0.5em;
      height: 1em;
      width: auto;
    }

    &:hover,
    &:focus-visible,
    &:visited {
      color: ${colors.brandPrimary};
      text-decoration-color: ${colors.brandPrimary};
    }

    &:focus {
      color: ${colors.brandPrimaryShade30};
      text-decoration-color: ${colors.brandPrimaryShade30};
    }

    &:focus-visible {
      outline: 2px solid ${colors.brandPrimaryAlpha60};
      outline-offset: 2px;
    }
  `}
`;
