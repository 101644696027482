import { Box } from '@sendible/design-system/src';
import styled from 'styled-components';

export const PostEngagementContainer = styled(Box)`
  flex-direction: column;
  width: 100%;
  padding: 1.875rem 1.875rem 2.5rem 1.875rem;
  border-radius: 0.375rem;
  background-color: #ffffff;
  gap: 1rem;
`;

export const EmptyResults = styled(Box)`
  width: 100%;
  justify-content: center;
`;
