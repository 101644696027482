import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({
    theme: {
      breakPoints: { mediumDevices, smallDevices },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    flex: 1;

    .grid {
      gap: 1.5rem;
    }

    @media all and (${mediumDevices.max}) {
      flex-wrap: wrap;
      gap: 0.75rem;

      .grid {
        grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
        gap: 1rem;
        width: 100%;
      }
    }
  `}
`;

export const PageHeader = styled.div`
  ${({
    theme: {
      breakPoints: { smallDevices, mediumDevices },
    },
  }) => css`
    align-items: center;
    display: flex;
    width: 100%;

    .button-sidebar {
      display: none;
    }

    .heading {
      word-break: break-all;
    }
    @media all and (${mediumDevices.max}) {
      .hide-mobile {
        display: none;
      }
    }
    @media all and (${smallDevices.max}) {
      gap: 0.5rem;
      width: 100%;

      .button-sidebar {
        display: flex;
        margin-left: -0.75rem;
      }

      .heading {
        font-size: 1.25rem;
      }
    }
  `}
`;

export const SectionTitle = styled.p`
  ${({ theme: { colors } }) => css`
    color: ${colors.brandDarkAlpha50};
    font-size: 16px;
    margin: 0 0 2px;
    align-self: flex-end;
  `}
`;
