import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { colors, spacings } }) => css`
    align-items: center;
    background: ${colors.brandLight};
    box-sizing: border-box;
    display: flex;
    gap: ${spacings.s8};
    padding: ${spacings.s8} ${spacings.s24};
    width: 100%;

    .checkbox {
      align-items: center;

      & span:nth-child(2) {
        font-size: 0.75em;
      }
    }
  `}
`;
