import { Heading } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';

type PreviewProps = {
  $color: string;
};

export const Container = styled.div`
  ${({
    theme: {
      breakPoints: { smallDevices },
    },
  }) => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;

    @media all and (${smallDevices.max}) {
      padding: 2rem;
    }
  `}
`;

export const Content = styled.div``;

export const Group = styled.div<{ $gap?: number }>`
  ${({ $gap = 1.5 }) => css`
    display: flex;
    flex-direction: column;
    gap: ${`${$gap}rem`};
  `}
`;

export const DateArea = styled.div`
  ${({
    theme: {
      breakPoints: { smallDevices },
    },
  }) => css`
    align-items: self-start;
    display: flex;
    gap: 2rem;

    h6 {
      padding-top: 0.45rem;
    }

    @media all and (${smallDevices.max}) {
      flex-direction: column;
      gap: 1rem;
    }
  `}
`;

export const Hr = styled.hr`
  ${({ theme: { colors } }) => css`
    border-color: ${colors.brandDarkAlpha5};
    margin: 0 0 1.5rem 0;
    padding: 0;
    width: 100%;
  `}
`;

export const Title = styled(Heading)`
  font-weight: 600;
`;

export const Row = styled.div<{ $alignment?: 'flex-start' | 'center'; $height?: string }>`
  ${({
    theme: {
      breakPoints: { laptop, smallDevices },
    },
    $alignment = 'center',
    $height,
  }) => css`
    align-items: ${$alignment};
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem 2rem;

    .input {
      flex: 1;

      input {
        margin: 0;
      }
    }

    @media all and (${laptop.min}) {
      ${$height &&
      css`
        height: ${$height};
      `}
    }

    @media all and (${smallDevices.max}) {
      align-items: center;
      height: unset;
    }
  `}
`;

export const ColorBox = styled.div`
  align-items: center;
  display: flex;
  gap: 0.75rem;
`;

export const Preview = styled.div<PreviewProps>`
  ${({ theme: { colors }, $color }) => css`
    background: ${$color};
    border-radius: 0.4rem;
    color: ${colors.brandLight};
    line-height: 1em;
    font-size: 0.8rem;
    font-style: italic;
    font-weight: 500;
    max-width: 7.35rem;
    padding: 0.3rem;
    text-transform: uppercase;
    user-select: none;

    span {
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`;

export const ColorBoxPopover = styled.div`
  margin-top: 0.5rem;
  position: fixed;
  z-index: 2;
`;
