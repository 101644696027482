export const badge_facebook = () => (
  <>
    <circle
      cx="12"
      cy="12"
      r="9"
      fill="#4577E0"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.967 8.547a.65.65 0 01-.014.92l-6.188 6a.65.65 0 01-.93-.027l-2.813-3.054a.65.65 0 11.956-.88l2.36 2.563 5.71-5.536a.65.65 0 01.919.014z"
    />
  </>
);
