import { Container, Message } from './index.styles';
import { generateId } from '../../utils/helpers';
import { Icon } from '../../atoms/Icon';
import { Text } from '../../atoms/Text';

export interface FeedbackItem {
  message: string;
  done: boolean;
}

export interface FeedbackProps extends Component {
  /**
   * Feedback list with message and done property. E.g.: [{ message: 'wrong password', done: false }]. Required.
   */
  feedbacks: FeedbackItem[];
}

/**
 * Feedback
 */
export const Feedback = (props: FeedbackProps) => {
  const { className = '', customClass = '', feedbacks, testId = 'feedback' } = props;

  const done = feedbacks
    .filter((f: FeedbackItem) => f.done)
    .map((f: FeedbackItem) => (
      <Message
        done={f.done}
        className="feedback-message"
        key={generateId()}
      >
        <Icon name="check" />
        <Text variation="body_12">{f.message}</Text>
      </Message>
    ));

  const toBeDone = feedbacks
    .filter((f: FeedbackItem) => !f.done)
    .map((f: FeedbackItem) => (
      <Message
        done={f.done}
        className="feedback-message"
        key={generateId()}
      >
        <Icon name="minus" />
        <Text variation="body_12">{f.message}</Text>
      </Message>
    ));

  return (
    <Container
      className={`feedback ${customClass} ${className}`}
      data-testid={testId}
    >
      {done}
      {toBeDone}
    </Container>
  );
};
