import styled, { css } from 'styled-components';
import { MediaCardProps } from '.';

interface ImageProps {
  isSelected?: boolean;
  showCheckbox?: boolean;
}

export const Container = styled.div`
  ${({
    theme: {
      breakPoints: { smallDevices },
    },
  }) => css`
    position: relative;
    width: 100%;

    .button {
      transition: all 0.1s ease-in-out;
    }

    .button {
      margin-right: -1rem;
      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      .button {
        margin-right: 0;
        opacity: 1;
        visibility: visible;
      }
    }

    .show-dropdown-button {
      margin-right: 0;
      opacity: 1;
      visibility: visible;

      &:hover {
        .button {
          margin-right: 0;
          opacity: 1;
          visibility: visible;
        }
      }
    }

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      .button {
        display: flex;
      }
    }
  `}
`;

export const Image = styled.div<ImageProps>`
  ${({
    theme: { borderRadius, colors, motions, motionSpeeds },
    isSelected,
    showCheckbox,
    theme: {
      breakPoints: { smallDevices },
    },
  }) => css`
    align-items: center;
    aspect-ratio: 1 / 1;
    background: ${colors.brandDarkAlpha10};
    border-radius: ${borderRadius.sm};
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;

    &::before {
      border-radius: inherit;
      box-shadow: inset 0 0 0 1px ${isSelected ? colors.brandPrimary : colors.brandDarkAlpha20};
      content: '';
      display: block;
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      user-select: none;
      width: 100%;
      z-index: 1;
    }

    .checkbox {
      left: 0.5rem;
      opacity: ${showCheckbox ? 1 : 0};
      position: absolute;
      top: 0.5rem;
      transition: all ${motionSpeeds.fast} ${motions.default};
      z-index: 1;

      span:nth-child(2) {
        display: none;
      }

      @media all and (${smallDevices.min}) and (${smallDevices.max}) {
        opacity: 1;
        z-index: 2;
      }
    }

    .show-checkbox {
      opacity: 1;
    }

    .placeholder {
      path {
        stroke: ${colors.brandDarkAlpha60};
      }
    }

    img {
      cursor: pointer;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      user-select: none;
    }

    &:hover {
      &::before {
        box-shadow: inset 0 0 0 1px ${isSelected ? colors.brandPrimary : colors.brandDark};
      }

      .checkbox {
        opacity: 1;
        z-index: 2;
      }
    }

    ${showCheckbox &&
    css`
      &:hover {
        background: ${colors.brandPrimaryAlpha10};
      }
    `}
  `}
`;

export const BackgroundFiller = styled.button`
  background: transparent;
  border: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  z-index: 1;
`;

export const Time = styled.span`
  ${({ theme: { borderRadius, colors, fontSizes } }) => css`
    background: ${colors.brandDarkAlpha90};
    border-radius: ${borderRadius.sm};
    bottom: 0.5rem;
    color: ${colors.brandLight};
    font-size: ${fontSizes.s12};
    left: 0.5rem;
    line-height: 1em;
    padding: 0.25rem;
    position: absolute;
    z-index: 2;
  `};
`;

export const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Details = styled.div<ImageProps>`
  ${({ theme: { fontWeights }, isSelected }) => css`
    flex: 1;
    gap: 0.5rem;
    overflow: hidden;
    padding: 0.5rem 0;

    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ${isSelected &&
    css`
      .text:nth-child(1) {
        font-weight: ${fontWeights.semiBold};
        letter-spacing: -0.000001em;
      }
    `}
  `}
`;

export const Progress = styled.div<Pick<MediaCardProps, 'loadingProgress'>>`
  ${({ theme: { colors, motions, motionSpeeds }, loadingProgress }) => css`
    background: ${colors.brandDarkAlpha10};
    border-radius: 1rem;
    bottom: 1rem;
    height: 0.125rem;
    left: 1rem;
    position: absolute;
    width: calc(100% - 2rem);
    z-index: 1;

    span {
      background: ${colors.brandPrimary};
      border-radius: 1rem;
      display: block;
      height: 100%;
      transition: all ${motionSpeeds.normal} ${motions.default};
      width: ${loadingProgress}%;
    }
  `}
`;
