export enum QuickActions {
  Hashtags = 'hashtags',
  Rephrase = 'rephrase',
  Spelling = 'spelling',
  Shorten = 'shorten',
  Lengthen = 'lengthen',
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  Instagram = 'Instagram',
  GMB = 'Google My Business',
  LinkedIn = 'LinkedIn',
  Pinterest = 'Pinterest',
  Tiktok = 'TikTok',
}

export enum Errors {
  QuickActionError = 'action_error',
  PromptError = 'prompt_error',
  GenericError = 'generic_error',
}
