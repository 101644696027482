import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { colors } }) => css`
    align-items: center;
    background: ${colors.brandDarkAlpha90};
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1042;
  `}
`;

export const DashedCircle = styled.div`
  ${({ theme: { colors } }) => css`
    align-items: center;
    aspect-ratio: 1 / 1;
    border: 2px dashed ${colors.brandLightAlpha70};
    border-radius: 80svh;
    display: flex;
    height: 80svh;
    justify-content: center;
  `}
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
`;

export const IconsRow = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;

  .icon {
    height: 3rem;
    width: 3rem;
  }

  & .icon:nth-child(1) {
    transform: rotate(-15deg);
  }
  & .icon:nth-child(3) {
    transform: rotate(15deg);
  }
`;

export const SupportedFilesRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;

  .heading {
    margin-bottom: 0.5rem;
  }
`;

export const FileTypeRow = styled.div`
  ${({ theme: { colors } }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-width: 35svh;

    p {
      color: ${colors.brandLight};
    }
  `}
`;
