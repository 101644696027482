import { Button, Heading } from '@sendible/design-system';
import styled, { css } from 'styled-components';

export const Container = styled.header`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0.5rem 1.5rem 0.5rem 1rem;
`;
export const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;
export const HeadingStyled = styled(Heading)`
  align-self: center;
`;

export const MainHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1.5rem 0;
`;

export const MinimiseButton = styled(Button)<{ rotateIconDegrees: number }>`
  ${({ rotateIconDegrees }) => css`
    svg {
      transform: rotate(${rotateIconDegrees}deg) scaleX(-1);
    }
  `}
`;
