import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { Keyboard, Navigation, Lazy } from 'swiper';
import { Icon, Spinner, Text, Button, Box, Input, InputFeedbackType } from '@sendible/design-system';
import { Action, MediaContainerBox, InputHolder, LightboxStyled, SwiperContainerBox } from './index.styles';
import useKeyDown from '../../../../hooks/useKeyDown';
import { mediaDownloadedFromPreview, previewMediasOpened } from '../../pendoEvents';
import { useRenameMedia } from '../../../../models/medias';
import { useMediaLibraryContext } from '../../context';

type PreviewProps = {
  requestClose: () => void;
  mediaList: MediaType[];
  initialMediaId: number;
};

export const PREVIEW_LIGHTBOX_TEST_ID = 'previewmedias_test';

export const Preview = (props: PreviewProps) => {
  const { requestClose, mediaList, initialMediaId } = props;

  const initialMediaIndex = mediaList.findIndex((media) => media.id === initialMediaId);
  const [mediaObject, setMediaObject] = useState<MediaType>(mediaList[initialMediaIndex]);
  const [feedback, setFeedback] = useState<InputFeedbackType | undefined>(undefined);
  const { mediaToDelete, setMediaToDelete } = useMediaLibraryContext();
  const { t } = useTranslation('media_library');
  const [isRenaming, setIsRenaming] = useState(false);
  const [newName, setNewName] = useState('');
  const renameMediaMutation = useRenameMedia(mediaObject.type || 'Image', newName);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const swiperRef = useRef(null) as React.RefObject<any>;

  const listOfNames = mediaList.map(({ name }) => name);
  const initialName = mediaObject.name;

  const ariaPostToComposeLabel = t('aria_post_to_compose');
  const ariaDownloadMediaLabel = t('aria_download_media');
  const ariaDeleteMediaLabel = t('aria_delete_media');
  const ariaMoveSliderLeftLabel = t('aria_move_slider_left');
  const ariaMoveSliderRightLabel = t('aria_move_slider_right');
  const ariaCloseLightboxLabel = t('aria_close_lightbox');

  useKeyDown(() => {
    if (!mediaToDelete) {
      requestClose();
    }
  }, 'Escape');

  if (mediaList.length === 0) requestClose();

  const onSlideChange = ({ activeIndex }: { activeIndex: number }) => {
    setMediaObject(mediaList[activeIndex]);
    setFeedback(undefined);

    const videos = document.querySelectorAll('video');

    Array.prototype.forEach.call(videos, (video) => video.pause());
  };

  const handleDownload = () => {
    (document.activeElement as HTMLElement).blur();

    const tempButton = document.createElement('a');

    tempButton.setAttribute('download', mediaObject.name);
    tempButton.href = mediaObject.downloadUrl;
    tempButton.click();
    tempButton.remove();

    window.pendo.track(mediaDownloadedFromPreview, {
      name: mediaObject.name,
      type: mediaObject.type,
    });
  };

  const handleRename = () => {
    if (newName === mediaObject.name || newName.length === 0 || newName === initialName) return;

    if (listOfNames.includes(newName)) {
      setFeedback({
        message: 'Name already in use',
        type: 'danger',
      });
    } else if (mediaObject) {
      setIsRenaming(true);

      renameMediaMutation.mutate(
        { mediaId: mediaObject.id },
        {
          onSettled: () => {
            setIsRenaming(false);
            swiperRef.current.swiper.activeIndex = 0;
          },
        }
      );
    }
  };

  const handleKeyPress = (key: string, name: string) => {
    setFeedback(undefined);
    if (key === 'Enter') setNewName(name);
  };

  useEffect(() => setMediaObject(mediaList[swiperRef.current.swiper.activeIndex]), [mediaList]);

  useEffect(() => handleRename(), [newName]);

  useEffect(() => {
    if (typeof swiperRef.current.swiper.enabled === 'boolean') {
      swiperRef.current.swiper.enabled = !mediaToDelete;
    }
  }, [mediaToDelete]);

  useEffect(() => {
    window.pendo.track(previewMediasOpened, {
      initialAssetName: mediaObject.name,
      initialAssetType: mediaObject.type,
      initialAssetExtension: mediaObject.type || 'Image',
    });
  }, []);

  const config = {
    className: 'lightbox__swiper',
    lazy: {
      loadPrevNext: true,
    },
    initialSlide: initialMediaIndex || 0,
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    modules: [Keyboard, Navigation, Lazy],
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
    preloadImages: false,
    onSlideChange,
  };

  return createPortal(
    <LightboxStyled
      darkmode
      close={requestClose}
      testId={PREVIEW_LIGHTBOX_TEST_ID}
      ariaCloseLabel={ariaCloseLightboxLabel}
    >
      <SwiperContainerBox
        orientation="row"
        verticalGap="s32"
      >
        <Swiper
          {...config}
          ref={swiperRef}
        >
          {mediaList.map((media) => (
            <SwiperSlide key={media.id}>
              {media.type === 'Video' && (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video
                  className="swiper-lazy"
                  controls
                  tabIndex={-1}
                >
                  <source
                    src={media.originalUrl}
                    type="video/webm"
                  />
                </video>
              )}
              {media.type === 'Image' && (
                <img
                  className="swiper-lazy"
                  data-src={media.originalUrl}
                  alt=""
                />
              )}
              <div className="swiper-lazy-preloader">
                <Spinner size="lg" />
              </div>
            </SwiperSlide>
          ))}
          <button
            type="button"
            className="swiper-button-prev"
            tabIndex={0}
            aria-label={ariaMoveSliderLeftLabel}
          >
            <Icon
              color="brandLight"
              name="arrow_left"
            />
          </button>
          <button
            type="button"
            className="swiper-button-next"
            tabIndex={0}
            aria-label={ariaMoveSliderRightLabel}
          >
            <Icon
              color="brandLight"
              name="arrow_right"
            />
          </button>
        </Swiper>
        <MediaContainerBox
          contentAlignment="center"
          contentJustify="center"
          orientation="column"
        >
          <InputHolder>
            <Input
              blur={(name) => setNewName(name)}
              darkmode
              feedback={feedback}
              isDisabled={isRenaming}
              isLoading={isRenaming}
              keyPress={(key, name) => handleKeyPress(key, name)}
              label={t('media_preview_input_label')}
              maxLength={250}
              size="sm"
              testId="name-input"
              minWidth="4rem"
              maxWidth="20rem"
              adaptWidthToText
              value={mediaObject.name}
            />
          </InputHolder>
          <Box
            horizontalGap="s16"
            margin={['s8', 's0', 's24', 's0']}
          >
            <Action>
              <Button
                disabled
                isLoading={false}
                data-testid="preview-medias-post-to-compose"
                onClick={() => null}
                icon="send"
                darkmode
                size={18}
                aria-label={ariaPostToComposeLabel}
              />
              <Text
                color="brandLight"
                variation="body_12"
              >
                Post
              </Text>
            </Action>
            <Action>
              <Button
                onClick={handleDownload}
                darkmode
                icon="download_cloud"
                size={18}
                aria-label={ariaDownloadMediaLabel}
              />
              <Text
                color="brandLight"
                variation="body_12"
              >
                {t('media_preview_download')}
              </Text>
            </Action>
            <Action>
              <Button
                onClick={() => setMediaToDelete({ media: mediaObject, source: 'preview' })}
                darkmode
                icon="trash"
                size={18}
                data-testid="delete_media_button"
                aria-label={ariaDeleteMediaLabel}
              />
              <Text
                color="brandLight"
                variation="body_12"
              >
                Delete
              </Text>
            </Action>
          </Box>
        </MediaContainerBox>
      </SwiperContainerBox>
    </LightboxStyled>,
    document.getElementById('lightbox-root') as HTMLElement
  );
};
