import { ForwardedRef, InputHTMLAttributes, forwardRef } from 'react';
import { Container } from './index.styles';
import { IconNames } from '../../../theme';
import { Icon } from '../../atoms/Icon';
import { Spinner } from '../../atoms/Spinner';

export const appearances = ['default', 'primary', 'secondary', 'success', 'danger', 'warning', 'info'] as const;
export const iconPositions = ['left', 'right'] as const;
export const sizes = [12, 14, 16, 18] as const;
export const types = ['button', 'submit', 'reset'] as const;
export const variations = ['default', 'fill', 'outline'] as const;

type RestProps = Omit<InputHTMLAttributes<HTMLButtonElement>, 'type'> & Pick<Component, 'customClass' | 'className'>;

export interface ButtonProps extends RestProps {
  /**
   * Appearance name. Optional. (Note: Semantic appearances only exists on fill variation and secondary only on floating button)
   */
  appearance?: (typeof appearances)[number];
  /**
   * Set dark mode colors or not. Optional.
   */
  darkmode?: boolean;
  /**
   * If you pass a value here, the component button will float on screen on mobile. Optional.
   */
  floatAppearance?: (typeof appearances)[number];
  /**
   * Icon name. If it has icon, icon will be applied. Optional. (You can see the full list at branding/icons)
   */
  icon?: IconNames;
  /**
   * Second Icon name. If it has a second icon, icon will be applied. Optional. (You can see the full list at branding/icons)
   */
  secondIcon?: IconNames;
  /**
   * Icon positon. Optional.
   */
  iconPosition?: (typeof iconPositions)[number];
  /**
   * Loading state. Optional.
   */
  isLoading?: boolean;
  /**
   * Button text. Optional. (Leave it blank if you want only icon)
   */
  label?: string;
  /**
   * Size. Optional.
   */
  size?: (typeof sizes)[number];
  /**
   * Button type attribute. Optional.
   */
  type?: (typeof types)[number];
  /**
   * Variation name. Optional.
   */
  variation?: (typeof variations)[number];
}

/**
 * Button
 */
export const Button = forwardRef((props: ButtonProps, forwardedRef: ForwardedRef<HTMLButtonElement>) => {
  const {
    appearance = 'default',
    className = '',
    customClass = '',
    darkmode,
    floatAppearance,
    icon,
    secondIcon,
    iconPosition = 'left',
    isLoading,
    label,
    size = 12,
    type = 'button',
    variation = 'default',
    ...rest
  } = props;

  const normalClassName = `button button-${variation}-${appearance}-${size} ${customClass} ${className}`;
  const iconClassName = `button button-${variation}-${appearance}-${size}--icon-${iconPosition} ${customClass} ${className}`;

  return (
    <>
      {icon || label ? (
        <Container
          className={icon ? iconClassName : normalClassName}
          $appearance={appearance}
          $darkmode={darkmode}
          $floatAppearance={floatAppearance}
          $hasIcon={!!icon}
          $iconPosition={iconPosition}
          $isLoading={isLoading}
          $label={label}
          $size={size}
          $variation={variation}
          type={type}
          ref={forwardedRef}
          {...rest}
        >
          {isLoading && (
            <Spinner
              size="xs"
              id={rest.id ? `${rest.id}-spinner` : ''}
            />
          )}
          {!isLoading && icon && (
            <Icon
              name={icon}
              id={rest.id ? `${rest.id}-icon-left` : ''}
            />
          )}
          {label && <span id={rest.id ? `${rest.id}-label` : ''}>{label}</span>}
          {!isLoading && secondIcon && (
            <Icon
              name={secondIcon}
              id={rest.id ? `${rest.id}-icon-right` : ''}
            />
          )}
        </Container>
      ) : null}
    </>
  );
});
