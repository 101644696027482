export const google_my_business = () => (
  <>
    <path
      d="M19.482 16.152c.02.128.033.228.04.337a3.1 3.1 0 01-.107 1.096c-.122.42-.353.816-.675 1.113a2.42 2.42 0 01-1.093.567c-.448.102-.921.105-1.366-.014a2.67 2.67 0 01-1.692-3.776 2.686 2.686 0 011.511-1.322 2.701 2.701 0 011.797.016c.319.12.61.3.86.532-.056.06-.115.118-.174.176l-.086.086-.49.49a1.436 1.436 0 00-.576-.338 1.569 1.569 0 00-1.58.412 1.668 1.668 0 00-.387.628 1.61 1.61 0 00.001 1.036l-.002.002v.002l.002.005.024.066c.118.3.32.56.584.746a1.59 1.59 0 001.23.26c.21-.034.41-.113.589-.23v.007c.279-.19.47-.481.533-.812H16.97l.001-1.085h2.51z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.71 2a2.182 2.182 0 00-2.134 1.723L1.02 9.257a.522.522 0 00-.005.268 3.146 3.146 0 001.363 2.465v7.127c0 1.226.994 2.22 2.22 2.22h14.766a2.219 2.219 0 002.218-2.22v-7.092A3.144 3.144 0 0023 9.398a.525.525 0 00-.027-.167l-1.558-5.543A2.176 2.176 0 0019.292 2H4.71zm-.555 10.54a3.14 3.14 0 002.618-1.402A3.14 3.14 0 009.39 12.54a3.14 3.14 0 002.617-1.402 3.14 3.14 0 002.617 1.402 3.14 3.14 0 002.617-1.401 3.14 3.14 0 003.29 1.329v6.65c0 .644-.523 1.167-1.167 1.167H4.597a1.167 1.167 0 01-1.167-1.168v-6.66c.233.054.476.083.725.083zm7.32-9.488v6.346c0 .024.002.047.005.07a2.091 2.091 0 01-4.183-.105l.72-6.311h3.458zm1.057 6.347a2.091 2.091 0 004.183.05l-.73-6.397h-3.459V9.32a.524.524 0 01.006.08zm7.863-5.458l.013.052 1.54 5.475a2.091 2.091 0 01-4.182-.069.531.531 0 00-.013-.115l-.71-6.233h2.248c.53 0 .99.37 1.104.89zM6.24 9.338l.718-6.287H4.71c-.532 0-.99.37-1.111.918l-.007.025-1.529 5.433a2.091 2.091 0 004.181.06.526.526 0 01-.004-.149z"
      fill="inherit"
    />
  </>
);
