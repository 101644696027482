import styled from 'styled-components';

export const Container = styled('div')`
  .toast-container {
    display: flex;
    flex-flow: column;
    position: absolute;
    width: fit-content;

    &.top-container {
      flex-direction: column-reverse;
      margin-top: 1.5rem !important;
      top: 0;

      > div {
        margin-bottom: 0.25rem;
      }
    }

    &.bottom-container {
      bottom: 0;
      margin-bottom: 1.5rem !important;

      > div {
        margin-top: 0.25rem;
      }
    }

    &.top {
      left: 0;
      right: 0;
      margin: auto;
    }

    &.top-start {
      left: 2rem;
    }

    &.top-end {
      right: 2rem;
    }

    &.bottom {
      left: 0;
      right: 0;
      margin: auto;
    }

    &.bottom-start {
      left: 2rem;
    }

    &.bottom-end {
      right: 2rem;
    }
  }
`;
