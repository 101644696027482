import styled, { css } from 'styled-components';

export const Container = styled.div`
  bottom: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  left: 50%;
  width: 50rem;
  position: fixed;
  transform: translate(-50%, 0);
  z-index: 1;
`;

export const Backdrop = styled.div`
  ${({ theme: { colors } }) => css`
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 0;
    background: ${colors.brandDarkAlpha30};
  `}
`;
