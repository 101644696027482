// Lookup method

// How to use:

// In template:
//  lookup('Welcome to %firstname %lastname').replace("%firstname", "firstname").replace("%lastname", "lastname")

// In Dictionary
//  "welcome to %firstname %lastname": {
//         "en": "welcome to %firstname %lastname",
//      "de": "willkommen %lastname %firstname"
//     },
import dictionary from './dictionary';
import TZobj from './timezones';

export { isoLangs } from './isoLangs';
export { TZobj };

window.dictionary = dictionary;
export function loadLanguage(onLanguageLoaded, forceLanguage) {
  // forceLanguage param is currently used to load an extended
  // dictionary from the signup/login section if a wl.
  if (forceLanguage) {
    window.$current_user.language = forceLanguage;
  }

  if (window.$current_user.language == 'en') {
    // empty the dictionary
    dictionary.templates = {};
    if (onLanguageLoaded)setTimeout(onLanguageLoaded, 0);
    return;
  }

  // if language is available then load it
  if (window.$.inArray(window.$current_user.language, dictionary.availableLanguages) !== -1) {
    // if current language not loaded than attempt to load
    if (typeof dictionary.templates.COMPOSE === 'undefined') {
      // empty the dictionary
      dictionary.templates = {};
      import(`./output/dictionary_${window.$current_user.language}_template.js`).then(() => {
        const allLowerCaseDictionary = {};

        // this loop converts all english terms to lowercase
        // eslint-disable-next-line
        for (const englishTerm in window.sendibleLocalisedLookup) { 
          if (!window.sendibleLocalisedLookup.hasOwnProperty(englishTerm)) {
            // eslint-disable-next-line
            continue;
          }

          allLowerCaseDictionary[englishTerm.toLowerCase()] = window.sendibleLocalisedLookup[englishTerm];
        }

        // set the templates to the new dictionary for the language requested
        dictionary.templates = allLowerCaseDictionary;

        if (onLanguageLoaded)onLanguageLoaded();
      });
    } else if (onLanguageLoaded)onLanguageLoaded();
    // else default to english
  } else {
    window.$current_user.language = 'en';
    dictionary.templates = {};
    if (onLanguageLoaded)setTimeout(onLanguageLoaded, 0);
  }
}
export function lookup(term, strTimeUnit, forceLanguage) {
  let localTerm;
  let userLang;
  let languageDetected;
  let languageWithoutExtension;
  let tagObj;
  let regexLookupRTags;
  let templateTerm;

  if (forceLanguage) {
    languageDetected = forceLanguage;
  }

  // localisation-mod as per logic below set $current_user.language in 360
  if (!window.$current_user || typeof window.$current_user.language === 'undefined') {
    userLang = navigator.language || navigator.userLanguage;
    languageDetected = userLang ? userLang.split('-')[0] : 'en';
    if (!window.$current_user) {
      window.$current_user = {};
    }
    window.$current_user.language = languageDetected;
  } else {
    languageDetected = window.$current_user.language;
  }

  if (languageDetected.indexOf('_') != -1) {
    languageWithoutExtension = languageDetected.split('_')[0];
  } else {
    languageWithoutExtension = languageDetected;
  }

  // setup dictionary
  if (strTimeUnit && strTimeUnit === 'days' && dictionary) {
    // if daysofweek exists for language then use that (see dictionary.js)
    if (dictionary.common.daysOfWeek[languageWithoutExtension]) {
      return dictionary.common.daysOfWeek[languageWithoutExtension].split(' ');
    }
    return dictionary.common.daysOfWeek.en.split(' ');
  } if (
    strTimeUnit
&& typeof dictionary.common[strTimeUnit] !== 'undefined'
  ) {
    if (
      strTimeUnit == 'months'
&& dictionary.common.months[languageWithoutExtension]
    ) {
      return dictionary.common.months[languageWithoutExtension].split(' ');
    } if (dictionary.common[strTimeUnit][languageWithoutExtension]) {
      return dictionary.common[strTimeUnit][languageWithoutExtension];
    }
    return dictionary.common[strTimeUnit].en;
  }

  if (typeof term === 'undefined' || term === '') return '';

  if (dictionary && dictionary.templates) {
    templateTerm = dictionary.templates[term.toLowerCase()];
  }

  // process language
  if (
    window.globalObject && window.globalObject.objDaysofWeek
&& typeof window.globalObject.objDaysofWeek[term] !== 'undefined'
  ) {
    localTerm = window.globalObject.objDaysofWeek[term];
  } else if (templateTerm) {
    localTerm = templateTerm;
  } else if (dictionary.common[term]) {
    localTerm = dictionary.common[term][languageWithoutExtension];
  } else {
    // if no translation then return english term
    localTerm = term;
  }

  if (
    localTerm.indexOf('99PtagP') !== -1
|| localTerm.indexOf('99PtagB') !== -1
  ) {
    if (languageWithoutExtension !== 'en') {
      // if not english remove tags
      regexLookupRTags = new RegExp('99Ptag[BA](End)?', 'g');

      localTerm = localTerm.replace(regexLookupRTags, '');

      localTerm = localTerm.replace(/99PtagPEnd/g, '</p>');
      localTerm = localTerm.replace(/99PtagP/g, '<p>');
    } else {
      // replace html tags with 99P terms
      regexLookupRTags = new RegExp(' 99Ptag[PBA](End)? ', 'g');

      tagObj = {
        ' 99PtagP ': '<p>',
        ' 99PtagPEnd ': '</p>',
        ' 99PtagB ': ' <b>',
        ' 99PtagBEnd ': '</b> ',
        ' 99PtagA ': '<a>',
        ' 99PtagAEnd ': '</a>',
      };

      localTerm = localTerm.replace(regexLookupRTags, (a) => tagObj[a]);
    }
  }

  return localTerm;
}
