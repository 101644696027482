import { Backdrop, Container } from './index.styles';

export const Wrapper = ({ children }: Component) => {
  return (
    <>
      <Container>{children}</Container>
      <Backdrop />
    </>
  );
};
