import styled, { css } from 'styled-components';
import { HeadingProps } from '.';

export const Container = styled.div<HeadingProps>`
  ${({
    theme: { colors, fontSizes, fontWeights },
    align = 'left',
    color = 'brandDark',
    variation = 'layout-title_28',
    weight = 'medium',
    maxLines,
  }) => css`
    color: ${colors[color]};
    font-weight: ${fontWeights[weight]};
    margin: 0;
    text-align: ${align};

    em,
    i {
      ${variation.match(/^layout+/)
        ? css`
            color: ${colors.brandAccent};
            font-family: 'IBM Plex Serif', serif;
            font-weight: ${fontWeights.regular};
          `
        : css`
            color: ${colors[color]};
            font-family: inherit;
            font-style: normal;
            font-weight: inherit;
          `}
    }

    ${maxLines &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${maxLines};
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

    ${variation === 'layout-title_54' &&
    css`
      font-size: ${fontSizes.s54};
      letter-spacing: -0.025em;
      line-height: 1.185em;
    `}

    ${variation === 'layout-title_42' &&
    css`
      font-size: ${fontSizes.s42};
      letter-spacing: -0.025em;
      line-height: 1.2em;
    `}

    ${variation === 'layout-title_32' &&
    css`
      font-size: ${fontSizes.s32};
      letter-spacing: -0.025em;
      line-height: 1.25em;
    `}

    ${variation === 'layout-title_28' &&
    css`
      font-size: ${fontSizes.s28};
      letter-spacing: -0.025em;
      line-height: 1.285em;
    `}

    ${variation === 'component-title_20' &&
    css`
      font-size: ${fontSizes.s20};
      line-height: 1.4em;
    `}

    ${variation === 'component-title_16' &&
    css`
      font-size: ${fontSizes.s16};
      line-height: 1.375em;
    `}

    ${variation === 'component-title_14' &&
    css`
      font-size: ${fontSizes.s14};
      letter-spacing: 0.005em;
      line-height: 1.285em;
    `}

    ${variation === 'list-title_12' &&
    css`
      font-size: ${fontSizes.s12};
      font-weight: ${fontWeights.regular};
      letter-spacing: 0.085em;
      line-height: 1em;
      text-transform: uppercase;
    `}
  `}
`;
