import { Box } from '@sendible/design-system/src';
import styled from 'styled-components';

export const TopPostsOrderContainer = styled.div``;

export const TopPostsRowContainer = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
`;

export const Table = styled.table`
  width: 100%;

  th {
    font-weight: normal;
    text-align: left;
  }

  th:first-child {
    width: 80%;
  }

  tr,
  thead {
    border-bottom: 0.063rem dashed #95a7b8;
  }

  th,
  td {
    padding: 1.25rem 0;
  }
`;

export const TopPostsInsightColumnContainer = styled(Box)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 6.25rem;
`;

export const TopPostMessageContainer = styled.div`
  margin-left: 3.75rem;
  display: flex;

  img {
    margin-right: 1rem;
    max-height: 8.125rem;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const ProfilePicture = styled(Box)`
  border-radius: 6.25rem;
  overflow: hidden;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  margin-right: 0.5rem;
`;

export const ProfileUserName = styled.div`
  display: inline-block;
  font-weight: 700;
  color: #000000;
  font-size: 0.875rem;
  line-height: 0.875rem;
  margin-top: auto;
  margin-bottom: auto;

  p {
    margin-top: 0.5rem;
    font-weight: normal;
  }
`;

export const PostEngagementContainer = styled(Box)`
  width: 100%;
  padding: 1.875rem 1.875rem 2.5rem 1.875rem;
  border-radius: 0.375rem;
  background-color: #ffffff;
  gap: 1rem;
`;

export const EmptyResults = styled(Box)`
  width: 100%;
  justify-content: center;
`;
