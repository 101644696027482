import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Display = styled.div<{ $isCollapsed: boolean }>`
  ${({ theme: { colors }, $isCollapsed }) => css`
    align-items: center;
    border: 1px solid ${$isCollapsed ? colors.brandPrimary : colors.brandDarkTint80};
    border-radius: 0.25rem;
    color: ${colors.brandDark};
    cursor: pointer;
    display: flex;
    font-size: 0.8rem;
    gap: 0.33rem;
    padding: 0.25rem 0.5rem;

    span {
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      max-width: 12rem;
      text-align: left;
      text-overflow: ellipsis;
    }

    .icon {
      ${$isCollapsed &&
      css`
        transform: scaleY(-1);
      `}
    }

    * {
      user-select: none;
    }
  `}
`;

export const List = styled.div`
  ${({ theme: { colors } }) => css`
    background: ${colors.brandLight};
    border-radius: 0.25rem;
    box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.2);
    margin: 0;
    width: max-content;

    ul {
      display: flex;
      flex-direction: column;
      margin: 0;
      max-height: 12.5rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: white;
      }

      &::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: ${colors.brandDarkTint80};
        border-radius: 8px;
        height: 8px;
        -webkit-border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: ${colors.brandDarkTint70};
      }
    }
  `}
`;

export const InputArea = styled.div<{ $hasError: boolean }>`
  ${({ theme: { colors }, $hasError }) => css`
    border-bottom: 1px solid ${colors.brandDarkTint80};
    padding: 0.5rem;

    .input {
      max-width: -webkit-fill-available;

      input {
        box-sizing: border-box;
        margin: 0;
      }
    }

    ${$hasError &&
    css`
      .input {
        label {
          outline-color: ${colors.semanticDanger} !important;
        }
      }
    `}
  `}
`;

export const ListItem = styled.li<{ $selected: boolean }>`
  ${({ theme: { colors }, $selected }) => css`
    cursor: pointer;
    display: block;
    font-size: 0.875rem;
    padding: 0.8rem 1.5rem;
    line-height: 1em;

    ${$selected &&
    css`
      color: ${colors.brandPrimary};
    `}

    &:hover {
      background: ${colors.brandDarkAlpha5};
      color: ${colors.brandPrimary};
    }

    &:focus-within,
    &:focus {
      outline-color: ${colors.brandPrimaryAlpha50};
    }
  `}
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0.5rem 0;
`;
