import styled, { css } from 'styled-components';

export const ContainerBox = styled.div`
  ${({ theme: { borderRadius } }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    max-height: 22.5rem;
    height: auto;
    border-radius: ${borderRadius.sm};
    max-width: 22.5rem;
    z-index: 1042;
    position: fixed;
    bottom: 1.5em;
    right: 1.5em;
    background-color: white;
    box-shadow: 0px 1px 18px rgba(32, 30, 36, 0.12), 0px 6px 10px rgba(32, 30, 36, 0.14), 0px 3px 5px -1px rgba(32, 30, 36, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  `}
`;

export const Body = styled.div<{ hasSubHeader: boolean }>`
  ${({ hasSubHeader, theme: { colors } }) =>
    css`
      display: flex;
      flex-flow: row wrap;
      flex-grow: 1;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      // height of the element: calc(50vh - 3rem); minus height of the header
      // max-height: calc(50vh - ${hasSubHeader ? '10rem' : '7.857rem'});
      ul {
        list-style-type: none;
        flex-grow: 1;
        margin: 0;
        width: 22.5rem;
        overflow: hidden;
        box-sizing: border-box;
      }
      ::-webkit-scrollbar {
        height: 8px;
        width: 8px;
      }

      ::-webkit-scrollbar-track {
        background: white;
      }

      ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: ${colors.brandDarkAlpha20};
        border-radius: 8px;
        height: 8px;
        -webkit-border-radius: 8px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #bbb;
      }
    `}
`;
