import styled, { css } from 'styled-components';
import { ToggleProps } from '.';

interface ContainerProps {
  $isDisabled: boolean;
  $size: ToggleProps['size'];
}

export const Container = styled.label<ContainerProps>`
  ${({ theme: { colors, fontSizes, motions, motionSpeeds }, $isDisabled, $size }) => css`
    align-items: center;
    color: ${colors.brandDark};
    cursor: pointer;
    display: flex;
    gap: 1em;
    user-select: none;

    * {
      cursor: pointer;
      font-size: inherit;
    }

    ${$size === 'sm' &&
    css`
      font-size: ${fontSizes.s12};
    `}

    ${$size === 'md' &&
    css`
      font-size: ${fontSizes.s16};
    `}

    ${$size === 'lg' &&
    css`
      font-size: ${fontSizes.s20};
    `}

    ${$isDisabled &&
    css`
      cursor: default !important;

      * {
        cursor: default !important;
      }
    `}

    input {
      appearance: none;
      align-items: center;
      background: ${colors.brandLight};
      box-shadow: inset 0 0 0 1px ${colors.brandDarkAlpha60};
      border-radius: 5rem;
      display: flex;
      flex-shrink: 0;
      height: 2em;
      justify-content: center;
      margin: 0;
      outline-offset: 0px;
      outline: 3px solid transparent;
      width: 3.334em;

      &::before {
        content: '';
        border-radius: 5rem;
        background: ${colors.brandDarkAlpha60};
        box-shadow: 0 0 0 1px ${colors.brandDarkAlpha60};
        height: 1.334em;
        transform: translateX(-0.66em);
        transition: all ${motionSpeeds.fast} ${motions.default};
        width: 1.334em;
      }

      &:checked {
        background: ${colors.semanticSuccess};

        &::before {
          background: ${colors.brandLight};
          box-shadow: 0 0 0 1px ${colors.brandDarkAlpha60};
          transform: translateX(0.66em);
        }
      }
    }

    &:focus-within {
      input {
        box-shadow: 0 0 0 1px ${colors.brandPrimary};
        outline-color: ${colors.brandPrimaryAlpha20};
      }
    }
  `}
`;
