import styled, { css } from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  height: 34px;
  margin-top: -34px;
  position: fixed;
  width: 34px;
  z-index: 1;

  * {
    box-sizing: border-box;
  }
`;

export const List = styled.ul`
  ${({ theme: { colors } }) => css`
    background: ${colors.brandLight};
    box-shadow: 0 0 0 1px ${colors.brandDarkTint80}, 0 0.75rem 0.5rem -0.25rem ${colors.brandDarkAlpha15},
      0 0.25rem 0.25rem -0.125rem ${colors.brandDarkAlpha20};
    border-radius: 0.275rem;
    margin: 0;
    padding: 0.5rem 0.5rem 0.5rem;
    width: max-content;
  `}
`;

export const Spacer = styled.hr`
  ${({ theme: { colors } }) => css`
    background-color: ${colors.brandDarkTint80};
    border: 0;
    display: block;
    height: 1px;
    margin: 0.5rem 0 0.5rem -0.5rem;
    width: calc(100% + 1rem);
  `}
`;

export const HiddenInput = styled.input`
  display: none;
`;
