import React, { useEffect, useState, MouseEvent } from 'react';
import { EVENTS, publish, subscribe } from '@sendible/event-manager';
import { ToastNotificationStyles } from './index.styles';

export enum LegacyStyledNotificationTypeEnum {
  Error = 'error',
  Info = 'info',
  Success = 'success',
}

type OptionsType = {
  timeoutSeconds?: number;
  showIndefinetly?: boolean;
  type?: LegacyStyledNotificationTypeEnum;
};

const DEFAULT_TIMEOUT_SECONDS = 11;

export function showLegacyStyledToast(newNotificationText: string | JSX.Element, options: OptionsType = {}) {
  publish(EVENTS.LEGACY_STYLED_TOAST_NOTIFICATION_SHOW, {
    newNotificationText,
    options,
  });
}

let currentHideTimeout: NodeJS.Timeout;

export const LegacyStyledToastNotification = () => {
  const [notificationText, setNotificationText] = useState('');
  const [show, setShow] = useState(false);
  const [notificationTypeState, setNotificationType] = useState<LegacyStyledNotificationTypeEnum | undefined>();

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setShow(false);
  };

  useEffect(() => {
    const unsubscribe = subscribe(
      EVENTS.LEGACY_STYLED_TOAST_NOTIFICATION_SHOW,
      ({ newNotificationText, options: { timeoutSeconds = DEFAULT_TIMEOUT_SECONDS, showIndefinetly = false, type = 'alert' } }) => {
        const timeoutMs = timeoutSeconds * 1000;

        clearTimeout(currentHideTimeout);

        setNotificationText(newNotificationText);
        setNotificationType(type);
        setShow(true);

        if (!showIndefinetly) {
          const hideTimeout = setTimeout(() => {
            setShow(false);
          }, timeoutMs);

          currentHideTimeout = hideTimeout;
        }
      }
    );

    return () => unsubscribe();
  }, []);

  if (!show) {
    return null;
  }

  return (
    <ToastNotificationStyles
      type={notificationTypeState}
      data-testid={`toast-${notificationTypeState}`}
    >
      <div>{notificationText}</div>
      <button
        className="close"
        data-testid="toast-notification-close"
        onClick={handleClose}
        type="button"
      >
        ×
      </button>
    </ToastNotificationStyles>
  );
};
