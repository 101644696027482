export const ruler = () => (
  <>
    <g clipPath="url(#prefix__clip0_904_968)">
      <path
        d="M7.757 19.071l1.415 1.414m4.242-7.07l1.414 1.413m4.243-7.07l1.414 1.414M9.172 14.828L12 17.657m2.828-8.485L17.657 12m1.414-9.9L21.9 4.93c.781.78.655 2.174-.282 3.111L8.04 21.617c-.937.937-2.33 1.063-3.111.282L2.1 19.071c-.782-.781-.655-2.174.282-3.111L15.96 2.383c.937-.937 2.33-1.064 3.111-.283z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_904_968">
        <path
          fill="inherit"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </>
);
