import { Icon, Heading, Text, Button } from '@sendible/design-system/src';
import { useTranslation } from 'react-i18next';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { ErrorBoundaryStyled } from './index.styles';

type Props = {
  errorMessage: string;
  translationNamespace: string;
};
export const ErrorState = ({ errorMessage, translationNamespace }: Props) => {
  const { t } = useTranslation(['common_terms', translationNamespace]);

  const [
    {
      user: { isWhiteLabel },
    },
  ] = useBridgeContext();
  let supportMessage;

  if (isWhiteLabel) {
    supportMessage = (
      <>
        {t('error_if_persists_wl')}
        <br /> <br />
        <Button
          variation="fill"
          appearance="primary"
          onClick={() => window.location.reload()}
          label={t('try_again_button') || ''}
        />
      </>
    );
  } else {
    supportMessage = (
      <>
        {t('error_if_persists')}{' '}
        <a
          href="https://support.sendible.com/hc/en-us/requests/new"
          target="_blank"
          rel="noreferrer"
        >
          {t('contact_support')}
        </a>
        <br /> <br />
        <Button
          variation="fill"
          appearance="primary"
          onClick={() => window.location.reload()}
          label={t('try_again_button') || ''}
        />
      </>
    );
  }

  return (
    <ErrorBoundaryStyled
      contentAlignment="stretch"
      contentJustify="center"
      orientation="column"
      verticalGap="s16"
      data-testid="error-message"
    >
      <Icon
        className="error_boundary-icon-danger"
        color="brandDarkAlpha50"
        name="danger"
      />
      <Heading
        align="left"
        color="brandDark"
        semantic="h2"
        variation="layout-title_28"
      >
        {t(`${translationNamespace}:error_message`)}
      </Heading>
      <Text
        align="left"
        color="brandDark"
        variation="body_14"
      >
        {supportMessage}
      </Text>
      {errorMessage && (
        <Text
          className="error_boundary-error-text"
          color="brandDarkAlpha70"
          variation="body_12"
        >
          {t('error_type')}: {errorMessage}
        </Text>
      )}
    </ErrorBoundaryStyled>
  );
};
