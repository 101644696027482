import styled, { css } from 'styled-components';

export const DateButtonStyled = styled.button<{ $isCurrentDay?: boolean; $isInteractive?: boolean; $isDateInbetween?: boolean }>`
  ${({ theme: { colors }, $isCurrentDay, $isInteractive, $isDateInbetween, disabled }) => css`
    all: unset;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 5rem;
    display: flex;
    height: calc(2.5rem - 2px);
    justify-content: center;
    width: calc(2.5rem - 2px);
    ${disabled &&
    css`
      color: ${colors.brandDarkAlpha50};
    `}
    ${!$isInteractive &&
    css`
      pointer-events: none;
    `}

    ${$isInteractive &&
    css`
      cursor: pointer;
      pointer-events: all;

      &:disabled {
        cursor: not-allowed;
      }

      &:focus-visible {
        border-color: ${colors.brandLight};
        box-shadow: 0 0 0 2px ${colors.brandPrimary};
      }

      &:hover:not([disabled]) {
        background: ${colors.brandDarkAlpha5};
      }
    `}

    ${$isCurrentDay &&
    css`
      box-shadow: 0 0 0 2px ${colors.brandPrimary};
      color: ${colors.brandDark};
    `}

    ${$isDateInbetween &&
    css`
      background: ${colors.brandDarkAlpha5};
    `}
  `}
`;
