import { Box } from '@sendible/design-system';
import styled from 'styled-components';

export const Message = styled(Box)`
  svg {
    flex-shrink: 0;
    height: 3rem;
    margin-bottom: 1rem;
    width: 3rem;
  }
  p {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`;
