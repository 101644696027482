import * as featureCodes from '@sendible/sendible360/src/assets/js/views/common/FeatureCodeConstants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EVENTS, publish } from '@sendible/event-manager';
import { useLocation } from 'react-router-dom';
import { useReportsModel } from '@sendible/frontendv2/src/models/reports';
import { pages } from '@sendible/frontendv2/src/routes';
import { ReportLinks } from './ReportsLinks';

export type ListConfigType = {
  href: string;
  reportName: string | null;
  icon: string;
  title: string;
}[];

type ReportsSidebarProps = {
  responsiveIsOpen: boolean;
};

type ReportsDataType = { account_type: string | null; id: number };
export type ReportsDataArrayType = ReportsDataType[] | undefined;

export const handleLinkClick = (event: React.MouseEvent<HTMLElement>, link: string) => {
  event.preventDefault();
  publish(EVENTS.CHANGE_PAGE, link);
};

export const ReportsSidebar = ({ responsiveIsOpen }: ReportsSidebarProps) => {
  const { t } = useTranslation('reports');
  const { quickReports } = useReportsModel();
  const [reportsData, setReportsData] = useState<ReportsDataArrayType>();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Utils.setActiveView('MeasureView');

    const fetchCampaigns = async () => {
      const result = await quickReports();

      setReportsData(result);
    };

    fetchCampaigns();
  }, []);

  const reportSidebarTitle = t('report_sidebar_title');
  const reportEngagementLink = t('report_engagement_sidebar_link');
  const facebookSidebarLink = t('facebook_sidebar_link');
  const instagramSidebarLink = t('instagram_sidebar_link');
  const linkedinSidebarLink = t('linkedin_sidebar_link');
  const youtubeSidebarLink = t('youtube_sidebar_link');
  const googleAnalyticsSidebarLink = t('google_analytics_sidebar_link');
  const campaignSidebarLink = t('report_campaign_sidebar_link');
  const myReportsSidebarLink = t('my_reports_sidebar_link');
  const reportBuilderSidebarLink = t('report_builder_sidebar_link');
  const downloadDataSidebarLink = t('download_data_analytics_sidebar_link');

  const listConfig: ListConfigType = [
    {
      href: '#measure/quick-reports/',
      reportName: null,
      icon: 'icon-magnet',
      title: reportEngagementLink,
    },
    {
      href: '#measure/quick-reports/',
      reportName: 'Facebook Fan Page',
      icon: 'icon-facebook',
      title: facebookSidebarLink,
    },
    {
      href: '#measure/quick-reports/',
      reportName: 'Instagram',
      icon: 'icon-instagram',
      title: instagramSidebarLink,
    },
    {
      href: '#measure/quick-reports/',
      reportName: 'LinkedIn Company Page',
      icon: 'icon-linkedin',
      title: linkedinSidebarLink,
    },
    {
      href: '#measure/quick-reports/',
      reportName: 'YouTube',
      icon: '',
      title: youtubeSidebarLink,
    },
    {
      href: '#measure/quick-reports/',
      reportName: '_GA',
      icon: 'icon-stats-dots',
      title: googleAnalyticsSidebarLink,
    },
  ];

  return (
    <div
      id="left-sidebar"
      className={`well sidebar-nav equal-height fev2-sidebar ${responsiveIsOpen ? 'open' : ''}`}
    >
      <ul className="nav nav-list persist-active">
        <li className="nav-header">
          <a
            href="#measure"
            onClick={(event) => {
              handleLinkClick(event, '#measure');
            }}
          >
            {reportSidebarTitle}
          </a>
        </li>

        {ReportLinks(listConfig, reportsData)}

        <li className={pathname.includes('campaigns') ? 'active' : ''}>
          <a
            href="/measure"
            onClick={(event) => {
              handleLinkClick(event, pages.Measure.campaigns);
            }}
          >
            <svg className="icon sds-icon">
              <use xlinkHref="#svg-icon-megaphone" />
            </svg>
            {campaignSidebarLink}
          </a>
        </li>

        {(window.js_featurMngr.isFeatureTriggerVisible(featureCodes.MEASURE_REPORTBUILDER) ||
          window.js_featurMngr.isFeatureTriggerVisible(featureCodes.MEASURE_EXPORTCSV)) && <li className="hr" />}
        {window.js_featurMngr.isFeatureTriggerVisible(featureCodes.MEASURE_REPORTBUILDER) && (
          <>
            <li>
              <a
                href=""
                onClick={(event) => {
                  handleLinkClick(event, '#measure/my-reports');
                }}
                id="auto_my-reports"
                className="feature-check my-reports-link"
                data-feature-code={featureCodes.MEASURE_REPORTBUILDER}
              >
                <span className="icon icon-folder-open" />
                {myReportsSidebarLink}
                <span className="badge badge-info myreports-count fade" />
              </a>
            </li>
            <li>
              <a
                href=""
                onClick={(event) => {
                  handleLinkClick(event, '#measure/builder/new');
                }}
                id="auto_builder-new"
                className="feature-check"
                data-feature-code={featureCodes.MEASURE_REPORTBUILDER}
              >
                <span className="icon icon-wrench2" />
                {reportBuilderSidebarLink}
              </a>
            </li>
          </>
        )}
        {window.js_featurMngr.isFeatureTriggerVisible(featureCodes.MEASURE_EXPORTCSV) && (
          <li>
            <a
              href=""
              onClick={(event) => {
                handleLinkClick(event, '#measure/export');
              }}
              id="auto_export"
              className="feature-check"
              data-feature-code="<%=js_featurMngr.codes.MEASURE_EXPORTCSV%>"
            >
              <span className="icon icon-cloud-download" />
              {downloadDataSidebarLink}
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};
