import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal, Text, useKeyDown } from '@sendible/design-system';

import { useDeleteMedia } from '@sendible/frontendv2/src/models/medias';
import { MediaToDelete, useMediaLibraryContext } from '../../context';
import { mediaDeletedFromGridView, mediaDeletedFromPreview } from '../../pendoEvents';

interface DeletionModalProps {
  media: MediaType;
  pendoEvent: MediaToDelete['source'];
}

export const DeleteMediaModal = ({ media, pendoEvent }: DeletionModalProps) => {
  const {
    id,
    metadata: { mediaType },
  } = media;
  const [isDeleting, setIsDeleting] = useState(false);
  const { setMediaToDelete } = useMediaLibraryContext();
  const deleteMediaMutation = useDeleteMedia(pendoEvent === 'content' ? mediaDeletedFromGridView : mediaDeletedFromPreview, mediaType);
  const { t } = useTranslation('media_library');

  const closeButtonLabel = t('deletion_modal_button_back');
  const deleteButtonLabel = t('dropdown_delete');
  const modalTitle = t('delete_media_modal_title');

  useEffect(() => {
    return () => setMediaToDelete(undefined);
  }, []);

  useKeyDown(() => setMediaToDelete(undefined), 'Escape');

  const handleMediaLibraryDeletion = () => {
    setIsDeleting(true);

    deleteMediaMutation.mutate(
      { mediaId: id },
      {
        onSettled: () => {
          setIsDeleting(false);
          setMediaToDelete(undefined);
        },
      }
    );
  };

  return (
    <Modal
      close={() => setMediaToDelete(undefined)}
      headingTitle={modalTitle}
      variation="small"
    >
      <Box>
        <Text>{t('deletion_media_modal_text')}</Text>
        <Box
          contentAlignment="stretch"
          orientation="column"
          verticalGap="s8"
          flex={1}
          margin={['s24', 's0', 's0', 's0']}
        >
          <Button
            onClick={() => setMediaToDelete(undefined)}
            label={closeButtonLabel}
            size={16}
            data-testid="modal-button-back"
            variation="outline"
            autoFocus
          />
          <Button
            appearance="primary"
            onClick={() => handleMediaLibraryDeletion()}
            label={deleteButtonLabel}
            isLoading={isDeleting}
            size={16}
            data-testid="modal-button-delete"
            variation="fill"
          />
        </Box>
      </Box>
    </Modal>
  );
};
