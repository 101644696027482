import styled, { css } from 'styled-components';
import { BoxProps, overflows } from './index';

interface TransientProps {
  $overflow?: (typeof overflows)[number];
}

export const Container = styled.div<Omit<BoxProps, 'overflow' | 'semantic'> & TransientProps>`
  ${({
    theme: { colors, spacings },
    alignSelf = 'auto',
    contentAlignment = 'flex-start',
    contentJustify = 'flex-start',
    flex,
    fullWidth,
    horizontalGap = 's0',
    margin = ['s0', 's0', 's0', 's0'],
    orientation = 'row',
    $overflow = 'wrap',
    padding = ['s0', 's0', 's0', 's0'],
    verticalGap = 's0',
  }) => css`
    align-self: ${alignSelf};
    align-items: ${contentAlignment};
    display: flex;
    flex: ${flex || 'unset'};
    flex-direction: ${orientation};
    gap: ${spacings[verticalGap]} ${spacings[horizontalGap]};
    justify-content: ${contentJustify};
    margin: ${spacings[margin[0]]} ${spacings[margin[1]]} ${spacings[margin[2]]} ${spacings[margin[3]]};
    padding: ${spacings[padding[0]]} ${spacings[padding[1]]} ${spacings[padding[2]]} ${spacings[padding[3]]};
    scrollbar-width: thin;
    scrollbar-color: ${colors.brandDarkAlpha20} transparent;
    ${fullWidth &&
    css`
      width: 100%;
    `}
    ${$overflow === 'wrap' &&
    css`
      flex-wrap: wrap;
    `}

    ${$overflow === 'nowrap' &&
    css`
      flex-wrap: nowrap;
    `}

    ${orientation === 'row' &&
    css`
      ${$overflow === 'scroll' &&
      css`
        overflow-x: auto;
      `}
    `}

    ${orientation === 'column' &&
    css`
      ${$overflow === 'scroll' &&
      css`
        overflow-y: auto;
      `}
    `}

    &::-webkit-scrollbar {
      height: 0.375rem;
      width: 0.375rem;
    }

    &::-webkit-scrollbar-thumb {
      background: ${colors.brandDarkAlpha20};
      border-radius: 7em;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  `}
`;
