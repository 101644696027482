export const facebook_color = () => (
  <>
    <circle
      cx="12"
      cy="12"
      r="11"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 12C23 5.92486 18.0751 1 12 1C5.92486 1 1 5.92486 1 12C1 17.4903 5.02252 22.0412 10.2813 22.8664V15.1797H7.48828V12H10.2813V9.57656C10.2813 6.81969 11.9235 5.29688 14.4361 5.29688C15.6392 5.29688 16.8984 5.51172 16.8984 5.51172V8.21875H15.5114C14.145 8.21875 13.7188 9.06674 13.7188 9.9375V12H16.7695L16.2818 15.1797H13.7188V22.8664C18.9775 22.0412 23 17.4903 23 12Z"
      fill="#1877F2"
    />
  </>
);
