import { Icon } from '@sendible/design-system/src';
import { useTranslation } from 'react-i18next';
import { Container } from './index.styles';

export const SupportedMedias = () => {
  const { t } = useTranslation('common_terms');

  return (
    <Container>
      <p>{t('only_supported_by')}</p>
      <Icon
        name="facebook"
        useStroke={false}
      />
      <Icon
        name="instagram"
        useStroke={false}
      />
      <Icon
        name="linkedin"
        useStroke={false}
      />
    </Container>
  );
};
