export const play_circle = () => (
  <>
    <path
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 8l6 4-6 4V8z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
