import { Box } from '@sendible/design-system/src';
import styled from 'styled-components';

export const PostImageContainer = styled.div`
  img {
    width: 17.75rem;
    max-height: 17.75rem;
  }
`;

export const PostItemMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 17.75rem;
  min-height: 8rem;
  height: 100%;
  box-shadow: 0 0 0 0.063rem rgba(50, 77, 143, 0.2);
  border-radius: 0.375rem;
  padding: 1rem 0 1rem 0;
`;

export const ProfilePicture = styled(Box)`
  border-radius: 6.25rem;
  overflow: hidden;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  margin-right: 0.5rem;
`;

export const ProfileUserName = styled.div`
  display: inline-block;
  font-weight: 700;
  color: #000000;
  font-size: 0.875rem;
  line-height: 0.875rem;
  margin-top: auto;
  margin-bottom: auto;

  p {
    margin-top: 0.5rem;
    font-weight: normal;
  }
`;

export const PostFooter = styled(Box)`
  flex-direction: row;
  margin: 0 0.75rem 0 0.75rem;
  justify-content: space-between;
`;

export const PostFooterItem = styled(Box)`
  display: flex;
  gap: 0.5rem;
`;

export const PostEngagementContainer = styled(Box)`
  flex-direction: column;
  width: 100%;
  padding: 1.875rem 1.875rem 2.5rem 1.875rem;
  border-radius: 0.375rem;
  background-color: #ffffff;
  gap: 1rem;
`;

export const EmptyResults = styled(Box)`
  width: 100%;
  justify-content: center;
`;

export const PostEngagementDataContainer = styled(Box)`
  flex-direction: row;
  width: 100%;
  gap: 2rem;
`;
