export const corner_left_up = () => (
  <>
    <path
      d="M14 9L9 4 4 9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 20h-7a4 4 0 01-4-4V4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
