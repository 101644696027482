type TimeRemaining = {
  time: number;
  units: string;
};

function getRelativeTime(language: string, isoTimeStamp: string, ignoreFormatter?: boolean): any {
  const date = new Date(isoTimeStamp);
  const baseNow = new Date();
  const nowOffset = baseNow.getTimezoneOffset() * 60000;
  const dateNow = new Date(baseNow.getTime() + nowOffset);

  const diffInMilliseconds = dateNow.getTime() - date.getTime();
  const millisecondsInASecond = 1000;
  const millisecondsInAMinute = millisecondsInASecond * 60;
  const millisecondsInAnHour = millisecondsInAMinute * 60;
  const millisecondsInADay = millisecondsInAnHour * 24;
  const millisecondsInAYear = millisecondsInADay * 365;
  const millisecondsInAMonth = millisecondsInADay * 30;
  const millisecondsInAWeek = millisecondsInADay * 7;
  let humanTime;
  let units: Intl.RelativeTimeFormatUnit;

  // If there are years
  if (diffInMilliseconds > millisecondsInAYear) {
    humanTime = diffInMilliseconds / millisecondsInAYear;
    units = 'years';
    // If there are months
  } else if (diffInMilliseconds > millisecondsInAMonth) {
    humanTime = diffInMilliseconds / millisecondsInAMonth;
    units = 'months';
    // If there are weeks
  } else if (diffInMilliseconds > millisecondsInAWeek) {
    humanTime = diffInMilliseconds / millisecondsInAWeek;
    units = 'weeks';
    // If there are days
  } else if (diffInMilliseconds > millisecondsInADay) {
    humanTime = diffInMilliseconds / millisecondsInADay;
    units = 'days';
    // If there are hours
  } else if (diffInMilliseconds > millisecondsInAnHour) {
    humanTime = diffInMilliseconds / millisecondsInAnHour;
    units = 'hours';
    // If there are minutes
  } else if (diffInMilliseconds > millisecondsInAMinute) {
    humanTime = diffInMilliseconds / millisecondsInAMinute;
    units = 'minutes';
    // Otherwise, use seconds
  } else {
    humanTime = diffInMilliseconds / millisecondsInASecond;
    units = 'seconds';
  }

  if (ignoreFormatter) {
    return {
      time: -Math.round(humanTime),
      units,
    } as TimeRemaining;
  }

  const relativeTimeFormatter = new Intl.RelativeTimeFormat(language);

  if (typeof ignoreFormatter === 'undefined') {
    return relativeTimeFormatter.format(-Math.round(humanTime), units) as string;
  }
}

export default getRelativeTime;
