import { Fragment, useEffect, useRef, useState } from 'react';
import { useFloating, offset, flip, shift, autoUpdate } from '@floating-ui/react';
import { Button, Icon, Input, helpers } from '@sendible/design-system';
import { useInView } from 'react-intersection-observer';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Container, Display, Footer, InputArea, List, ListItem } from './index.styles';

export interface Option {
  label: string;
  value: string;
}

interface Props {
  id: string;
  options: Option[];
  onEnter: (option: string) => void;
  onSelect: (option: string) => void;
  selectedOption?: string;
  testid?: string;
  title: string;
  showInput?: boolean;
}

export const SelectWithInput = ({ id, onEnter, onSelect, options, selectedOption, testid, title, showInput = false }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [hasError, setHasError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLLIElement | null>(null);
  const { t } = useTranslation('ai_assist');

  const clearButtonLabel = t('dropdown_clear_button');
  const errorFeedback = t('audience_input_error');

  const { ref: inViewRef, inView } = useInView({
    threshold: 1,
    rootMargin: '-8px',
  });

  const { x, y, strategy, refs } = useFloating({
    open: isCollapsed,
    onOpenChange: setIsCollapsed,
    placement: 'bottom-start',
    middleware: [offset(8), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const handleEnter = (value: string) => {
    const replacedValue = value.replace(/\s\s+/g, ' ').trim();

    if (replacedValue.length < 3) setHasError(true);
    else {
      onEnter(replacedValue);
      setIsCollapsed(false);
    }
  };

  const handleKeyDown = (key: string, index: number, listLength: number, value: string) => {
    if (key === 'ArrowUp' && index > 0) document.activeElement.previousSibling.focus();
    else if (key === 'ArrowDown' && index < listLength - 1) document.activeElement.nextSibling.focus();
    else if (key === 'Enter') handleEnter(value);
  };

  const handleSelect = (value: string) => {
    onSelect(value);
    setIsCollapsed(false);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      if (!target.id.includes(id)) setIsCollapsed(false);
    };

    document.addEventListener('mouseup', handleClickOutside);

    return () => document.removeEventListener('mouseup', handleClickOutside);
  }, []);

  useEffect(() => {
    if (isCollapsed && !inView) setIsCollapsed(false);
  }, [inView]);

  useEffect(() => {
    if (isCollapsed) {
      setHasError(false);

      if (inputRef.current) inputRef.current.focus();
      else listRef.current?.focus();
    }
  }, [isCollapsed]);

  const optionList = options.map((option, index) => (
    <Fragment key={helpers.generateId()}>
      <ListItem
        id={`${id}-li-${option.value}`}
        onClick={() => handleSelect(option.value)}
        onKeyDown={(e) => handleKeyDown(e.key, index, optionList.length, option.value)}
        ref={(el) => {
          if (index === 0) listRef.current = el;
        }}
        role="menuitem"
        tabIndex={0}
        title={option.label}
        $selected={option.label === title}
      >
        {option.label}
      </ListItem>
    </Fragment>
  ));

  return (
    <Container
      data-testid={testid}
      id={id}
    >
      <Display
        $isCollapsed={isCollapsed}
        ref={refs.setReference}
        onClick={() => setIsCollapsed((prev) => !prev)}
      >
        <span>{selectedOption || title}</span>
        <Icon
          name="chevron_down"
          color="brandDark"
        />
      </Display>
      {isCollapsed &&
        createPortal(
          <List
            id={`${id}-list`}
            ref={refs.setFloating}
            style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
            tabIndex={-1}
          >
            <div
              className="dropdown-contentarea"
              id={`${id}-content`}
              tabIndex={-1}
            >
              {showInput && (
                <InputArea $hasError={hasError}>
                  <Input
                    label={t('audience_input')}
                    id={`${id}-input`}
                    keyPress={(key, value) => key === 'Enter' && handleEnter(value)}
                    maxLength={50}
                    ref={inputRef}
                    size="sm"
                    feedback={hasError ? { message: errorFeedback, type: 'danger' } : undefined}
                    feedbackDuration={2500}
                    onFeedbackDurationEnd={() => setHasError(false)}
                  />
                </InputArea>
              )}
              <ul
                aria-labelledby={`swf-${title}`}
                id={`${id}-ul`}
                role="menu"
                tabIndex={-1}
                ref={inViewRef}
              >
                {optionList}
              </ul>
              <Footer>
                <Button
                  appearance="primary"
                  id={`${id}-button`}
                  label={clearButtonLabel}
                  onClick={() => handleSelect('')}
                  size={12}
                />
              </Footer>
            </div>
          </List>,
          document.getElementById('dropdown-root') as HTMLElement
        )}
    </Container>
  );
};
