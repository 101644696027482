import styled, { css } from 'styled-components';
import { LightboxProps } from '.';

export const Container = styled.div<LightboxProps>`
  ${({ theme: { colors, motions, motionSpeeds }, darkmode }) => css`
    background: ${darkmode ? colors.brandDarkAlpha90 : colors.brandLightAlpha90};
    display: flex;
    height: 100%;
    left: 0;
    padding-top: 15px;
    position: fixed;
    top: 0;
    transition: all ${motionSpeeds.normal} ${motions.default};
    width: 100%;

    .close-button {
      left: 0.5rem;
      position: fixed;
      top: 0.5rem;
    }
  `}
`;
