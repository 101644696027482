import { useBridgeContext } from '@sendible/shared-state-bridge';
import { AnimatePresence } from 'framer-motion';
import { ContentSection, CreateMediaLibraryModal, DeleteMediaLibraryModal, DeleteMediaModal, Sidebar } from './components';
import { useMediaLibraryContext } from './context';
import ErrorBoundary from './errorBoundaries/errorBoundary';

export const MediaLibraryPage = () => {
  const { activeMediaLibrary, isCreationModalOpen, isDeleteModalOpen, isRenameModalOpen, mediaToDelete } = useMediaLibraryContext();
  const [
    {
      user: { isWhiteLabel },
    },
  ] = useBridgeContext();

  return (
    <ErrorBoundary
      isWhiteLabel={isWhiteLabel}
      errorSection="mediaLibrarySection"
    >
      <Sidebar />
      <ErrorBoundary
        isWhiteLabel={isWhiteLabel}
        errorSection="contentSection"
      >
        <AnimatePresence>{isCreationModalOpen && <CreateMediaLibraryModal />}</AnimatePresence>
        <AnimatePresence>{isDeleteModalOpen && <DeleteMediaLibraryModal mediaLibrary={activeMediaLibrary} />}</AnimatePresence>
        <AnimatePresence>{isRenameModalOpen && <CreateMediaLibraryModal mediaLibrary={activeMediaLibrary} />}</AnimatePresence>
        <AnimatePresence>
          {mediaToDelete && (
            <DeleteMediaModal
              media={mediaToDelete.media}
              pendoEvent={mediaToDelete.source}
            />
          )}
        </AnimatePresence>
        <ContentSection />
      </ErrorBoundary>
    </ErrorBoundary>
  );
};
