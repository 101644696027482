import { COMPOSE_CAMPAIGNS } from '@sendible/sendible360/src/assets/js/views/common/FeatureCodeConstants';
import { routeUtils } from '@sendible/common';

export type LinkType = {
  route: string;
  label: string;
  isBeta?: boolean;
  countLabel?: string;
  groupCountLabel?: string;
  count?: number | string;
  groupCount?: number | string;
  defaultCountValue?: number;
};

export type SideBarConfigurationType = {
  header: string;
  links: LinkType[];
};

export type RouteConfigurationType = {
  featureTag?: string;
  featureRestriction?: string;
  sidebar: SideBarConfigurationType;
};

export const pages = {
  Campaigns: {
    base: '/campaigns',
  },
  MediaLibrary: {
    base: '/medialibrary',
  },
  Measure: {
    base: '/measure',
    campaigns: '/measure/campaigns',
  },
} as const;

export type PageBaseKeys = {
  [K in keyof typeof pages]: (typeof pages)[K]['base'];
}[keyof typeof pages];

type routesConfig = {
  [routes in PageBaseKeys]: RouteConfigurationType;
};

export const routes: routesConfig = {
  [pages.Campaigns.base]: {
    featureRestriction: COMPOSE_CAMPAIGNS,
    sidebar: {
      header: 'header_word_publish',
      links: [
        { route: '/#publish/calendar', label: 'publish_sidebar_link_calendar', isBeta: false },
        { route: pages.Campaigns.base, label: 'publish_sidebar_link_campaigns', isBeta: false },
        {
          route: '/#publish/scheduled',
          label: 'publish_sidebar_link_scheduled',
          isBeta: false,
          countLabel: 'scheduledCount',
          groupCountLabel: 'scheduledTeamCount',
          defaultCountValue: 0,
        },
        { route: '/#publish/queued', label: 'publish_sidebar_link_queues', isBeta: false },
        { route: '/#publish/sent', label: 'publish_sidebar_link_sent', isBeta: false },
        {
          route: '/#publish/bounced',
          label: 'publish_sidebar_link_undelivered',
          isBeta: false,
          countLabel: 'bouncedCount',
          defaultCountValue: undefined,
        },
        { route: '/#publish/drafts', label: 'publish_sidebar_link_drafts', isBeta: false },
        { route: '/#publish/tasks/me', label: 'publish_sidebar_link_tasks', isBeta: false, countLabel: 'taskCount', defaultCountValue: 0 },
      ],
    },
  },
  [pages.MediaLibrary.base]: {
    featureTag: 'mediaLibrary',
    sidebar: {
      header: 'header_word_content',
      links: [
        { route: '/medialibrary', label: 'content_sidebar_media_library', isBeta: true },
        { route: '/#content/feeds', label: 'content_sidebar_feeds' },
        { route: '/#content', label: 'content_sidebar_content' },
      ],
    },
  },
  [pages.Measure.base]: {
    sidebar: {
      header: 'header_word_content',
      links: [],
    },
  },
};

export const isFEV2Route = (pathname: string) => {
  const frontendV2Routes = Object.keys(routes);

  const pathWihtoutSubPaths = routeUtils.getPathWithoutSubPaths(pathname);

  return frontendV2Routes.includes(pathWihtoutSubPaths);
};
