import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { DropdownProps } from './index';

export const Container = styled(motion.div)<{ $width: DropdownProps['width'] }>`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
    },
    $width = '17.5rem',
  }) => css`
    background: ${colors.brandLight};
    border-radius: 0.5rem;
    box-shadow: 0 1rem 3rem 0 ${colors.brandDarkAlpha15};
    width: ${$width};

    @media all and (${smallDevices.max}) {
      border-radius: 1rem 1rem 0 0;
      position: fixed !important;
      bottom: 0 !important;
      left: 0 !important;
      top: unset !important;
      right: unset !important;
      width: 100%;
    }
  `}
`;

export const Content = styled.ul`
  ${({
    theme: {
      breakPoints: { smallDevices },
    },
  }) => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    list-style: none;
    margin: 0;
    max-height: 20rem;
    overflow-y: auto;
    padding: 0;

    @media all and (${smallDevices.max}) {
      max-height: 80vh;
    }
  `}
`;

export const Header = styled.div`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
    },
  }) => css`
    @media all and (${smallDevices.max}) {
      background: ${colors.brandLight};
      border-radius: 1rem 1rem 0 0;
      display: flex;
      justify-content: flex-end;
      padding: 1rem 0.25rem 1rem 1rem;
    }
  `}
`;

export const ListItem = styled.li`
  ${({
    theme: {
      breakPoints: { smallDevices },
    },
  }) => css`
    display: flex;
    flex: 1;
  `}
`;

export const Action = styled.button`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
      fontSizes,
    },
  }) => css`
    align-items: center;
    background: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    flex: 1;
    font-size: 1rem;
    gap: 1rem;
    margin: 0;
    padding: 1rem;
    user-select: none;

    svg {
      height: 1.25rem;
      width: 1.25rem;
    }

    * {
      pointer-events: none;
    }

    :disabled {
      cursor: not-allowed;

      svg {
        filter: grayscale(1);
        opacity: 0.3;
      }
    }

    @media all and (${smallDevices.max}) {
      padding: 2rem;
      font-size: ${fontSizes.s16};
      height: 3.5rem;

      &:focus-visible {
        background: ${colors.brandPrimaryAlpha5};
        color: ${colors.brandPrimary};
        outline-color: transparent;
      }
    }
  `}
`;

export const Children = styled.div``;

export const Backdrop = styled(motion.div)`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
    },
  }) => css`
    @media all and (${smallDevices.max}) {
      background: ${colors.brandDarkAlpha90};
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
    }
  `}
`;

export const Spacer = styled.span`
  ${({ theme: { colors } }) => css`
    display: block;
    background: ${colors.brandDarkAlpha10};
    min-height: 1px;
    margin: 0.5rem 0;
    width: 100%;
  `}
`;
