import { Box } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme: { colors } }) => css`
    align-items: center;
    background: ${colors.brandLight};
    border-radius: 1.25rem;
    display: flex;
    gap: 1.5rem;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.2);

    .icon {
      flex-shrink: 0;
      height: 1.25rem;
      width: 1.25rem;
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
`;

export const Row = styled.div<{ $gap?: number }>`
  ${({ $gap = 1 }) => css`
    align-items: center;
    display: flex;
    gap: ${`${$gap}rem`};
    justify-content: space-between;
  `}
`;

export const Feedback = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;

  .text {
    font-size: 0.75rem;
  }

  .icon {
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export const ErrorContainer = styled(Box)`
  flex-wrap: nowrap;
`;
