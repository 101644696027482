import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@sendible/design-system';
import { Actions, Container, HeadingStyled, MinimiseButton } from './index.styles';
import { useBackgroundUploaderContext } from '../../context';

export const Header = () => {
  const { isMinimised, setIsMinimised, canBeClosed, closeUploader, itemCountByStatus } = useBackgroundUploaderContext();
  const { t } = useTranslation('uploader');

  const headerText = useMemo(() => {
    if (itemCountByStatus.aborted) {
      return {
        key: 'uploader_items_cancelled',
        data: { totalCanceledItems: itemCountByStatus.aborted },
      };
    }
    if (itemCountByStatus.errored) {
      return {
        key: 'uploader_items_failed',
        data: { totalFailedMedias: itemCountByStatus.errored },
      };
    }
    if (itemCountByStatus.ready && itemCountByStatus.uploading) {
      return {
        key: 'uploader_items_uploaded',
        data: {
          totalUploadingMedias: itemCountByStatus.uploading,
          totalMedias: itemCountByStatus.uploading + itemCountByStatus.ready,
        },
      };
    }
    if (itemCountByStatus.uploading) {
      return {
        key: 'uploader_items_uploading',
        data: { totalUploadingMedias: itemCountByStatus.uploading },
      };
    }
    if (itemCountByStatus.ready) {
      return {
        key: 'uploader_items_complete',
        data: {
          itemsCompleted: itemCountByStatus.ready,
          totalMedias: itemCountByStatus.uploading + itemCountByStatus.ready,
        },
      };
    }

    return { key: '', data: {} };
  }, [itemCountByStatus]);

  return (
    <Container>
      <Actions>
        {canBeClosed && (
          <Button
            data-testid="close-backgrounduploader-button"
            onClick={() => {
              closeUploader();
            }}
            size={16}
            icon="close"
          />
        )}

        <MinimiseButton
          data-testid="minimise-backgrounduploader-button"
          rotateIconDegrees={isMinimised ? 180 : 0}
          onClick={() => {
            setIsMinimised(!isMinimised);
          }}
          size={16}
          icon="minimise_alt"
        />
      </Actions>
      <HeadingStyled
        color="brandDark"
        semantic="h5"
        variation="component-title_14"
      >
        {t(headerText.key, headerText.data)}
      </HeadingStyled>
    </Container>
  );
};
