import { useBridgeContext } from '@sendible/shared-state-bridge';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DragAndDrop, MediaAttacherDropdown } from './components';
import { useBackgroundUploaderContext } from './components/BackgroundUploader/context';

export const FrontendV2Components = () => {
  const [sharedState] = useBridgeContext();
  const { closeUploader, cancelUpload, uploadingIdsFromComposeBox, uploadingIdsFromMediaLibrary, setUploadingIdsFromComposeBox } =
    useBackgroundUploaderContext();
  const navigate = useNavigate();

  useEffect(() => {
    uploadingIdsFromComposeBox.every((id) => cancelUpload(id));

    setUploadingIdsFromComposeBox([]);

    if (!uploadingIdsFromMediaLibrary.length) {
      closeUploader();
    }
  }, [sharedState?.compose?.isComposeBoxOpen, navigate]);

  return (
    <div>
      {window?.js_featureTagsManager?.isFeatureTag('mediaSystem') ? (
        <>
          {sharedState?.compose?.isMediaAttacherOpen && <MediaAttacherDropdown />}
          {sharedState?.compose?.isComposeBoxOpen && <DragAndDrop />}
        </>
      ) : null}
    </div>
  );
};
