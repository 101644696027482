export const canva = () => (
  <>
    <rect
      width={18.333}
      height={18.333}
      x={2.833}
      y={2.833}
      fill="url(#a)"
      rx={9.167}
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      stroke="#fff"
      strokeWidth={0.417}
      d="M15.521 15.314c-.616.566-1.016.955-1.911 1.413-.514.256-1.09.41-1.635.41-1.634 0-2.43-1.504-2.59-2.803-.407-3.333 2.522-7.914 4.564-7.914.476 0 .902.414.981 1.06.123 1.004.103 1.716-.936 2.584-.114.095-.147.248-.089.34.089.135.368.149.932-.136 1.105-.558 1.503-1.419 1.382-2.416-.136-1.105-1.1-2.031-2.395-2.031-.629 0-1.321.188-1.939.564-2.693 1.64-4.43 5.384-4.081 8.239.11.906.487 1.897 1.198 2.564.48.444 1.346.991 2.368.991 1.106 0 2.04-.427 2.878-.956a7 7 0 0 0 1.445-1.254c.657-.611.184-.991-.172-.655Z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="a"
        x1={20.743}
        x2={3.51}
        y1={21.643}
        y2={2.636}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#823AF3" />
        <stop
          offset={0.36}
          stopColor="#4B66E1"
        />
        <stop
          offset={0.906}
          stopColor="#01F1C4"
        />
      </linearGradient>
    </defs>
  </>
);
