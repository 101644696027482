import styled, { css } from 'styled-components';
import { LegacyStyledNotificationTypeEnum } from './index';

interface ToastPropsInterface {
  type: LegacyStyledNotificationTypeEnum | undefined;
}

export const ToastNotificationStyles = styled.div<ToastPropsInterface>`
  ${({ type = '' }) => css`
    align-items: center;
    background-color: #fcf8e3;
    border-color #fbeed5;
    border-radius: 6px;
    color: #c09853;
    display: flex;
    justify-content: space-between;
    left: 0;
    margin-bottom: 22px;
    margin-left: auto;
    margin-right: auto;
    padding: 8px 14px 8px 14px;
    position: absolute;
    right: 0;
    text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
    top: 1px;
    width: 290px;
    word-break: break-word;
    z-index: 9999999;

    ${
      type === 'error' &&
      css`
        background-color: #f2dede;
        border-color: #eed3d7;
        color: #ff4d93;
      `
    }

    ${
      type === 'info' &&
      css`
        background-color: #d9edf7;
        border-color: #bce8f1;
        color: #2689de;
      `
    }

    ${
      type === 'success' &&
      css`
        background-color: #dff0d8;
        border-color: #d6e9c6;
        color: #468847;
      `
    }
  `}
`;
