export const corner_right_up = () => (
  <>
    <path
      d="M10 9l5-5 5 5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 20h7a4 4 0 004-4V4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
