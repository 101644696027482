import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

type ReportsContextType = {
  isPrint: boolean;
  setIsPrint: Dispatch<SetStateAction<boolean>>;
};

export const ReportsContext = createContext({} as ReportsContextType);

export const ReportsProvider = ({ children }: Component) => {
  const [isPrint, setIsPrint] = useState(false);

  return (
    <ReportsContext.Provider
      value={{
        isPrint,
        setIsPrint,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export const useReportsContext = (): ReportsContextType => useContext(ReportsContext);
