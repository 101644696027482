const appendTag = (type: 'script' | 'link', props: Partial<HTMLScriptElement | HTMLLinkElement> = {}) =>
  new Promise((res, rej) => {
    try {
      const element = document.createElement(type);

      Object.assign(element, props);
      element.onload = () => {
        res(element);
      };
      document.head.appendChild(element);
    } catch (error: unknown) {
      rej(error);
    }
  });

export default {
  addLink: (href: string, props?: Partial<HTMLLinkElement>) =>
    appendTag('link', {
      ...props,
      href,
      rel: 'stylesheet',
      type: 'text/css',
    }),
  addScript: (src: string, props?: Partial<HTMLScriptElement>) =>
    appendTag('script', {
      ...props,
      src,
    }),
};
