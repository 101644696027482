import { Box, Button, Dropdown, Grid, Heading } from '@sendible/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DropdownOption } from '@sendible/design-system/src/components/molecules/Dropdown';
import { pages } from '@sendible/frontendv2/src/routes';
import { Container, PageHeader, SectionTitle } from './index.styles';
import { GridItemComponent } from './components/GridItemComponent';
import { ErrorState } from '../../../../components';
import { useCampaignsContext } from '../../context';
import { LoadingComponent } from '../LoadingComponent';
import { CustomAvatar } from '../LeftSidebar/components/CustomAvatar';
import { CAMPAIGN_ACTION_DELETE, CAMPAIGN_ACTION_EDIT } from '../../constants';

export const Overview = () => {
  const { t } = useTranslation('campaigns');
  const {
    activeCampaignObject: { id, name, status, color },
    setIsSidebarVisible,
    overviewError,
    overviewData,
    isOverviewLoading,
    setIsEditionModalOpen,
    setDeleteModalId,
  } = useCampaignsContext();
  const navigate = useNavigate();

  if (overviewError) {
    return (
      <ErrorState
        errorMessage={overviewError}
        translationNamespace="campaigns"
      />
    );
  }

  if (isOverviewLoading || !overviewData) {
    return <LoadingComponent />;
  }

  const { posts, tasks } = overviewData;
  const { bounced, draft, published, queued, scheduled } = posts;
  const { approved, pending, rejected } = tasks;

  const campaignActions: DropdownOption[] = [
    { action: CAMPAIGN_ACTION_EDIT, label: t('dropdown_edit') },
    { action: CAMPAIGN_ACTION_DELETE, label: t('dropdown_delete') },
  ];

  return (
    <Container>
      <PageHeader>
        <Button
          icon="menu_burger"
          onClick={() => setIsSidebarVisible(true)}
          size={14}
          customClass="button-sidebar"
        />
        <Box
          contentJustify="space-between"
          horizontalGap="s12"
          contentAlignment="center"
          fullWidth
        >
          <Box
            contentAlignment="center"
            horizontalGap="s12"
            flex={3}
            overflow="nowrap"
          >
            <CustomAvatar
              status={status}
              color={color}
            />
            <Heading
              semantic="h1"
              maxLines={1}
            >
              {name}
            </Heading>
            <SectionTitle>Overview</SectionTitle>
          </Box>
          <Box
            contentAlignment="center"
            contentJustify="flex-end"
            horizontalGap="s12"
            customClass="hide-mobile"
            flex={1}
            overflow="nowrap"
          >
            <Button
              variation="outline"
              icon="bar_chart"
              label={t('report') || ''}
              onClick={() => navigate(`${pages.Measure.campaigns}/${id}`)}
            />
            <Dropdown
              options={campaignActions}
              select={(action) => {
                if (id) {
                  if (action === CAMPAIGN_ACTION_EDIT) {
                    navigate(`${pages.Campaigns.base}/${id}`);
                    setIsEditionModalOpen(id);
                  } else if (action === CAMPAIGN_ACTION_DELETE) {
                    setDeleteModalId(id || null);
                  }
                }
              }}
            >
              <Button
                icon="more_horizontal"
                title="options"
                size={14}
                customClass="sidebar-nav-item-button"
              />
            </Dropdown>
          </Box>
        </Box>
      </PageHeader>
      <Grid numberOfColumns={4}>
        <GridItemComponent
          title={t('posts_published_header')}
          count={published}
          tooltip={t('tooltip_sent')}
        />
        <GridItemComponent
          title={t('posts_scheduled_header')}
          count={scheduled}
          tooltip={t('tooltip_scheduled')}
        />
        <GridItemComponent
          title={t('posts_drafted_header')}
          count={draft}
          tooltip={t('tooltip_drafted')}
        />
        <GridItemComponent
          title={t('posts_queued_header')}
          count={queued}
          tooltip={t('tooltip_queued')}
        />
        <GridItemComponent
          title={t('posts_undelivered_header')}
          count={bounced}
          tooltip={t('tooltip_undelivered')}
        />
        <GridItemComponent
          title={t('pending_posts_header')}
          count={pending}
          tooltip={t('tooltip_pending_approval')}
        />
        <GridItemComponent
          title={t('posts_approved_header')}
          count={approved}
          tooltip={t('tooltip_approved')}
        />
        <GridItemComponent
          title={t('posts_rejected_header')}
          count={rejected}
          tooltip={t('tooltip_rejected')}
        />
      </Grid>
    </Container>
  );
};
