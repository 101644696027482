import { Container } from './index.styles';
import { Spacings } from '../../../theme';
import { spacings } from '../../../theme/typeConstants';

export type SpaceArray<T extends (typeof spacings)[number][]> = Pick<T, Exclude<keyof T, ArrayLengthMutationKeys>> & {
  [Symbol.iterator]: () => IterableIterator<ArrayItems<T>>;
};

export const fillRules = ['auto-fill', 'auto-fit'] as const;

export interface GridProps extends Component {
  /**
   * Content fill behavior. Optional.
   */
  fillRule?: (typeof fillRules)[number];
  /**
   * Horizontal space between children elements. Optional.
   */
  horizontalGap?: Spacings;
  /**
   * Outside margins. E.g: ['s12', 's0', 's8', 's0']. Optional.
   */
  margin?: SpaceArray<[Spacings, Spacings, Spacings, Spacings]>;
  /**
   * Grid min size. Optional.
   */
  minGridSize?: string;
  /**
   * Number Of Columns in the Grid
   */
  numberOfColumns?: number;
  /**
   * Number Of Rows in the Grid
   */
  numberOfRows?: number;
  /**
   * Inside paddings. E.g: ['s12', 's0', 's8', 's0']. Optional.
   */
  padding?: SpaceArray<[Spacings, Spacings, Spacings, Spacings]>;
  /**
   * Vertical space between children elements. Optional.
   */
  verticalGap?: Spacings;
}

/**
 * Grid
 */
export const Grid = (props: GridProps) => {
  const { children, className = '', customClass = '', fillRule = 'auto-fill', testId = 'grid' } = props;

  return (
    <Container
      className={`grid grid-${fillRule} ${customClass} ${className}`}
      data-testid={testId}
      $fillRule={fillRule}
      {...props}
    >
      {children}
    </Container>
  );
};
