import { FC } from 'react';
import { Skeleton } from '@sendible/design-system/src';
import { LoadingMediaCard } from './index.styles';

type Props = {
  perPage: number;
};

const Skeletons: FC<Props> = ({ perPage }) => {
  return (
    <>
      {new Array(perPage).fill('').map((_, index) => (
        <LoadingMediaCard
          key={index}
          orientation="column"
          verticalGap="s12"
        >
          <Skeleton
            shape="square"
            width="100%"
          />
          <Skeleton
            width="80%"
            height="0.75rem"
          />
          <Skeleton
            width="60%"
            height="0.75rem"
          />
        </LoadingMediaCard>
      ))}
    </>
  );
};

export default Skeletons;
