import { Container } from './index.styles';
import { useAiAssistContext } from '../../AiAssistContext';
import { inputMaxLength } from '../TextArea';

export const CharacterCounter = () => {
  const { promptValue } = useAiAssistContext();
  const count = inputMaxLength - promptValue.length;
  const size = 2;

  return (
    <Container
      $count={count}
      data-testid="ai-input-character-counter"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size}rem`}
        height={`${size}rem`}
        fill="none"
      >
        <circle
          className="counter-bg"
          cx={`${size / 2}rem`}
          cy={`${size / 2}rem`}
          r={size * 8 * 0.83333}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <circle
          className="counter-meter"
          cx={`${size / 2}rem`}
          cy={`${size / 2}rem`}
          r={size * 8 * 0.83333}
          strokeDasharray={size * 40}
          strokeDashoffset={count * 0.255}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
      </svg>
      <span>{count}</span>
    </Container>
  );
};
