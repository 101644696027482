import { MediaLibraryProvider } from './context';
import { ContainerStyled } from './index.styles';
import { MediaLibraryPage } from './page';

export const MediaLibrary = () => (
  <MediaLibraryProvider>
    <ContainerStyled className="isV2Page">
      <MediaLibraryPage />
    </ContainerStyled>
  </MediaLibraryProvider>
);
