import { useTranslation } from 'react-i18next';
import { useGetMediaLibraries } from '../../../models/mediaLibraries/index';
import { defaultGetMediaLibrariesResponse, defaultMediaLibrary, MEDIA_LIBRARY_ACTION_DELETE, MEDIA_LIBRARY_ACTION_RENAME } from '../constants';
import { useMediaLibraryContext } from '../context';

export default function useMediaLibraryOptions() {
  const { data = defaultGetMediaLibrariesResponse } = useGetMediaLibraries();
  const { setIsDeleteModalOpen, setIsRenameModalOpen, setActiveMediaLibrary } = useMediaLibraryContext();
  const { canDelete } = data;
  const { t } = useTranslation('media_library');

  return {
    getMediaLibraryOptions: (mediaLibrary = defaultMediaLibrary) => {
      const del = {
        label: t('dropdown_delete'),
        isHighlighted: true,
        action: MEDIA_LIBRARY_ACTION_DELETE,
      };
      const rename = {
        label: t('dropdown_rename'),
        action: MEDIA_LIBRARY_ACTION_RENAME,
      };

      const optionsList = [rename];

      if (canDelete) optionsList.push(del);

      const actionHandlers = {
        [MEDIA_LIBRARY_ACTION_DELETE as string]: () => {
          setActiveMediaLibrary(mediaLibrary);
          setIsDeleteModalOpen(true);
        },
        [MEDIA_LIBRARY_ACTION_RENAME as string]: () => {
          setActiveMediaLibrary(mediaLibrary);
          setIsRenameModalOpen(true);
        },
      };

      return { optionsList, actionHandlers };
    },
  };
}
