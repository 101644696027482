import { Tooltip } from '@sendible/design-system/src';
import { CustomAvatarStyled, Status } from './index.styles';

export const CustomAvatar = ({ color, status }: { color: string; status: string }) => {
  return (
    <Tooltip
      content={status === 'active' ? 'Active' : 'Inactive'}
      noDecoration
      defaultPosition="top"
    >
      <CustomAvatarStyled color={color}>
        <div>
          <Status status={status} />
        </div>
      </CustomAvatarStyled>
    </Tooltip>
  );
};
