import { MouseEvent } from 'react';
import { CommonLink, RouterLink } from './index.styles';
import { IconNames } from '../../../theme';
import { Icon } from '../../atoms/Icon';

export const linkTypes = ['router', 'common'] as const;
export const targets = ['_blank', '_self'] as const;

export interface LinkProps extends Component {
  /**
   * click event emmiter. Optional.
   */
  click?: (e: MouseEvent) => void;
  /**
   * Icon name. If it has icon, icon will be applied. Optional. (You can see the full list at branding/icons)
   */
  icon?: IconNames;
  /**
   * Link url. Required.
   */
  link: string;
  /**
   * Router link or common link? Optional.
   */
  linkType?: (typeof linkTypes)[number];
  /**
   * mouseover event emmiter. Optional.
   */
  mouseOver?: (e: MouseEvent) => void;
  /**
   * Link open behavior (Common link only). Optional.
   */
  target?: (typeof targets)[number];
  /**
   * Hover title. Optional.
   */
  title?: string;
}

/**
 * Link
 */
export const Link = (props: LinkProps) => {
  const {
    children,
    className = '',
    click,
    customClass = '',
    icon,
    link,
    linkType = 'router',
    mouseOver,
    target = '_blank',
    testId = 'link',
    title,
  } = props;

  return (
    <>
      {linkType === 'router' ? (
        <RouterLink
          className={`link ${customClass} ${className}`}
          data-testid={testId}
          onClick={click}
          onMouseOver={mouseOver}
          to={`/${link}`}
          title={title || String(children)}
        >
          {icon && <Icon name={icon} />}
          {children}
        </RouterLink>
      ) : (
        <CommonLink
          className={`link ${customClass}`}
          data-testid={testId}
          href={link}
          onClick={click}
          onMouseOver={mouseOver}
          rel="noreferrer noopener"
          target={target}
          title={title || String(children)}
        >
          {icon && <Icon name={icon} />}
          {children}
        </CommonLink>
      )}
    </>
  );
};
