import { Box } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const FilterContainer = styled(Box)`
  gap: 1rem;
`;

export const DateInput = styled.input`
  ${({
    theme: {
      breakPoints: { smallDevices, mediumDevices },
      colors,
    },
  }) => css`
    background: rgba(32, 30, 36, 0.05) !important;
    border-color: rgba(32, 30, 36, 0.05) !important;
    outline: none !important;
    border-radius: 4px !important;
    color: ${colors.brandDark} !important;
    font-size: 1rem !important;
    height: 2rem !important;
    justify-content: space-between;
    width: auto;

    &:focus {
      border-color: rgb(var(--color-primary)) !important;
    }

    @media all and (${smallDevices.max}) {
      font-size: 0.8rem !important;
    }
  `}
`;
