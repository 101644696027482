import { Container } from './index.styles';
import { generateId } from '../../utils/helpers';

export const shapes = ['square', 'circle', 'free'] as const;

export interface SkeletonProps extends Component {
  /**
   * Number of skeletons. Optional.
   */
  count?: number;
  /**
   * Column size. Optional. (Note: This will be ignored if shape is circle or square!)
   */
  height?: string;
  /**
   * Shape variation. Optional. (circle and square will use width as height)
   */
  shape?: (typeof shapes)[number];
  /**
   * Width. E.g.: '20%' or '1rem' Required.
   */
  width: string;
}

/**
 * Skeleton
 */
export const Skeleton = (props: SkeletonProps) => {
  const { className = '', customClass = '', count = 1, testId = 'skeleton' } = props;

  const it = new Array(count).fill('');

  const items = it.map(() => (
    <Container
      className={`skeleton ${customClass} ${className}`}
      data-testid={testId}
      key={generateId()}
      {...props}
    />
  ));

  return <>{items}</>;
};
