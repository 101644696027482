import styled, { css } from 'styled-components';

export const SidebarWrapper = styled.div<{ $isReports?: boolean; $responsiveIsOpen?: boolean }>`
  ${({
    theme: {
      breakPoints: { smallDevices },
    },
    $isReports,
    $responsiveIsOpen = false,
  }) => css`
    width: ${$isReports ? '204px' : '14%'};

    #left-sidebar {
      box-sizing: border-box;
      position: relative;
      top: 0 !important;
      width: ${$isReports ? '204px' : '100%'} !important;

      svg {
        width: 15px !important;
        height: 23px !important;
      }
    }

    @media all and (${smallDevices.max}) {
      display: ${$responsiveIsOpen ? 'block' : 'none'};
      position: absolute;
      bottom: 0;
      top: 86px;
    }
  `}
`;
