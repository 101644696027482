import React, { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { advancedLocalStorage, localStorageConsts } from '@sendible/common';
import { EVENTS, subscribe } from '@sendible/event-manager';
import { BridgeContextType, SharedStateType } from '@sendible/shared-state-bridge';

interface BridgeContextProviderProps {
  initialState: SharedStateType;
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const BridgeContext = createContext<BridgeContextType>(undefined!);

const BridgeContextProvider: FC<BridgeContextProviderProps> = (props) => {
  const { children, initialState } = props;
  const [state, setState] = useState(initialState);
  const value: BridgeContextType = useMemo(() => [state, setState], [state]);

  useEffect(() => {
    subscribe(EVENTS.LOCAL_STORAGE_CHANGED, () => {
      const newContext = advancedLocalStorage.getItem(localStorageConsts.sharedStateKey);

      setState(newContext);
    });
  }, []);

  return <BridgeContext.Provider value={value}>{children}</BridgeContext.Provider>;
};

const useBridgeContext = () => {
  const context = useContext(BridgeContext);

  if (!context) {
    throw new Error('getContext must be used within BridgeContext');
  }

  return context;
};

export { BridgeContextProvider, useBridgeContext };
