import styled, { css } from 'styled-components';

export const Actions = styled.div`
  ${({ theme: { spacings } }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: ${spacings.s8} 0;
    margin-top: ${spacings.s24};
    width: 100%;

    button {
      width: 100%;
    }
  `}
`;
