export const corner_down_right = () => (
  <>
    <path
      d="M15 10l5 5-5 5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 4v7a4 4 0 004 4h12"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
