import { useBridgeContext } from '@sendible/shared-state-bridge';

export const useUrlWithCredentials = () => {
  const [{ user }] = useBridgeContext();

  return (url: string) => {
    let credentials = `access_token=${user.accessToken}`;

    if (user.isSwitchedUser) {
      credentials = `username=${user.username}&logged_in_access_token=${user.accessToken}`;
    }

    return `${url}?${credentials}`;
  };
};
