import { Box } from '@sendible/design-system';
import styled from 'styled-components';

export const ContainerStyled = styled.div`
  display: flex;
  flex: auto;
  font-family: 'IBM Plex Sans', sans-serif;

  .dialog-modal {
    left: unset;
    right: 0;
    z-index: 12;
  }
`;

export const BoxStyled = styled(Box)`
  box-sizing: border-box;
  width: 100%;
`;
