import { useEffect, useState, useRef, KeyboardEvent } from 'react';
import { Actions, BackgroundFiller, Container, Details, Image, Progress, Time } from './index.styles';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import { Icon } from '../../atoms/Icon';
import { Text } from '../../atoms/Text';
import { Dropdown } from '../Dropdown';

export interface DropdownAction {
  action: string;
  click?: () => void;
  isHighlighted?: boolean;
  label: string;
}

export interface MediaCardProps extends Omit<Component, 'id'> {
  /**
   * Aria Media Card label. Optional
   */
  ariaMediaCardLabel?: string;
  /**
   * List of actions for the dropdown. Required.
   */
  dropdownActions: DropdownAction[];
  /**
   * Dropdown button event emitter. Required.
   */
  dropdownButtonClick: (option: DropdownAction['action']) => void;
  /**
   * Video duration time. Optional.
   */
  duration?: number;
  /**
   * Media type.
   */
  extension?: string;
  /**
   * Media id. Required.
   */
  id: number;
  /**
   * Tells if the mediacard is selected. Optional.
   */
  isSelected?: boolean;
  /**
   * If loading, receives loading percentage updates. Optional.
   */
  loadingProgress?: number;
  /**
   * File name. Required.
   */
  name: string;
  /**
   * Open event emitter. Optional.
   */
  open?: (id: number) => void;
  /**
   * select event emitter. Optional.
   */
  select?: (value: boolean) => void;
  /**
   * Display the checkbox by default. Optional.
   */
  showCheckboxWithoutHover?: boolean;
  /**
   * Tab index for MediaCard. Optional.
   */
  tabIndex?: number;
  /**
   * URL of the thumbnail. Optional.
   */
  thumbUrl?: string;
  /**
   * File type. Required.
   */
  type: string;
  /**
   * Creation date or last update date. Required.
   */
  updateDate: string;
  /**
   * Translated text for uploading state. Required.
   */
  uploadingText: string;
}

/**
 * MediaCard
 */
export const MediaCard = (props: MediaCardProps) => {
  const {
    ariaMediaCardLabel = '',
    className = '',
    customClass = '',
    dropdownActions,
    dropdownButtonClick,
    duration = 0,
    extension,
    name,
    type,
    id,
    isSelected = false,
    loadingProgress,
    open,
    select,
    showCheckboxWithoutHover = false,
    tabIndex = -1,
    testId = 'mediacard',
    thumbUrl,
    uploadingText,
    updateDate,
  } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSelectedState, setIsSelectedState] = useState<boolean>(isSelected);
  const ref = useRef(null);

  const handleSelect = () => {
    setIsSelectedState((prev) => !prev);
    select && select(isSelectedState);
  };

  const getTime = () => {
    const mins = Math.floor(duration / 60000);
    const secs = ((duration % 60000) / 1000).toFixed(0);

    return `${mins}:${Number(secs) < 10 ? '0' : ''}${secs}`;
  };

  const filename = extension ? `${name}.${extension}` : name;

  useEffect(() => {
    setIsSelectedState(isSelected);
  }, [isSelected]);

  return (
    <Container
      className={`mediacard ${customClass} ${className}`}
      data-testid={testId}
    >
      <Image
        isSelected={isSelectedState}
        showCheckbox={isSelectedState || showCheckboxWithoutHover}
        onKeyDown={(e: KeyboardEvent<HTMLElement>) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            open && open(id);
          }
          if (e.key === ' ') {
            e.preventDefault();
            handleSelect();
          }
        }}
        tabIndex={tabIndex}
        data-testid={`${testId}-image`}
        aria-label={ariaMediaCardLabel}
      >
        {!loadingProgress ? (
          <>
            <Checkbox
              check={handleSelect}
              id={id.toString()}
              initialState={isSelectedState ? 'checked' : 'unchecked'}
              label="Checkbox"
              size="md"
              tabIndex={-1}
              testId={`${testId}-checkbox`}
            />
            <BackgroundFiller
              data-testid={`${testId}-button`}
              id={id.toString()}
              onClick={() => open && open(id)}
              tabIndex={-1}
            />
            {thumbUrl && type.toLowerCase().includes('image') ? (
              <>
                <img
                  alt=""
                  src={thumbUrl}
                />
                <Icon
                  customClass="placeholder"
                  name="image"
                />
              </>
            ) : (
              <>
                {duration ? <Time>{getTime()}</Time> : ''}
                <img
                  alt=""
                  src={thumbUrl}
                />
                <Icon
                  customClass="placeholder"
                  name="video"
                />
              </>
            )}
          </>
        ) : (
          <Progress loadingProgress={loadingProgress}>
            <span />
          </Progress>
        )}
      </Image>
      <Actions id={id.toString()}>
        <Details isSelected={isSelectedState}>
          <Text
            color={isSelectedState ? 'brandPrimary' : 'brandDark'}
            variation="body_12"
          >
            {filename}
          </Text>
          <Text
            color="brandDarkAlpha70"
            variation="body_12"
          >
            {loadingProgress ? `${uploadingText}...` : updateDate}
          </Text>
        </Details>
        {!loadingProgress && (
          <Dropdown
            options={dropdownActions}
            select={(option) => dropdownButtonClick(option)}
            visibilityChange={(state) => !state && setIsDropdownOpen(false)}
          >
            <Button
              aria-controls="menu"
              aria-expanded={isDropdownOpen}
              aria-haspopup="true"
              onClick={() => setIsDropdownOpen(true)}
              customClass={isDropdownOpen ? 'show-dropdown-button' : ''}
              icon="more_horizontal"
              ref={ref}
              size={12}
              data-testid={`${testId}-dropdown-btn`}
            />
          </Dropdown>
        )}
      </Actions>
    </Container>
  );
};
