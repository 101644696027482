import { useEffect, useState } from 'react';
import { Heading, Tooltip } from '@sendible/design-system/src';
import { useTranslation } from 'react-i18next';
import { imageUtils } from '@sendible/common';
import { useReportsModel } from '@sendible/frontendv2/src/models/reports';
import { pages } from '@sendible/frontendv2/src/routes';
import {
  OverviewMainContainer,
  OverviewContainer,
  OverviewDataContainer,
  ProfilesOverviewContainer,
  ProfileOverviewItemContainer,
} from './index.styles';
import { MetricOverview } from './components/MetricOverview';
import { ModuleErrorContainer } from '../index.styles';
import { useReportsContext } from '../../context';

type Posts = {
  comments: number;
  likes: number;
  total: number;
};

type Profiles = {
  pictureUrl: string;
  profileName: string;
  socialNetwork: string;
};

type OverviewData = {
  profiles: Profiles[];
  posts: Posts;
  audience: number;
};

type OverviewProps = {
  title: string;
  type: string;
  id: number | null;
  defaultErrorMessage: string;
  emptyErrorMessage: string;
};

export const Overview = ({ type, id, title, defaultErrorMessage, emptyErrorMessage }: OverviewProps) => {
  const { getReportCampaignOverview } = useReportsModel();
  const { isPrint } = useReportsContext();
  const { t } = useTranslation(['common_terms', 'reports']);

  const [overviewResponse, setOverviewResponse] = useState<OverviewData | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const postsSent = t('posts_sent').toUpperCase();
  const interactions = t('posts_interactions').toUpperCase();
  const audience = t('profile_audience').toUpperCase();

  const profilesInfo: string[] = [];

  useEffect(() => {
    const fetchReportOverview = async () => {
      try {
        if (id) {
          let results = null;

          if (type === pages.Measure.campaigns) {
            results = await getReportCampaignOverview(id);
          }

          setOverviewResponse(results);

          if (results.profiles.length == 0) {
            setErrorMessage(emptyErrorMessage);
          } else {
            setErrorMessage(null);
          }
        }
      } catch (error: unknown) {
        setErrorMessage(defaultErrorMessage);
      }
    };

    fetchReportOverview();
  }, [id]);

  if (errorMessage) {
    return (
      <OverviewMainContainer horizontalGap="s12">
        <ModuleErrorContainer>
          <Heading
            semantic="h6"
            variation="component-title_20"
            color="brandLight"
          >
            {title}
          </Heading>
          <div>{errorMessage}</div>
        </ModuleErrorContainer>
      </OverviewMainContainer>
    );
  }

  if (!overviewResponse?.profiles?.length) {
    return null;
  }

  const firstThreeProfiles = overviewResponse.profiles.slice(0, 3);
  const remainingProfiles = overviewResponse.profiles.slice(3);

  remainingProfiles.forEach((profile) => {
    profilesInfo.push(`${profile.profileName} (${profile.socialNetwork})`);
  });

  return (
    <OverviewMainContainer horizontalGap="s12">
      <OverviewContainer>
        <Heading
          semantic="h6"
          variation="component-title_20"
          color="brandLight"
        >
          {title}
        </Heading>
        <OverviewDataContainer>
          <ProfilesOverviewContainer>
            {firstThreeProfiles.slice(0, 3).map((profile: Profiles, index: number) => (
              <ProfileOverviewItemContainer key={index}>
                {isPrint ? (
                  <img
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={`${profile.profileName} (${profile.socialNetwork})`}
                    src={profile.pictureUrl}
                    alt={profile.profileName}
                    onError={imageUtils.removeImageOnError}
                    crossOrigin="anonymous"
                  />
                ) : (
                  <img
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={`${profile.profileName} (${profile.socialNetwork})`}
                    src={profile.pictureUrl}
                    alt={profile.profileName}
                    onError={imageUtils.removeImageOnError}
                  />
                )}
              </ProfileOverviewItemContainer>
            ))}
            {profilesInfo && profilesInfo.length > 0 ? (
              <ProfileOverviewItemContainer>
                <Tooltip
                  content={<>{profilesInfo.join(', ')}</>}
                  defaultPosition="right-end"
                  noDecoration
                >
                  + {profilesInfo.length}
                </Tooltip>
              </ProfileOverviewItemContainer>
            ) : null}
          </ProfilesOverviewContainer>
          <MetricOverview
            tooltip={t('tooltip_posts_sent', { ns: 'reports' })}
            title={postsSent}
            value={overviewResponse.posts.total ?? 0}
            iconName="send"
          />
          <MetricOverview
            tooltip={t('tooltip_audience', { ns: 'reports' })}
            title={audience}
            value={overviewResponse.audience ?? 0}
            iconName="users"
          />
          <MetricOverview
            tooltip={t('tooltip_interactions', { ns: 'reports' })}
            title={interactions}
            value={overviewResponse.posts.comments + overviewResponse.posts.likes ?? 0}
            iconName="conversation"
          />
        </OverviewDataContainer>
      </OverviewContainer>
    </OverviewMainContainer>
  );
};
