/* eslint-disable no-param-reassign */
import { SyntheticEvent } from 'react';

export const removeImageOnError = (event: SyntheticEvent<HTMLImageElement, Event>) => {
  event.currentTarget.src = '/images/generic/1x1-transparent.png';
  event.currentTarget.style.width = '1px';
  event.currentTarget.style.height = '1px';
};

export default {
  removeImageOnError,
};
