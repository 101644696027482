// XLocalized strings for use in Javascript only. For HTML localization, use Utils.localize
// Exmples:
// dictionary.templates[term]
// Localized strings for use in Javascript only. For HTML localization, use Utils.localize
// Exmples:
// dictionary.templates[term]
export default {
  availableLanguages: ['en', 'sv', 'zh', 'de', 'el', 'ru', 'es', 'fr', 'fa', 'nl', 'pl', 'th', 'ar', 'cs', 'ja', 'sk', 'it', 'he', 'ko', 'ja_srnd', 'en_nulou', 'en_digmatb', 'en_advsoc', 'en_slmng', 'en_dash', 'en_scmng', 'en_splcast', 'en_sclzr', 'en_sclstd', 'en_dig360'],
  // Message Dialogs
  common: {
    daysOfWeek: {
      en: 'Monday Tuesday Wednesday Thursday Friday Saturday Sunday',
      el: 'Δευτέρα Τρίτη Τετάρτη Πέμπτη Παρασκευή Σάββατο Κυριακή',
      es: 'Lunes Martes Miercoles Jueves Viernes Sabado Domingo',
      de: 'Montag Dienstag Mittwoch Donnerstag Freitag Samstag Sonntag',
      fr: 'lundi Mardi Mercredi Jeudi Vendredi Samedi Dimanche',
      it: 'lunedì martedì mercoledì giovedì venerdì sabato domenica',
      ru: 'понедельник вторник среда Четверг Пятница Суббота воскресенье',
      he: 'שני שלישי רביעי חמישי חמישי שישי שבת',
      fa: 'دوشنبه سه چهارشنبه پنجشنبه جمعه شنبه یکشنبه',
      sk: 'Pondelok Utorok Streda Štvrtok Piatok Sobota Nedeľa',
      zh: '星期一 星期二 星期三 星期四 星期五 星期六 星期天',
      ja: '月曜日 火曜日 水曜日 木曜日 金曜日 土曜日 日曜日',
      ko: '월요일 화요일 수요일 목요일 금요일 토요일 일요일',
      ja_srnd: '月曜日 火曜日 水曜日 木曜日 金曜日 土曜日 日曜日',
    },
    dayNamesShort: {
      en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      es: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
      fa: ['ی', 'د', 'س', 'چ', 'پ', 'ج', 'ش'],
    },
    months: {
      en: 'January February March April May June July August September October November December',
      de: 'Januar Februar März April Mai Juni Juli August September Oktober November Dezember',
      es: 'Enero Febrero Marzo Abril Mayo Junio Julio Agosto Septiembre Octubre Noviembre Diciembre',
      fr: 'Janvier Fevrier Mars Avril Mai Juin Juillet Aout Septembre Octobre Novembre Decembre',
      it: 'Gennaio Febbraio Marzo Aprile Maggio Giugno Luglio Agosto Settembre Ottobre Novembre Dicembre',
      ru: 'Январь февраль март апрель май июнь июль август сентябрь октябрь ноябрь декабрь',
      fa: 'ژانویه فوریه مارس آوریل مه ژوئن ژوئیه اوت سپتامبر اکتبر نوامبر دسامبر',
      cs: 'leden‎ únor‎ březen‎ duben‎ květen červen‎ červenec‎ srpen‎ září‎ říjen‎ listopad prosinec‎',
      el: 'Ιανουάριος Φεβρουάριος Μάρτιος Απρίλιος Μάιος Ιούνιος Ιούλιος Αύγουστος Σεπτέμβριος Οκτώβριος Νοέμβριος Δεκέμβριος',
      sk: 'Január február marec apríl máj jún júl august september október november december',
      sv: 'januari februari mars april maj juni juli augusti september oktober november december',
      pl: 'styczeń luty marzec kwiecień maj czerwiec lipiec sierpień wrzesień październik listopad grudzień',
      th: 'มกราคม กุมภาพันธ์ มีนาคม เมษายน อาจ มิถุนายน กรกฎาคม สิงหาคม กันยายน ตุลาคม พฤศจิกายน ธันวาคม',
      tr: 'Ocak Şubat Mart Nisan Mayıs Haziran Temmuz Ağustos Eylül Ekim Kasım Aralık',
      nl: 'januari februari maart april mei juni juli augustus september oktober november december',
      he: 'ינואר פברואר מרץ אפריל מאי יוני יולי אוגוסט ספטמבר אוקטובר נובמבר דצמבר',
      ja: '1月 2月 3月 4月 5月 6月 7月 8月 9月 10月 11月 12月',
      ko: '1월 2월 3월 4월 5월 6월 7월 8월 9월 10월 11월 12월',
    },
    monthNamesShort: {
      en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      es: ['Enero', 'Feb', 'Mar', 'Abr.', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      fa: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      it: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      de: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      el: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      ru: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      nl: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      cs: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      pl: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      sv: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      th: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      tr: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      he: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      ja: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      ko: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    },
    currency: {
      usd: '$',
      eur: '&euro;',
      gbp: '&pound',
    },
  },
  templates: {
    // the default language is English so there are no translations here
    // translation files are loaded if the language is set to anything
    // other than English
    // (see the libs/localize folder for template translations in other languages)
  },
};
