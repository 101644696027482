import { ThemeProvider as Provider, StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import { themeNames } from '../../../theme/typeConstants';
import GlobalStyle from '../../../theme/GlobalStyle';
import { themeGenerator } from '../../../theme';
import { whitelabelColors } from '../../../theme/whitelabelColors';

interface ThemeProviderProps extends Component {
  theme?: string;
}

const shouldForwardProp = (propName: string, target: unknown): boolean => {
  if (typeof target === 'string') {
    return isPropValid(propName);
  }

  return true;
};

/**
 * ThemeProvider
 */
export const ThemeProvider = ({ children, theme }: ThemeProviderProps) => (
  <StyleSheetManager
    shouldForwardProp={shouldForwardProp}
    enableVendorPrefixes
  >
    <Provider theme={theme && themeNames.includes(theme) ? themeGenerator(whitelabelColors[theme]) : themeGenerator()}>
      <GlobalStyle />
      {children}
    </Provider>
  </StyleSheetManager>
);
