import { Text } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ $color: string }>`
  ${({ $color }) => css`
    background: ${$color};
    border-radius: 1rem;
    box-sizing: border-box;
    color: white;
    padding: 2rem 15rem 2rem 2rem;

    > p {
      color: white;
      font-weight: bold;
      margin-bottom: 1rem;
      text-transform: uppercase;
      width: 6.5rem;
      word-wrap: normal;
    }

    svg {
      margin-right: 0.5rem;
    }
  `}
`;

export const AudienceChangeText = styled(Text)`
  font-weight: bold;
`;
