import React, { useEffect, useRef, useState } from 'react';
import { DateTime, Info } from 'luxon';
import { Box, Button } from '@sendible/design-system';
import { Actions, Container, Grid, Header } from './index.styles';
import { DateButton } from './components';

type CalendarProps = {
  closeHandler: () => void;
  dateSelectedCallback: (date: CampaignsDateTime) => void;
  isStartOrEndDate: IsStartOrEndDateType;
  selectedDateRanges: SelectedDateRangeType;
};

export const DatePicker = ({ closeHandler, dateSelectedCallback, isStartOrEndDate, selectedDateRanges }: CalendarProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const currentDate = DateTime.now().toUTC();
  const { startDate, endDate } = selectedDateRanges;
  const [visibleDate, setVisibleDate] = useState(currentDate);
  const firstDayOfMonth = DateTime.fromObject({ year: visibleDate.year, month: visibleDate.month, day: 1 });
  const emptyFieldsStart = firstDayOfMonth.weekday !== 7 ? new Array(firstDayOfMonth.weekday).fill(undefined) : [];
  const items = new Array(visibleDate.daysInMonth)
    .fill('')
    .map((_, indx) => DateTime.fromObject({ year: visibleDate.year, month: visibleDate.month, day: indx + 1 }));
  const calendarDates = [...emptyFieldsStart, ...items];

  for (let x = calendarDates.length; (x / 7) % 1 !== 0; x++) {
    calendarDates.push(undefined);
  }

  useEffect(() => {
    if (startDate && isStartOrEndDate === 'start') {
      setVisibleDate(startDate.toUTC());
    }

    if (endDate && isStartOrEndDate === 'end') {
      setVisibleDate(endDate.toUTC());
    }
  }, [selectedDateRanges]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && event.target && !ref.current.contains(event.target as Node)) {
        closeHandler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container ref={ref}>
      <Header>
        <Actions>
          <Button
            onClick={() => setVisibleDate((vDate) => vDate.minus({ months: 1 }).toUTC())}
            data-testid="icon-left"
            icon="chevron_left"
            size={14}
          />
          <Button
            onClick={() => setVisibleDate((vDate) => vDate.plus({ months: 1 }).toUTC())}
            data-testid="icon-right"
            icon="chevron_right"
            size={14}
          />
        </Actions>
        <p data-testid="d-p-month">{visibleDate.monthLong}</p>
        <p data-testid="d-p-year">{visibleDate.year}</p>
      </Header>
      <Grid>
        {new Array(7).fill('').map((_, wdIndex) => (
          <div key={`weekday-${wdIndex}`}>
            <p data-testid="d-p-weekday">{Info.weekdays('short')[(wdIndex + 6) % 7][0]}</p>
          </div>
        ))}
      </Grid>
      <Grid data-testid="d-t-calendarDates">
        {calendarDates.map((dayDate, dIndex) => (
          <DateButton
            date={dayDate}
            index={dIndex}
            selectedDateRanges={selectedDateRanges}
            dateSelectedCallback={dateSelectedCallback}
            key={`date-button-${dIndex}`}
          />
        ))}
      </Grid>
      <Box contentJustify="flex-end">
        <Button
          data-testid="d-p-clearButton"
          onClick={() => {
            dateSelectedCallback(null);
          }}
          label="Clear"
        />
      </Box>
    </Container>
  );
};
