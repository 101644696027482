import { createPortal } from 'react-dom';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useRef, useState } from 'react';
import { Container, HiddenInput, List, Spacer } from './index.styles';
import { Option } from './components';
import { composeMediaInteractions } from '../../composeMediaInteractions';
import { useUploadFiles } from '../../models/uploads';

export const MediaAttacherDropdown = () => {
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { uploadFiles } = useUploadFiles({ useMediaLibrary: false });
  const { t } = useTranslation('common_terms');
  const [
    {
      compose: { activeComposeBoxTab, isMediaAttacherOpen },
    },
  ] = useBridgeContext();

  const portal = window.document.getElementById(`attachment-button-compose-${activeComposeBoxTab}`) as HTMLElement;

  const { x, y, strategy, refs } = useFloating({
    open: isMediaAttacherOpen,
    onOpenChange: composeMediaInteractions.setIsMediaAttacherOpen,
    placement: 'bottom-start',
    middleware: [offset({ mainAxis: 0, alignmentAxis: -4 }), shift(), flip({ fallbackPlacements: ['top-start', 'right-end', 'right-start'] })],
    whileElementsMounted: autoUpdate,
  });

  const onDeviceUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    setIsUploading(false);
    composeMediaInteractions.setIsMediaAttacherOpen(false);

    if (!files) return;

    uploadFiles(Array.from(files), 'compose-box');
  };

  const handleBlur = () => {
    if (!isUploading) composeMediaInteractions.setIsMediaAttacherOpen(false);
    else setIsUploading(false);
  };

  const handleClick = (option: string) => {
    // eslint-disable-next-line no-console
    console.log(`${option} clicked!`);
  };

  return (
    <>
      {createPortal(
        <Container
          ref={refs.setReference}
          onClick={() => {
            setIsUploading(false);
            composeMediaInteractions.toggleMediaAttacher();
          }}
          onBlur={handleBlur}
        />,
        portal
      )}
      {createPortal(
        <List
          ref={refs.setFloating}
          style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
          onBlur={handleBlur}
        >
          <HiddenInput
            accept="video/*,image/*"
            multiple
            name="files[]"
            onChange={onDeviceUpload}
            onClick={(event) => {
              setIsUploading(true);
              event.currentTarget.value = '';
            }}
            ref={fileInputRef}
            type="file"
          />
          <Option
            autoFocus
            icon="upload"
            label={t('upload_from_device')}
            extraLabel={t('or_drop_files') || ''}
            onMouseDown={() => fileInputRef.current?.click()}
            useStroke
          />
          <Spacer />
          <Option
            icon="clock"
            label={t('recently_used')}
            onMouseDown={() => handleClick('Recently used')}
            useStroke
          />
          <Option
            icon="globe"
            label={t('from_url')}
            onMouseDown={() => handleClick('From URL')}
            useStroke
          />
          <Option
            icon="canva"
            label={t('canva')}
            onMouseDown={() => handleClick('Canva')}
          />
          <Option
            icon="youtube_color"
            label={t('youtube_link')}
            onMouseDown={() => handleClick('YouTube link')}
          />
          <Option
            icon="flickr"
            label={t('flickr')}
            onMouseDown={() => handleClick('Flickr')}
          />
          <Option
            icon="pexels"
            label={t('pexels')}
            onMouseDown={() => handleClick('Pexels')}
          />
          <Option
            icon="giphy"
            label={t('giphy')}
            onMouseDown={() => handleClick('Giphy')}
          />
          <Option
            icon="dropbox"
            label={t('dropbox')}
            onMouseDown={() => handleClick('Dropbox')}
          />
          <Option
            icon="google_drive"
            label={t('google_drive')}
            onMouseDown={() => handleClick('Google Drive')}
          />
        </List>,
        document.getElementById('dropdown-root') as HTMLElement
      )}
    </>
  );
};
