import styled, { css } from 'styled-components';
import { RadioGroupProps } from '.';

export const Container = styled.div<Pick<RadioGroupProps, 'orientation' | 'size'>>`
  ${({ theme: { fontSizes }, orientation = 'column', size = 'md' }) => css`
    display: flex;

    ${size === 'sm' &&
    css`
      font-size: ${fontSizes.s12};
    `}

    ${size === 'md' &&
    css`
      font-size: ${fontSizes.s16};
    `}

    ${size === 'lg' &&
    css`
      font-size: ${fontSizes.s20};
    `}

    ${size === 'xl' &&
    css`
      font-size: ${fontSizes.s24};
    `}

    ${orientation === 'column' &&
    css`
      flex-direction: column;
      gap: 1em;
    `}

    ${orientation === 'row' &&
    css`
      align-items: flex-start;
      flex-direction: row;
      gap: 2em;
    `}
  `}
`;
