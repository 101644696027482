import { useEffect, useRef, useState, MouseEvent, ChangeEvent, SetStateAction, Dispatch } from 'react';
import { DateTime } from 'luxon';
import { Portal, useWindowSize, MOBILE_MAX_SIZE } from '@sendible/design-system';
import { DatePicker } from './components';
import { Container, DateInput, FilterContainer } from './index.styles';

type Props = {
  currentCampaign: CampaignsData;
  setCurrentCampaign: Dispatch<SetStateAction<CampaignsData>>;
};
export const Calendar = ({ currentCampaign, setCurrentCampaign }: Props) => {
  const { startDate, endDate } = currentCampaign;
  const { height, width } = useWindowSize();

  const startDateRef = useRef<HTMLInputElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [isStartOrEndDate, setIsStartOrEndDate] = useState<IsStartOrEndDateType>('start');

  useEffect(() => {
    if (startDateRef.current && endDateRef.current) {
      startDateRef.current.value = startDate ? (startDate.toISODate() as string) : '';

      endDateRef.current.value = endDate ? (endDate.toISODate() as string) : '';
    }
  }, [startDate, endDate]);

  const updateDateWhenDateNull = () => {
    if (startDateRef.current && endDateRef.current) {
      setCurrentCampaign((prev) => {
        return {
          ...prev,
          startDate: isStartOrEndDate === 'start' ? null : startDate,
          endDate: isStartOrEndDate === 'end' ? null : endDate,
        };
      });

      if (isStartOrEndDate === 'start') {
        startDateRef.current.value = '';
        startDateRef.current.focus();
      }

      if (isStartOrEndDate === 'end') {
        endDateRef.current.value = '';
        endDateRef.current.focus();
      }
    }
  };

  const updateDateWhenStartDate = (date: DateTime) => {
    if (startDateRef.current && endDateRef.current) {
      if (endDate && date > endDate) {
        setCurrentCampaign((prev) => {
          return {
            ...prev,
            startDate: date,
            endDate: null,
          };
        });
      } else {
        setCurrentCampaign((prev) => {
          return {
            ...prev,
            startDate: date,
          };
        });
      }

      setIsStartOrEndDate('end');
      endDateRef.current.focus();
    }
  };

  const updateDateWhenEndDate = (date: DateTime) => {
    if (startDateRef.current && endDateRef.current) {
      if (startDate && date < startDate) {
        setCurrentCampaign((prev) => {
          return {
            ...prev,
            startDate: date,
            endDate: null,
          };
        });

        endDateRef.current.focus();
      } else {
        setCurrentCampaign((prev) => {
          return {
            ...prev,
            endDate: date,
          };
        });

        setCalendarOpen(false);
        endDateRef.current.blur();
      }
    }
  };

  const updateDate = (date: CampaignsDateTime) => {
    if (!date) {
      updateDateWhenDateNull();

      return;
    }

    if (isStartOrEndDate === 'start') {
      updateDateWhenStartDate(date);
    }

    if (isStartOrEndDate === 'end') {
      updateDateWhenEndDate(date);
    }
  };

  const handleDateInputOnInput = (event: ChangeEvent<HTMLInputElement>) => {
    const date = event.currentTarget.value;

    if (date.length < 1) {
      updateDate(null);
    }
  };

  const handleDateInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const onChangeDate = event.currentTarget.value;
    const date = DateTime.fromISO(onChangeDate);

    if (date.isValid && date > DateTime.fromISO('2000-01-01')) {
      updateDate(date);
    }
  };

  const inputComponent = (
    <FilterContainer>
      <DateInput
        type="date"
        ref={startDateRef}
        onClick={(event: MouseEvent<HTMLInputElement>) => {
          event.preventDefault();
          setIsStartOrEndDate('start');
          setCalendarOpen(true);
        }}
        onInput={handleDateInputOnInput}
        onChange={handleDateInputOnChange}
      />
      <DateInput
        type="date"
        ref={endDateRef}
        onClick={(event: MouseEvent<HTMLInputElement>) => {
          event.preventDefault();
          setIsStartOrEndDate('end');
          setCalendarOpen(true);
        }}
        onInput={handleDateInputOnInput}
        onChange={handleDateInputOnChange}
      />
    </FilterContainer>
  );

  const datePickerComponent = (
    <DatePicker
      selectedDateRanges={{ startDate, endDate }}
      isStartOrEndDate={isStartOrEndDate}
      dateSelectedCallback={updateDate}
      closeHandler={() => {
        setCalendarOpen(false);
      }}
    />
  );

  return (
    <Container>
      {width >= MOBILE_MAX_SIZE && height >= 500 ? (
        <Portal
          id="campaigns-datepicker-portal"
          isVisibleOverride={calendarOpen}
          content={datePickerComponent}
        >
          {inputComponent}
        </Portal>
      ) : (
        <>
          {inputComponent}
          {calendarOpen && datePickerComponent}
        </>
      )}
    </Container>
  );
};
