import { FC } from 'react';
import { Heading, Icon } from '@sendible/design-system';
import { Container, Content } from './index.styles';

export const ErrorPage: FC = () => (
  <Container>
    <Content>
      <Icon
        color="brandAccent"
        name="warning"
      />
      <Heading
        align="center"
        semantic="h1"
        variation="layout-title_54"
      >
        There was an error loading the app. <em>Please try again later</em>.
      </Heading>
    </Content>
  </Container>
);

export default ErrorPage;
