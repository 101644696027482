import sharedStateBridge, { composeTabs } from '@sendible/shared-state-bridge';

const setActiveComposeBoxTab = (tabId: composeTabs | undefined) => sharedStateBridge.updateSpecificKey('compose', 'activeComposeBoxTab', tabId);

const setIsComposeBoxOpen = (state: boolean) => sharedStateBridge.updateSpecificKey('compose', 'isComposeBoxOpen', state);

const setIsMediaAttacherOpen = (state: boolean) => sharedStateBridge.updateSpecificKey('compose', 'isMediaAttacherOpen', state);

const toggleMediaAttacher = () => {
  const currentMediaAttacherState = sharedStateBridge.getSharedState().compose.isMediaAttacherOpen || false;

  sharedStateBridge.updateSpecificKey('compose', 'isMediaAttacherOpen', !currentMediaAttacherState);
};

export const composeMediaInteractions = {
  setActiveComposeBoxTab,
  setIsComposeBoxOpen,
  setIsMediaAttacherOpen,
  toggleMediaAttacher,
};
