import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Display = styled.div<{ $color: string }>`
  ${({ theme: { colors }, $color }) => css`
    background: ${$color};
    border: 1px solid ${colors.brandDarkTint80};
    border-radius: 0.25rem;
    cursor: pointer;
    height: 1.25rem;
    width: 1.25rem;
  `}
`;

export const List = styled.div`
  ${({ theme: { colors } }) => css`
    background: ${colors.brandLight};
    border-radius: 0.25rem;
    box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.2);
    margin: 0;
    padding: 1.25rem;
    width: 17rem;

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin: 0;
      list-style: none;
    }
  `}
`;

export const Color = styled.li<{ $color: string; $isCurrent: boolean }>`
  ${({ theme: { colors }, $color, $isCurrent }) => css`
    background: ${$color};
    border-radius: 0.25rem;
    cursor: pointer;
    height: 1.25rem;
    width: 1.25rem;

    &:hover {
      outline: 2px solid ${colors.brandPrimary};
      outline-offset: 2px;
    }

    ${$isCurrent &&
    css`
      outline: 2px solid ${colors.brandPrimary};
      outline-offset: 2px;
    `}
  `}
`;
