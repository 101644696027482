import styled, { css } from 'styled-components';

export const Container = styled.textarea`
  ${({ theme: { colors } }) => css`
    background-color: transparent;
    border: 0;
    color: ${colors.brandDark};
    font-size: 1rem;
    max-height: 5.5rem;
    min-height: 3rem;
    min-width: 20vw;
    min-width: 20svw;
    margin: 0;
    padding: 0;
    resize: none;
    width: 100%;

    &::placeholder {
      color: ${colors.brandDarkAlpha60};
    }

    &:focus-visible {
      outline: none;
    }

    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: white;
    }

    &::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      background-color: ${colors.brandDarkTint80};
      border-radius: 8px;
      height: 8px;
      -webkit-border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: ${colors.brandDarkTint70};
    }
  `}
`;
