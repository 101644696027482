import styled, { css } from 'styled-components';
import { Box } from '@sendible/design-system';

export const ContainerStyled = styled(Box)`
  ${({
    theme: {
      colors,
      breakPoints: { smallDevices },
    },
  }) => css`
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid ${colors.brandDarkAlpha20};
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 3;

    .name-container {
      align-items: center;
      flex: 10;
      flex-wrap: nowrap;
      margin-right: 0.5rem;
      word-break: break-word;
    }

    .button-container {
      flex: 1;
      flex-wrap: nowrap;
      justify-content: flex-end;
    }

    .input {
      width: 100%;
    }

    .bulk-actions-bar {
      padding: 0;
      width: auto;
    }

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      .hide-in-mobile {
        display: none;
      }

      .bulk-actions-bar {
        padding: 0;
      }
    }
  `}
`;
