/* eslint-disable @typescript-eslint/no-explicit-any */
import { cookie } from '@sendible/common';
import EVENTS from './event-types';

interface EventType extends Event {
  data?: any;
}

interface EventListInterface {
  [key: string]: EventType;
}

type SubscribeCallbackType = (result?: any) => Promise<void> | void;

const eventsList: EventListInterface = {};
const isDebugMode = !!cookie('debug').get();

function logEvents(eventType: string, eventName: EVENTS, data?: any): void {
  if (isDebugMode) {
    console.log(`${eventType}: ${eventName} ${JSON.stringify(data) || ''}`);
  }
}

function unsubscribe(eventName: EVENTS, handler: (result?: any) => void) {
  logEvents('unsubscribe', eventName);
  window.removeEventListener(eventName, handler);
}

export function publish(eventName: EVENTS, data: any): void {
  logEvents('publish', eventName, data);
  eventsList[eventName] ||= new Event(eventName);
  eventsList[eventName].data = data;
  window.dispatchEvent(eventsList[eventName]);
}

export function subscribe(eventName: EVENTS, callback: SubscribeCallbackType, subscribeOnlyOnce = false) {
  const handler = (event: EventType) => {
    callback(event.data);

    if (subscribeOnlyOnce) {
      unsubscribe(eventName, handler);
    }
  };

  logEvents('subscribe', eventName);
  window.addEventListener(eventName, handler);

  return () => {
    unsubscribe(eventName, handler);
  };
}

export function subscribeOnce(eventName: EVENTS, callback: SubscribeCallbackType) {
  return subscribe(eventName, callback, true);
}
