import { Box } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';

export const Container = styled(Box)<{ height: number }>`
  ${({ height }) =>
    css`
      height: ${height}rem;
      padding-bottom: 2rem;
    `}
`;
