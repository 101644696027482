function sendErrorDirectlyToOnerror(error: string) {
  const newError = new Error(error);

  if (window.onerror) {
    window.onerror(newError.message, undefined, undefined, undefined, newError);
  }
}

export default {
  sendErrorDirectlyToOnerror,
};
