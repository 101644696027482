export default {
  formatKiloBytes: (sizeInBytes: number) => {
    return Math.ceil(sizeInBytes / 1024);
  },
  formatBytesFromKilobytes: (sizeInKilobytes: number) => {
    return Math.ceil(sizeInKilobytes * 1024);
  },
  formatMegaBytes: (sizeInBytes: number) => {
    return +(sizeInBytes / 1048576).toFixed(3);
  },
};
