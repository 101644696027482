import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './index.styles';
import { useAiAssistContext } from '../../AiAssistContext';

export const inputMaxLength = 300;

export const TextArea = () => {
  const { composeText, promptValue, setPromptValue, generateContentHandler, isGenerationEnabled } = useAiAssistContext();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation('ai_assist');

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPromptValue(event.target.value || '');

    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const placeHolderText = composeText ? t('compose_text_textarea_placeholder') : t('textarea_placeholder');

  return (
    <Container
      autoFocus
      ref={textareaRef}
      value={promptValue}
      placeholder={placeHolderText}
      onChange={onChangeHandler}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();

          if (isGenerationEnabled) generateContentHandler();
        }
      }}
      data-gramm="false"
      data-gramm_editor="false"
      data-enable-grammarly="false"
    />
  );
};
