import { Box } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';

export const GridItem = styled(Box)`
  ${({
    theme: {
      breakPoints: { mediumDevices },
      colors,
    },
  }) => css`
    border: 2px solid ${colors.brandSecondaryTint90};
    border-radius: 1rem;
    box-sizing: border-box;
    padding: 1rem;
    position: relative;
    justify-content: space-between;

    .ds-tooltip {
      position: absolute;
      right: 0.25rem;
      top: 0.25rem;

      .icon {
        height: 1.5rem;
        width: 1.5rem;

        path {
          stroke: ${colors.brandSecondaryTint80};
        }
      }

      &:hover {
        .icon {
          path {
            stroke: ${colors.brandSecondaryTint40};
          }
        }
      }
    }

    @media all and (${mediumDevices.max}) {
      border-width: 1px;
      flex: 1;
    }
  `}
`;

export const GridItemTitle = styled.h4`
  ${({
    theme: {
      breakPoints: { mediumDevices },
    },
  }) => css`
    font-size: 1rem;
    text-align: center;

    @media all and (${mediumDevices.max}) {
      font-size: 0.8rem;
      line-height: 1em;
    }
  `}
`;

export const GridItemNumber = styled.p`
  font-size: 2rem;
  ${({
    theme: {
      breakPoints: { mediumDevices },
    },
  }) => css`
    font-size: 2rem;

    @media all and (${mediumDevices.max}) {
      font-size: 1.8rem;
    }
  `}
`;
