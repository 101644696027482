export const giphy = () => (
  <>
    <path
      fill="#0F9"
      fillRule="evenodd"
      d="M5.519 4.315V19.13H7.37V4.315z"
      clipRule="evenodd"
    />
    <path
      fill="#93F"
      fillRule="evenodd"
      d="M16.63 8.019V19.13h1.852V8.019z"
      clipRule="evenodd"
    />
    <path
      fill="#FFF35C"
      fillRule="evenodd"
      d="M11.769 3.667h-6.25v1.851h8.055V3.667z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M11.074 5.518h2.778l-.926-1.851zM18.482 9.222l-1.852-.926v2.778z"
      clipRule="evenodd"
      opacity={0.4}
    />
    <path
      fill="#0CF"
      fillRule="evenodd"
      d="M16.63 18.482H5.519v1.851h12.963v-1.851z"
      clipRule="evenodd"
    />
    <path
      fill="#F66"
      fillRule="evenodd"
      d="M16.63 7.37V5.518h-1.852V3.667h-1.852v5.555h5.556V7.37z"
      clipRule="evenodd"
    />
  </>
);
