import { Box, Spinner } from '@sendible/design-system';

export const LoadingComponent = () => {
  return (
    <Box
      fullWidth
      contentJustify="space-around"
      contentAlignment="center"
    >
      <Spinner
        testId="campaign-spinner"
        className="spinner-icon"
        size="lg"
      />
    </Box>
  );
};
