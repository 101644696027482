import { ReportsProvider } from './context';
import { ReportsPage } from './page';
import { ErrorBoundary } from '../../components';

export const Reports = () => (
  <ErrorBoundary translationNamespace="campaigns">
    <ReportsProvider>
      <ReportsPage />
    </ReportsProvider>
  </ErrorBoundary>
);
