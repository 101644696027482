import { throwError } from '@sendible/common';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToastNotificationContext } from '@sendible/design-system';
import { NotificationTypeEnum } from '@sendible/design-system/src/components/molecules/Toast';
import endpoints from '../../data-layer/endpoints';
import { useQueryWithAccessToken, useMutationWithAccessToken } from '../../data-layer/useReactQueryWithAccessToken';
import { defaultGetMediaLibrariesResponse } from '../../pages/MediaLibrary/constants';
import { useMediaLibraryContext } from '../../pages/MediaLibrary/context';
import { mediaLibraryRenamed, mediaLibraryCreated } from '../../pages/MediaLibrary/pendoEvents';

const MediaLibrariesQueryKey: DLQueryKey = [endpoints.GetMediaLibraries.endpoint];

export const useGetMediaLibraries = () =>
  useQueryWithAccessToken<GetMediaLibrariesReturnType>(MediaLibrariesQueryKey, {
    select: useCallback((APIResponse) => {
      if (!APIResponse) throwError('API response was empty when fetching media libraries');

      const mediaLibraries = APIResponse;

      return {
        mediaLibraries,
        canDelete: mediaLibraries.length > 1,
      };
    }, []),
    refetchInterval: endpoints.GetMediaLibraries.interval,
  });

export const useDeleteMediaLibrary = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('media_library');
  const { activeMediaLibrary, setActiveMediaLibrary } = useMediaLibraryContext();
  const { data = defaultGetMediaLibrariesResponse } = useGetMediaLibraries();
  const { mediaLibraries } = data;
  const { method, endpoint } = endpoints.DeleteMediaLibrary;
  const { showToastNotification } = useToastNotificationContext();

  return useMutationWithAccessToken<MediaLibraryType, MediaLibraryMutationParamsType>({
    method,
    endpoint,
    options: {
      onSuccess: useCallback(
        (mediaLibrary: MediaLibraryType) => {
          const message = t('item_deleted', { name: mediaLibrary.name });

          showToastNotification({
            notificationText: message,
            type: NotificationTypeEnum.Success,
            showCloseButton: true,
          });

          queryClient.invalidateQueries(MediaLibrariesQueryKey);
          if (activeMediaLibrary?.id === mediaLibrary.id) setActiveMediaLibrary(mediaLibraries[0]);
        },
        [activeMediaLibrary]
      ),
      onError: useCallback((_, mediaLibrary) => {
        const message = t('failed_delete', { name: mediaLibrary.name });

        showToastNotification({
          notificationText: message,
          type: NotificationTypeEnum.Error,
          showCloseButton: true,
        });
      }, []),
    },
  });
};

export const useRenameMediaLibrary = (newName: string, oldName: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('media_library');
  const { method, endpoint } = endpoints.RenameMediaLibrary;
  const { showToastNotification } = useToastNotificationContext();

  return useMutationWithAccessToken<MediaLibraryType, MediaLibraryMutationParamsType>({
    method,
    endpoint,
    body: { name: newName },
    options: {
      onSuccess: useCallback((mediaLibrary: MediaLibraryType) => {
        const message = t('item_changed', { oldName, newName: mediaLibrary.name });

        showToastNotification({
          notificationText: message,
          type: NotificationTypeEnum.Success,
          showCloseButton: true,
        });

        window.pendo.track(mediaLibraryRenamed, {
          mediaLibraryOldName: oldName,
          mediaLibraryNewName: newName,
          mediaLibraryId: mediaLibrary.id,
        });
        queryClient.invalidateQueries(MediaLibrariesQueryKey);
      }, []),
      onError: useCallback(() => {
        const message = t('failed_changed', { oldName });

        showToastNotification({
          notificationText: message,
          type: NotificationTypeEnum.Error,
          showCloseButton: true,
        });
      }, []),
    },
  });
};

export const useCreateMediaLibrary = (name: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('media_library');
  const { method, endpoint } = endpoints.CreateMediaLibrary;
  const { showToastNotification } = useToastNotificationContext();

  return useMutationWithAccessToken<MediaLibraryType, MediaLibraryMutationParamsType>({
    method,
    endpoint,
    body: { name },
    options: {
      onSuccess: useCallback((mediaLibrary: MediaLibraryType) => {
        const message = t('item_created', { name: mediaLibrary.name });

        showToastNotification({
          notificationText: message,
          type: NotificationTypeEnum.Success,
          showCloseButton: true,
        });
        window.pendo.track(mediaLibraryCreated, {
          mediaLibraryName: name,
          mediaLibraryId: mediaLibrary.id,
        });
        queryClient.invalidateQueries(MediaLibrariesQueryKey);
      }, []),
      onError: useCallback((_, mediaLibrary) => {
        const message = t('failed_create', { name: mediaLibrary.name });

        showToastNotification({
          notificationText: message,
          type: NotificationTypeEnum.Error,
          showCloseButton: true,
        });
      }, []),
    },
  });
};
