const TZobj = {};

TZobj.getTimezoneCountryIndex = function (country, data) {
  return _.indexOf(data, _.findWhere(data, {
    country,
  }));
};

TZobj.processSpecialCountry = function (country, data) {
  const countryIndex = this.getTimezoneCountryIndex(country, data);
  const countryData = data.splice(countryIndex, 1)[0];

  countryData.isSpecial = true;

  if (countryData.country.match(/britain/i) !== null) {
    countryData.country = 'United Kingdom';
  }

  data.unshift(countryData);

  return data;
};

TZobj.createUSTimezones = function (data) {
  const countryIndex = this.getTimezoneCountryIndex('United States', data);
  const countryData = data[countryIndex];
  const timezones = _.map(countryData.timezones, (zone) => ((typeof zone === 'string') ? JSON.parse(zone) : zone));
  const USGroupedTimezones = [
    'Hawaii',
    'Alaska',
    'Pacific',
    'Mountain',
    'Mountain-Arizona',
    'Central',
    'Eastern',
  ];

  countryData.timezones = _.map(USGroupedTimezones, (zone) => {
    let matchedTimezone;
    let friendlyName;

    switch (zone) {
      case 'Hawaii':
        friendlyName = 'Hawaii Time';
        matchedTimezone = _.findWhere(timezones, { timezone_identifier: 'Pacific/Honolulu' });
        break;

      case 'Alaska':
        friendlyName = 'Alaska Time';
        matchedTimezone = _.findWhere(timezones, { timezone_identifier: 'America/Anchorage' });
        break;

      case 'Pacific':
        friendlyName = 'Pacific Time';
        matchedTimezone = _.findWhere(timezones, { timezone_identifier: 'America/Los_Angeles' });
        break;

      case 'Mountain':
        friendlyName = 'Mountain Time';
        matchedTimezone = _.findWhere(timezones, { timezone_identifier: 'America/Denver' });
        break;

      case 'Mountain-Arizona':
        friendlyName = 'Mountain Time - Arizona';
        matchedTimezone = _.findWhere(timezones, { timezone_identifier: 'America/Phoenix' });
        break;

      case 'Central':
        friendlyName = 'Central Time';
        matchedTimezone = _.findWhere(timezones, { timezone_identifier: 'America/Chicago' });
        break;

      case 'Eastern':
        friendlyName = 'Eastern Time';
        matchedTimezone = _.findWhere(timezones, { timezone_identifier: 'America/New_York' });
        break;
      default:
    }

    return {
      time_now: matchedTimezone.time_now,
      timezone_abbr: matchedTimezone.timezone_abbr,
      timezone_friendly_name: friendlyName,
      timezone_identifier: matchedTimezone.timezone_identifier,
      timezone_offset: matchedTimezone.timezone_offset,
      us_timezone: true,
      us_grouped: true,
    };
  });
};

// primeTimezoneCache will retrieve tz from server if not already cached
// to get the timezones use libs/localization/timezones.js
TZobj.primeTimezoneCache = function (callback) {
  const cache = new Cache();
  const key = 'timezones';

  TZobj.isPrimed = false;
  // check if timezones cached
  if (cache.read(key) == null || cache.read(key) !== null) {
    Sendible.Connect.getTimeZones((data) => {
    //   let localTimezone;
    //   let browserTZDetails;
    //   let timezoneIdentifier;

      TZobj.isPrimed = true;

      data = _.sortBy(data, 'country');

      _.each(data, (country) => {
        country.timezones = _.map(country.timezones, (timezone) => {
          timezone = JSON.parse(timezone);
          timezone.country = country.country;

          return timezone;
        });
      });

      TZobj.processSpecialCountry('Canada', data);
      TZobj.processSpecialCountry('Britain (UK)', data);
      TZobj.processSpecialCountry('United States', data);

      TZobj.createUSTimezones(data);

      cache.store(key, JSON.stringify(data), 1);

      Backbone.trigger('timezones-updated');

      if (callback) {
        callback();
      }
    });
  } else {
    TZobj.isPrimed = true;
  }
};

TZobj.getBrowserTZDetails = function () {
  const localTimeOffset = new Date().getTimezoneOffset();

  return {
    localTimezone: parseInt(localTimeOffset, 10),
    timezoneIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};

TZobj.getTimeZones = function () {
  const cache = new Cache();
  const tmCache = cache.read('timezones');

  const localizedTimeZone = JSON.parse(tmCache);

  return localizedTimeZone;
};

TZobj.getTimezoneCountries = function (callback, setFromLocal, setFromValue) {
  const fragment = document.createDocumentFragment();
  const cachedTimezones = TZobj.getTimeZones();
  const localTimeOffset = new Date().getTimezoneOffset();

  if (!cachedTimezones && callback) {
    TZobj.primeTimezoneCache(callback);
    return;
  }

  const selected = _.find(cachedTimezones, (country) => {
    if (setFromValue) {
      return _.findWhere(country.timezones, {
        timezone_identifier: setFromValue,
      });
    } if (setFromLocal) {
      return _.findWhere(country.timezones, {
        timezone_offset: localTimeOffset,
      });
    }
    return _.findWhere(country.timezones, {
      timezone_identifier: loginContext.getCurrentDetails().timezone_name,
    });
  });

  _.each(_.pluck(cachedTimezones, 'country'), (timezone) => {
    const option = document.createElement('option');

    option.value = option.innerHTML = timezone;

    if (typeof selected !== 'undefined' && selected.country === timezone) {
      option.selected = true;
    }

    fragment.appendChild(option);
  });

  return fragment;
};

TZobj.getTimeZoneOptionsForCountry = function (country, setFromLocal, setFromValue) {
  const fragment = document.createDocumentFragment();
  let isTimezoneSelected = false;
  const currDetails = loginContext.getCurrentDetails();
  let prevOption;
  let is_us_grouped;
  let splitterOption;
  let disabledAttribute;
  let cachedTimezones;
  const localTimeOffset = new Date().getTimezoneOffset();

  cachedTimezones = TZobj.getTimeZones();

  cachedTimezones = _.findWhere(cachedTimezones, { country });

  if (typeof cachedTimezones === 'undefined') {
    return;
  }
  cachedTimezones = cachedTimezones.timezones;

  _.each(cachedTimezones, (timezone) => {
    const option = document.createElement('option');
    timezone = (typeof timezone === 'string') ? JSON.parse(timezone) : timezone;
    let timezoneName = '';
    const timezonePrefixPositive = (timezone.timezone_offset < 0);
    const timezoneHour = Math.floor(Math.abs(timezone.timezone_offset) / 60);
    const timezoneMinutes = (Math.abs(timezone.timezone_offset) / 60) % 1;

    timezoneName += (timezonePrefixPositive) ? '+' : '-';
    timezoneName += (timezoneHour < 10) ? `0${timezoneHour}:` : `${timezoneHour}:`;
    timezoneName += (timezoneMinutes === 0) ? '00' : timezoneMinutes * 60;

    if (timezone.timezone_offset === 0) {
      timezoneName = ' 00:00';
    }

    option.innerHTML = `(GMT${timezoneName}) ${timezone.timezone_friendly_name}`;
    option.value = timezone.timezone_offset;
    option.setAttribute('data-identifier', timezone.timezone_identifier);

    if (timezone.hasOwnProperty('us_grouped') && timezone.us_grouped === true) {
      is_us_grouped = true;
    } else if (timezone.hasOwnProperty('us_grouped') === false && is_us_grouped === true) {
      is_us_grouped = false;

      splitterOption = document.createElement('option');
      disabledAttribute = document.createAttribute('disabled');
      disabledAttribute.value = 'disabled';
      splitterOption.innerHTML = '--------------------------------';
      splitterOption.setAttributeNode(disabledAttribute);
      fragment.appendChild(splitterOption);
    }

    // set option to selected based on current user
    if (!isTimezoneSelected && currDetails && currDetails.timezone_name && !setFromLocal && !setFromValue) {
      // check if user has timezone_name set and is in the correct format.
      if (currDetails.timezone_name && currDetails.timezone_name.match(/\/|-/g) !== null) {
        if (currDetails.timezone_name == timezone.timezone_identifier) {
          if (prevOption)prevOption.removeAttribute('selected');
          option.setAttribute('selected', 'true');
          isTimezoneSelected = true;
        }
      } else if (currDetails.timezone == timezone.timezone_offset) {
        if (prevOption) prevOption.removeAttribute('selected');
        option.setAttribute('selected', 'true');
        isTimezoneSelected = true;
      }
    } else if (!isTimezoneSelected && setFromLocal && !setFromValue) {
      if (timezone.timezone_offset === localTimeOffset) {
        if (prevOption) prevOption.removeAttribute('selected');
        option.setAttribute('selected', 'true');
        isTimezoneSelected = true;
      }
    } else if (!isTimezoneSelected && setFromValue) {
      if (timezone.timezone_offset === setFromValue) {
        if (prevOption) prevOption.removeAttribute('selected');
        option.setAttribute('selected', 'true');
        isTimezoneSelected = true;
      }
    }

    prevOption = option;

    fragment.appendChild(option);
  });

  return fragment;
};

TZobj.getTimeZoneOptions = function (callback) {
  const fragment = document.createDocumentFragment();
  let isTimezoneSelected = false;
  const currDetails = loginContext.getCurrentDetails();
  let prevOption;
  let is_us_grouped;
  let splitterOption;
  let disabledAttribute;

  const cachedTimezones = TZobj.getTimeZones();

  // if callback passed and timezone are not present
  // then perform asyn primeTZCache and call callback
  if (!cachedTimezones && callback) {
    TZobj.primeTimezoneCache(callback);
    return;
  }

  _.each(cachedTimezones, (timezone) => {
    const option = document.createElement('option');
    let timezoneName = '';
    const timezonePrefixPositive = (timezone.timezone_offset < 0);
    const timezoneHour = Math.floor(Math.abs(timezone.timezone_offset) / 60);
    const timezoneMinutes = (Math.abs(timezone.timezone_offset) / 60) % 1;

    timezoneName += (timezonePrefixPositive) ? '+' : '-';
    timezoneName += (timezoneHour < 10) ? `0${timezoneHour}:` : `${timezoneHour}:`;
    timezoneName += (timezoneMinutes === 0) ? '00' : timezoneMinutes * 60;

    if (timezone.timezone_offset === 0) {
      timezoneName = ' 00:00';
    }

    option.innerHTML = `(GMT${timezoneName}) ${timezone.timezone_friendly_name}`;
    option.value = timezone.timezone_offset;
    option.setAttribute('data-identifier', timezone.timezone_identifier);

    if (timezone.hasOwnProperty('us_grouped') && timezone.us_grouped === true) {
      is_us_grouped = true;
    } else if (timezone.hasOwnProperty('us_grouped') === false && is_us_grouped === true) {
      is_us_grouped = false;

      splitterOption = document.createElement('option');
      disabledAttribute = document.createAttribute('disabled');
      disabledAttribute.value = 'disabled';
      splitterOption.innerHTML = '--------------------------------';
      splitterOption.setAttributeNode(disabledAttribute);
      fragment.appendChild(splitterOption);
    }

    // set option to selected based on current user
    if (!isTimezoneSelected && currDetails && currDetails.timezone_name) {
      // check if user has timezone_name set and is in the correct format.
      if (currDetails.timezone_name && currDetails.timezone_name.match(/\/|-/g) !== null) {
        if (currDetails.timezone_name == timezone.timezone_identifier) {
          if (prevOption)prevOption.removeAttribute('selected');
          option.setAttribute('selected', 'true');
          isTimezoneSelected = true;
        }
      } else if (currDetails.timezone == timezone.timezone_offset) {
        if (prevOption) prevOption.removeAttribute('selected');
        option.setAttribute('selected', 'true');
        isTimezoneSelected = true;
      }
    }

    prevOption = option;

    fragment.appendChild(option);
  });

  return fragment;
};

TZobj.updateUserTimezone = function (timezone, timezone_identifier, update_messages) {
  Sendible.Connect.updateTimezone(window.appConfig.applicationId, {
    timezone_name: timezone_identifier,
    timezone,
    update_messages: update_messages || 0,
  }, () => {
    Backbone.trigger('new-timezone-selected');
  });
};

export default TZobj;
