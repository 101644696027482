export const corner_left_down = () => (
  <>
    <path
      d="M14 15l-5 5-5-5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 4h-7a4 4 0 00-4 4v12"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
