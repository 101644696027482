import { Icon, IconNames, Text } from '@sendible/design-system';
import { InputHTMLAttributes } from 'react';
import { Container, Labels } from './index.styles';

interface OptionProps extends Omit<InputHTMLAttributes<HTMLButtonElement>, 'type'> {
  extraLabel?: string;
  icon: IconNames;
  label: string;
  useStroke?: boolean;
}

export const Option = ({ extraLabel, icon, label, useStroke = false, ...rest }: OptionProps) => (
  <Container
    type="button"
    $useStroke={useStroke}
    $hasExtraLabel={!!extraLabel}
    {...rest}
  >
    <Icon
      color="brandDark"
      name={icon}
      useStroke={useStroke}
    />
    <Labels>
      <Text variation="body_14">{label}</Text>
      {extraLabel && (
        <Text
          variation="body_12"
          color="brandDarkTint30"
        >
          {extraLabel}
        </Text>
      )}
    </Labels>
  </Container>
);
