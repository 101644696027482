import { Box } from '@sendible/design-system/src';
import styled from 'styled-components';

export const HeaderContainer = styled(Box)`
  background: #f5f5f5;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
`;

export const PrintIconContainer = styled(Box)`
  svg {
    width: 30px;
    height: 30px;
    path {
      stroke-width: 1;
    }
  }
`;
