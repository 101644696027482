import { Button, Text } from '@sendible/design-system';
import { Container } from './index.styles';

interface Props {
  currentPage: number;
  disabled: boolean;
  pages: number;
  onBack: () => void;
  onNext: () => void;
}

export const Pagination = ({ currentPage, disabled, pages, onBack, onNext }: Props) => {
  return (
    <Container>
      <Button
        icon="chevron_left"
        title="Previous result"
        disabled={currentPage <= 1 || disabled}
        onClick={onBack}
      />
      <Text color={disabled ? 'brandDarkAlpha50' : 'brandDark'}>{`${currentPage} / ${pages}`}</Text>
      <Button
        icon="chevron_right"
        title="Next result"
        disabled={currentPage >= pages || disabled}
        onClick={onNext}
      />
    </Container>
  );
};
