import styled, { css } from 'styled-components';
import { SecondarySidebar } from '@sendible/design-system';

export const SecondarySidebarStyled = styled(SecondarySidebar)`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
    },
  }) => css`
    border-right: 1px solid ${colors.brandDarkAlpha20};

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      z-index: 11;
    }
  `}
`;
