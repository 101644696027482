import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { colors } }) => css`
    align-items: center;
    display: flex;
    gap: 0.25rem;

    p {
      font-size: 0.75rem;
      margin: 0 0.25rem 0 0;
    }

    .icon {
      fill: ${colors.brandDark};
      height: 1rem;
      width: 1rem;
    }
  `}
`;
