import { FC, Ref } from 'react';
import { Container } from './index.styles';
import { Spacings } from '../../../theme';
import { spacings } from '../../../theme/typeConstants';

export type SpaceArray<T extends (typeof spacings)[number][]> = Pick<T, Exclude<keyof T, ArrayLengthMutationKeys>> & {
  [Symbol.iterator]: () => IterableIterator<ArrayItems<T>>;
};

export const contentAlignments = ['center', 'flex-end', 'flex-start', 'stretch'] as const;
export const contentJustifies = ['center', 'flex-end', 'flex-start', 'space-around', 'space-between', 'space-evenly'] as const;
export const orientations = ['column', 'row'] as const;
export const overflows = ['scroll', 'wrap', 'nowrap'] as const;
export const semantics = ['div', 'section'] as const;

export interface BoxProps extends Component {
  /**
   * Self alignment. E.g.: flex-start. Optional.
   */
  alignSelf?: (typeof contentAlignments)[number];
  /**
   * Content alignment. E.g.: flex-start. Optional.
   */
  contentAlignment?: (typeof contentAlignments)[number];
  /**
   * Content justify. E.g.: space-between. Optional.
   */
  contentJustify?: (typeof contentJustifies)[number];
  /**
   * Flex value. This value is the size this component will have in comparison to its siblings. E.g.: If this have flex 2 and the sibling has flex 1, this one will have twice the size of the other. Optional.
   */
  flex?: number;
  /**
   * Horizontal space between children elements. Optional.
   */
  horizontalGap?: Spacings;
  /**
   * Outside margins. E.g: ['s12', 's0', 's8', 's0']. Optional.
   */
  margin?: SpaceArray<[Spacings, Spacings, Spacings, Spacings]>;
  /**
   * Box orientaion, can be row or column. Optional.
   */
  orientation?: (typeof orientations)[number];
  /**
   * Make inner content break into new columns after reaching row limit or scroll. Optional.
   */
  overflow?: (typeof overflows)[number];
  /**
   * Inside paddings. E.g: ['s12', 's0', 's8', 's0']. Optional.
   */
  padding?: SpaceArray<[Spacings, Spacings, Spacings, Spacings]>;
  /**
   * Semantic name. E.g.: div. Optional.
   */
  semantic?: (typeof semantics)[number];
  /**
   * Vertical space between children elements. Optional.
   */
  verticalGap?: Spacings;
  /**
   * ref. Optional.
   */
  innerRef?: Ref<HTMLDivElement>;
  /**
   * Is the box supposed to have 100% width?
   */
  fullWidth?: boolean;
}

/**
 * Box
 */
export const Box: FC<BoxProps> = (props) => {
  const {
    children,
    className = '',
    contentAlignment = 'flex-start',
    contentJustify = 'flex-start',
    customClass = '',
    orientation = 'row',
    overflow = 'wrap',
    semantic = 'div',
    testId = 'box',
    innerRef,
  } = props;

  return (
    <Container
      as={semantic}
      className={`box box-${orientation}--${contentAlignment}-${contentJustify} ${customClass} ${className}`}
      data-testid={testId}
      $overflow={overflow}
      ref={innerRef}
      {...props}
    >
      {children}
    </Container>
  );
};
