import { useTranslation } from 'react-i18next';
import { useAiAssistContext } from '../../../AiAssistContext';
import { ActionButton, FullWidthBox } from '../index.styles';
import { AI_ASSIST_QUICK_ACTIONS } from '../../../consts';

type ActionProps = {
  content: string;
  disableButtons: boolean;
};

export const Actions = ({ content, disableButtons }: ActionProps) => {
  const { regenerateContentHandler } = useAiAssistContext();
  const { t } = useTranslation('ai_assist');

  const regenerateText = t('regenerate');

  return (
    <FullWidthBox contentJustify="flex-end">
      <ActionButton
        disabled={disableButtons}
        onClick={() => {
          regenerateContentHandler(AI_ASSIST_QUICK_ACTIONS.rephrase, content);
        }}
        icon="rotate_cw"
        title={regenerateText}
      />
    </FullWidthBox>
  );
};
